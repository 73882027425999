<template>
  <div class="" style="height: inherit;" ref="element">
    <b-container class="d-flex flex-column" style="height: 100%;">
      <b-row style="margin: 0px 0px 10px 0px; height: 50px;">
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">업체명</span>
          <b-form-select size="sm" style="flex: 4;" v-model="filterData.searchCompany" @change="changeCompany" :options="searchCompanyOption"></b-form-select>
        </b-col>
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">요금제명</span>
          <b-form-select size="sm" style="flex: 4;" v-model="filterData.searchFee" @change="setChargeFee" :options="searchFeeOption"></b-form-select>
        </b-col>
      </b-row>
      <b-row style="margin: 0px 0px 10px 0px; height: 50px;">
        <b-col lg=6 class="d-flex justify-content-between flex-row align-items-center">
          <div>
            <!-- 라디오 버튼 -->
            <b-form-group class="radio-group-sqaure" style="margin: 0px;">
              <b-form-radio-group
                :options="userTypeOption"
                v-model="select_user_type"
                buttons
                button-variant="light-gray rounded-1"
                @change="setChargeFee"
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col lg=6>
          <div class="d-flex justify-content-end">
            <b-button class="btn_def" @click="add()">추가</b-button>
            <b-button class="btn_def" @click="edit()">수정</b-button>
          </div>
        </b-col>
      </b-row>
      <b-row style="width: inherit; margin: 0px; flex: 1; height: 80vh;">
        <div class="table-wrapper2" style="height: 100%; overflow-y: scroll; padding-bottom: 20px;">
          <table class="px-3 text-14 text-lg-16">
            <tr class="border-1 border-white">
              <th rowspan="2">구분</th>
              <th rowspan="2">계절</th>
              <th rowspan="2">시간대</th>
              <th colspan="2">전력량 요금 (원/kWh)</th>
            </tr>
            <tr class="border-1 border-white">
              <th>급속</th>
              <th>완속</th>
            </tr>
            <tr
              v-for="(item, i) in items"
              :key="i"
              :class="
                i == 0
                  ? 'border-2 border-gray'
                  : 'border-1 border-light-gray'
              "
            >
              <td v-if="item.isType" :rowspan="item.type_span_count"> {{item.name}} </td>
              <td v-if="item.isSeason" :rowspan="item.season_span_count"> {{item.season_name}} </td>
              <td v-if="item.isTime" :rowspan="item.time_span_count"> {{item.time_text}} </td>
              <td>
                <div class="d-flex justify-content-center" style="width: 100%; height: 100%;">
                  <b-form-input style="width: 80px; height: 100%; border: 0;" v-model="item.fee_fast"></b-form-input>
                </div> 
              </td>
              <td>
                <div class="d-flex justify-content-center" style="width: 100%; height: 100%;">
                  <b-form-input style="width: 80px; height: 100%; border: 0;" v-model="item.fee_low"></b-form-input>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </b-row>

      <!-- modals -->
      <modal-fee-add ref="modal_feeAdd" :obj="obj" @reflash="reflash"/>
    </b-container>
  </div>
</template>

<script>
import Form from "@/components/Form/index";
import regex from "@/lib/regex";

import Modals from "@/components/Modals/index";
import { ref } from 'vue';

import * as XLSX from 'xlsx' // 라이브러리 import

import $ from 'jquery';

export default {
  components: {
    
    // Modals
    ModalFeeAdd: Modals.FeeAdd,
  },
  data() {
    const isDev = process.env.NODE_ENV !== "production";

    return {
      select_user_type: "N",
      charge_fee: [],
      temp_fee: [],
      cf_list: [],
      gridDefaultData: [],
      gridFilterData: [],
      gData: [
        {
          bid: "CPS00001",
          cf_id: "CPS00001_02",
          cf_title: "테스트 요금제2",
          company_name: "서비스법인1",
          fee_fast: "140",
          fee_low: "120",
          id: 414,
          isSeason: true,
          isTime: true,
          isType: true,
          name: "중간부하",
          reg_date: "20240109065614",
          reg_user: "admin",
          season_end: "02",
          season_name: "겨울철 (11월 ~ 02월)",
          season_span_count: 3,
          season_start: "11",
          sid: "1000000101",
          sub_name: "겨울철",
          time_end: "090000",
          time_span_count: 3,
          time_start: "230000",
          time_text: "23:00 ~ 09:00",
          type_span_count: 12,
          update_date: "",
          update_user: "",
          use_date: "20240109065614",
          use_yn: "Y",
          user_type: "N"
        }
      ],
      items: null,
      searchCompanyOption: [],
      searchFeeOption: [],
      filterData: {
        searchCompany: "all",
        searchFee: "",
      },
      userTypeOption: [
        { text: "비회원", value: "N" },
        { text: "회원", value: "M" },
        { text: "로밍회원", value: "R" },
      ],
      obj: {
        bid: "",
        bid_fee_list: []
      },
    };
  },
  computed: {
    
  },
  watch: {
    
  },
  async mounted() {
    console.log("요금설정 페이지 로드");

    this.selectCompanyList();
  },
  methods: {
    async selectCompanyList() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectCompanyList", {
            use_yn : "Y",
            type : "S"
          }
        );
        if (data.length > 0) {
          let options = [];

          data.forEach((e,i) => {
            let add_option = { value: e.company_id, text: e.name }
            options.push(add_option);
          });

          this.searchCompanyOption = options;
          this.filterData.searchCompany = options[0].value;
        } else if (data.length == 0) {
          this.searchCompanyOption = null;
          window.alert("데이터가 없습니다.");
        }

        this.selectChargeFee();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async selectChargeFee() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectChargeFee", {
            use_yn: "Y"
          }
        );
        if (data.length > 0) {debugger;
          this.charge_fee = data;
          let temp_cfData = [];

          let tempData = [];
          let options = [];

          let search_company = this.filterData.searchCompany;
          this.charge_fee.forEach((e,i) => {
            if (e.bid === search_company) {
              tempData.push(e);
            }
          });

          this.temp_fee = tempData;

          let cf_data = tempData.filter(
            (e, i, callback) =>
              i === callback.findIndex(
                (e1) => e1.cf_id === e.cf_id
              )
          );

          for(let j = 0; j < cf_data.length; j++) {
            let temp_data = [];
            this.charge_fee.forEach((e,i) => {
              if (e.cf_id === cf_data[j].cf_id) {
                temp_data.push(e);
                temp_cfData.push(e);
              }
            });

            //temp_cfData.push(temp_data[0]);
            options.push({ text: temp_data[0].cf_title, value: temp_data[0].cf_id});
          };

          this.cf_list = temp_cfData;
          this.searchFeeOption = options;
          this.filterData.searchFee = this.searchFeeOption[0].value;

          this.setChargeFee();
        } else if (data.length == 0) {
          this.gData = null;
          window.alert("데이터가 없습니다.");
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    setChargeFee() {
      let grid_data = [];
      let user_type = this.select_user_type;
      let user_search_data = [];
      let search_fee = this.filterData.searchFee;

      this.cf_list.forEach((e,i) => {
        if (e.user_type === user_type && e.cf_id === search_fee) {
          user_search_data.push(e);
        }
      });

      let name_arr = user_search_data.filter(
        (e, i, callback) =>
          i === callback.findIndex(
            (e1) => e1.name === e.name
          )
      );

      for (let a = 0; a < name_arr.length; a++) {
        let temp_data = {};
        let name_search_data = [];
        let type_count = 0;

        user_search_data.forEach((e,i) => {
          if (e.name === name_arr[a].name) {
            name_search_data.push(e);
          }
        });

        let type_span_count = name_search_data.length;
        let season_arr = name_search_data.filter(
          (e, i, callback) =>
            i === callback.findIndex(
              (e1) => e1.sub_name === e.sub_name
            )
        );

        let season_span_count = season_arr.length;
        for (let b = 0; b < season_arr.length; b++) {
          let season_count = 0;
          let season_search_data = [];
          name_search_data.forEach((e,i) => {
            if (e.sub_name === season_arr[b].sub_name) {
              season_search_data.push(e);
            }
          });

          let season_span_count = season_search_data.length;
          let season_name = season_search_data[0].sub_name + " (" + season_search_data[0].season_start + "월 ~ " + season_search_data[0].season_end + "월)";

          let fee_arr = season_search_data.filter(
            (e, i, callback) =>
              i === callback.findIndex(
                (e1) => e1.time_start === e.time_start
              )
          );

          for(let c=0; c < fee_arr.length; c++) {
            let time_count = 0;
            let fee_data = [];
            season_search_data.forEach((e,i) => {
              if (e.time_start === fee_arr[c].time_start) {
                fee_data.push(e);
              }
            });

            let time_span_count = fee_data.length;
            let time_text = fee_data[0].time_start.substr(0,2) + ":" + fee_data[0].time_start.substr(2,2)+ " ~ " + fee_data[0].time_end.substr(0,2) + ":" + fee_data[0].time_end.substr(2,2);
      
            fee_data.forEach((e,i) => {
              temp_data = e;
              temp_data.time_text = time_text;
              temp_data.time_span_count = time_span_count;
              temp_data.season_name = season_name;
              temp_data.season_span_count = season_span_count;
              temp_data.type_span_count = type_span_count;

              if (time_count === 0) {
                temp_data.isTime = true;
              } else {
                temp_data.isTime = false;
              }

              if (season_count === 0) {
                temp_data.isSeason = true;
              } else {
                temp_data.isSeason = false;
              }

              if (type_count === 0) {
                temp_data.isType = true;
              } else {
                temp_data.isType = false;
              }

              type_count++;
              season_count++;
              time_count++;

              grid_data.push(temp_data);
            });
          };
        };
      };

      this.items = grid_data;
    },
    add() {
      this.obj.bid = this.filterData.searchCompany;
      this.obj.bid_fee_list = this.temp_fee;
      
      this.$refs.modal_feeAdd.setData(this.obj);
      this.$bvModal.show("modal-fee-add");
    },
    edit() {
      this.updateChargeFee();

      window.alert("요금이 수정되었습니다.");

      let content = "업체명: " + this.items[0].company_name + ", 요금제명: " + this.items[0].cf_title;
      this.addAdminLog("요금 관리", "요금 금액 수정", "요금 수정팝업", content, this.admin_data.user_id);			// 관리자 명령 로그 추가
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
    changeCompany() {
      let temp_cfData = [];
      let tempData = [];
      let options = [];

      let search_company = this.filterData.searchCompany;
      this.charge_fee.forEach((e,i) => {
        if (e.bid === search_company) {
          tempData.push(e);
        }
      });

      this.temp_fee = tempData;

      let cf_data = tempData.filter(
        (e, i, callback) =>
          i === callback.findIndex(
            (e1) => e1.cf_id === e.cf_id
          )
      );

      for(let j = 0; j < cf_data.length; j++) {
        let temp_data = [];
        this.charge_fee.forEach((e,i) => {
          if (e.cf_id === cf_data[j].cf_id) {
            temp_data.push(e);
            temp_cfData.push(e);
          }
        });

        //temp_cfData.push(temp_data[0]);
        options.push({ text: temp_data[0].cf_title, value: temp_data[0].cf_id});
      };

      this.cf_list = temp_cfData;
      this.searchFeeOption = options;
      this.filterData.searchFee = this.searchFeeOption[0].value;

      this.setChargeFee();
    },
    async updateChargeFee() {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/updateChargeFee", items
        );
      } catch (error) {
        // console.log(7);
        window.alert("요금 수정을 실패했습니다.");
        console.log(error);
      }
    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("관리자 명령 등록을 실패했습니다.");
      }
    },
    reflash() {
      this.selectChargeFee();
    }
  },
  beforeDestroy() {
    //clearInterval(this.timerActive);
  },
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: goldenrod;
}
</style>