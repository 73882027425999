"use strict";

import Vue from "vue";
import axios from "axios";
import { setWithCredentials } from "ol/featureloader";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = ;
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL:
    // process.env.NODE_ENV === "product"
    //   ? "http://3.36.41.196:8100"
    //   :
  //"https://svr.home-bank.co.kr/api/v1",
  //"http://localhost:7071",
  //"http://localhost:6060",
  "https://evcloud.duruscoev.com",
  //"http://localhost:8806",
  //"http://119.197.83.227",
  //"http://112.175.232.250",
  //server domain
  //"http://3.36.41.196:8100/api/v1",
  // "https://svr.home-bank.co.kr/api/v1",
  //timeout: 3000, // Timeout
  setWithCredentials: true,
  headers: {
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    "Access-Control-Allow-Methods": "GET, POST",
  },
  responseType: "json",
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function (Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
