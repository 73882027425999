<template>
  <b-modal
    id="modal-board-detail"
    :title="obj.title"
    :ok-title="obj.ok_title"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="xl"
    @shown="init"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxWidth: '100%', maxHeight: '1000px' }"
    >
      <div>
        <b-row class="d-flex" style="gap: 10px; margin: 0px;">
          <div class="modal-input-row" style="flex: 1;">
            <div class="modal-input-title">
              <span class="text-17">
                <font-awesome-icon />작성자 ID
              </span>
            </div>
            <div class="modal-input-content">
              <b-form-input size="sm" v-model="boardData.reg_user" style="height: 50px; border: 1px solid #666666; border-radius: 10px; font-size: 15px; color: #414141;"></b-form-input>
            </div>
          </div>
          <div v-if="obj.type === 'NOTICE'" class="modal-input-row" style="flex: 1;">
            <div class="modal-input-title">
              <span class="text-17">
                <font-awesome-icon />분류
              </span>
            </div>
            <div class="modal-input-content">
              <b-form-select size="sm" v-model="boardData.code" :options="noticeTypeOptions"></b-form-select>
            </div>
          </div>
          <div v-else-if="obj.type === 'FAQ'" class="modal-input-row" style="flex: 1;">
            <div class="modal-input-title">
              <span class="text-17">
                <font-awesome-icon />분류
              </span>
            </div>
            <div class="modal-input-content">
              <b-form-select size="sm" v-model="boardData.code" :options="faqTypeOptions"></b-form-select>
            </div>
          </div>
          <div v-else-if="obj.type === 'QNA'" class="modal-input-row" style="flex: 1;">
            <div class="modal-input-title">
              <span class="text-17">
                <font-awesome-icon />분류
              </span>
            </div>
            <div class="modal-input-content">
              <b-form-select size="sm" v-model="boardData.code" :options="qnaTypeOptions"></b-form-select>
            </div>
          </div>
        </b-row>
        <b-row class="d-flex" style="gap: 10px; margin: 0px;">
          <div class="modal-input-row" style="flex: 1;">
            <div class="modal-input-title">
              <span class="text-17">
                <font-awesome-icon />작성일시
              </span>
            </div>
            <div class="modal-input-content">
              <b-form-input size="sm" type=number v-model="boardData.reg_date" style="height: 50px; border: 1px solid #666666; border-radius: 10px; font-size: 15px; color: #414141;"></b-form-input>
            </div>
          </div>
          <div class="modal-input-row" style="flex: 1;">
            <div class="modal-input-title">
              <span class="text-17">
                <font-awesome-icon />게시상태
              </span>
            </div>
            <div class="modal-input-content">
              <b-form-select size="sm" v-model="boardData.use_yn" :options="statusOptions"></b-form-select>
            </div>
          </div>
        </b-row>
        <b-row class="modal-input-row" style="height: 150px;">
          <div class="modal-input-title" style="height: 140px; flex: 0.45;">
            <span class="text-17">
              <font-awesome-icon />문의
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-textarea
              id="titleText"
              v-model="boardData.title"
              placeholder=""
              size="sm"
              style="border-radius: 10px;"
              rows="6"
              max-rows="6"
            ></b-form-textarea>
          </div>
        </b-row>
        <b-row class="modal-input-row" style="height: 150px;">
          <div class="modal-input-title" style="height: 140px; flex: 0.45;">
            <span class="text-17">
              <font-awesome-icon />내용
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-textarea
              id="answerText"
              v-model="boardData.descript"
              placeholder=""
              size="sm"
              style="border-radius: 10px;"
              rows="6"
              max-rows="6"
            ></b-form-textarea>
          </div>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>
import KakaoMap from "@/components/KakaoMap.vue";

export default {
  components: {
    
  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      type: String,
      edit_type: String,
      title: String,
      ok_title: String,
      param_data: Object,
    },
  },
  data() {
    return {
      admin_data: {},
      boardData: [

      ],
      faqTypeOptions: [
        { value: "FC01", text: "faq1" },
        { value: "FC02", text: "faq2" }
      ],
      qnaTypeOptions: [
        { value: "QC01", text: "QnA1" },
        { value: "QC02", text: "QnA2" }
      ],
      noticeTypeOptions: [
        { value: "NC01", text: "공지분류1" },
        { value: "NC02", text: "공지분류2" }
      ],
      statusOptions: [
        { value: "Y", text: "게시" },
        { value: "W", text: "대기" },
        { value: "N", text: "숨김" }
      ],
      rules: {
        address: [
          (v) =>
            !!v || {
              title: "주소 입력",
              message: "주소를 입력하세요.",
            },
        ],
        // addressDetail: [
        //   (v) =>
        //     !!v || {
        //       title: "상세 주소 입력",
        //       message: "상세 주소를 입력하세요.",
        //     },
        // ],
      },
    };
  },
  mounted() {
    if (this.$store.getters.user === null) {
      this.admin_data.user_id = "admin";
    } else {
      this.admin_data = this.$store.getters.user;
    }

    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {debugger;
        this.boardData = this.obj.param_data;
      });
    },
    async ok() {
      try {
        var type_url = "";

        if (this.obj.edit_type === "detail") {
          this.$bvModal.hide("modal-board-detail");
        } else {
          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            if (this.obj.type === "NOTICE") {
              this.addBoard("N");
            } else if (this.obj.type === "FAQ") {
              this.addBoard("F");
            } else if (this.obj.type === "QNA") {
              this.addBoard("Q");
            }
          } else if (this.obj.edit_type === "edit") {
            this.updateBoard();
          }

          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            window.alert("충전소 정보가 등록되었습니다.");
          } else if (this.obj.edit_type === "edit") {
            window.alert("충전소 정보가 수정되었습니다.");
          }

          this.$bvModal.hide("modal-board-detail");
        }
        //this.$root.$emit("hb::pdf::hidden", true);
      }  catch (error) {
        if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
          window.alert("충전소 정보 등록을 실패했습니다.");
        } else if (this.obj.edit_type === "edit") {
          window.alert("충전소 정보 수정을 실패했습니다.");
        }

        this.$bvModal.hide("modal-excel-upload");
        console.log(error);
      }
    },
    async addBoard(type_value) {debugger;
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addBoard", {
            type: type_value,
						code: this.boardData.type,
						title: this.boardData.title,
						descript: this.boardData.descript,
						reg_user: this.admin_data.user_id,
						reg_date: crt_date,
						update_date: crt_date,
						use_yn: this.boardData.use_yn,
						post_date: crt_date
          }
        );

        if (this.obj.type === "NOTICE") {
          window.alert("공지사항이 등록되었습니다.");
        } else if (this.obj.type === "FAQ") {
          window.alert("FAQ가 등록되었습니다.");
        } else if (this.obj.type === "QNA") {
          window.alert("QnA가 등록되었습니다.");
        }

        this.$emit("reflash");
        this.$bvModal.hide("modal-board-detail");
      } catch (error) {
        window.alert("게시물 등록을 실패했습니다.");
        console.log(error);
      }
    },
    async updateBoard() {debugger;
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/updateBoard", {
            id: this.boardData.id,
						code: this.boardData.type,
						title: this.boardData.title,
						descript: this.boardData.descript,
						update_date: crt_date,
						use_yn: this.boardData.use_yn,
						post_date: crt_date
          }
        );

        if (this.obj.type === "NOTICE") {
          window.alert("공지사항이 수정되었습니다.");
        } else if (this.obj.type === "FAQ") {
          window.alert("FAQ가 수정되었습니다.");
        } else if (this.obj.type === "QNA") {
          window.alert("QnA가 수정되었습니다.");
        }

        this.$emit("reflash");
        this.$bvModal.hide("modal-board-detail");
      } catch (error) {
        window.alert("게시물 수정을 실패했습니다.");
        console.log(error);
      }
    },
    setData(select_rowData) {debugger;
      this.boardData = select_rowData;

      if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
        if (this.obj.type === "NOTICE") {
          this.boardData.code = this.noticeTypeOptions[0].value;
        } else if (this.obj.type === "FAQ") {
          this.boardData.code = this.faqTypeOptions[0].value;
        } else if (this.obj.type === "QNA") {
          this.boardData.code = this.qnaTypeOptions[0].value;
        }

        this.boardData.use_yn = this.statusOptions[0].value;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
