<template>
  <b-modal
    id="modal-enc-add"
    title="설정 종류 추가"
    ok-title="추가"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="false"
    size="lg"
    @shown="init"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxWidth: '800px', maxHeight: '1000px' }"
    >
      <div>
        <b-row class="modal-input-row">
            <div class="modal-input-title">
              <span class="text-17">
                <font-awesome-icon /> 설정 종류
              </span>
            </div>
            <div class="modal-input-content">
              <b-form-input style="height: 50px; border-radius: 10px;" v-model="type"></b-form-input>
            </div>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  components: {
    
  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      param_data: Object
    }
  },
  data() {
    return {
      type: ""
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        
      });
    },
    async ok() {
      try {
        this.$emit("updateType", type);
        this.$bvModal.hide("modal-enc-add");
      }  catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
