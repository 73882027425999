<template>
  <b-modal
    id="modal-fw-del"
    title="펌웨어 삭제"
    ok-title="삭제"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="lg"
    @shown="init"
  >
    <div class="pr-md-2 overflow-auto">
      <div>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />제조사
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input size="sm" style="height: 50px; border-radius: 10px;" v-model="obj.param_data.company_name"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />모델ID
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input size="sm" style="height: 50px; border-radius: 10px;" v-model="obj.param_data.model_id"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />펌웨어버전
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input size="sm" style="height: 50px; border-radius: 10px;" v-model="obj.param_data.fw_ver"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />파일경로
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input size="sm" style="height: 50px; border-radius: 10px;" v-model="obj.param_data.ftp_url"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />파일명
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input size="sm" style="height: 50px; border-radius: 10px;" v-model="obj.param_data.url"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row" style="height: 150px;">
          <div class="modal-input-title" style="height: 95%;">
            <span class="text-17">
              <font-awesome-icon />변경내용
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-textarea
              id="note"
              v-model="obj.param_data.descript"
              placeholder=""
              size="sm"
              style="border-radius: 10px;"
              rows="6"
              max-rows="6"
            ></b-form-textarea>
          </div>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  components: {

  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      maker_list: Array,
      param_data: Object,
    },
  },
  data() {
    return {
      admin_data: {},
      file: null,
      file_name: "",
      fwData: {
        id : 0,
        fw_ver : "",
        descript : "",
        url : "",
        reg_user : "",
        reg_date : "",
        type : "",
        company_id : "",
        company_name : "",
        model_id : "",
        ftp_url : "",
        ftp_user : "",
        ftp_pass : "",
      },
      model_list: [],
      makerOptions: [],
      modelOptions: [],
      rules: {
        address: [
          (v) =>
            !!v || {
              title: "주소 입력",
              message: "주소를 입력하세요.",
            },
        ],
        // addressDetail: [
        //   (v) =>
        //     !!v || {
        //       title: "상세 주소 입력",
        //       message: "상세 주소를 입력하세요.",
        //     },
        // ],
      },
    };
  },
  mounted() {debugger;
    if (this.$store.getters.user === null) {
      this.admin_data.user_id = "admin";
    } else {
      this.admin_data = this.$store.getters.user;
    }

    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {debugger;
        this.fwData = this.obj.param_data;
      });
    },
    async ok() {
      try {
        this.delFw();

        window.alert("펌웨어 삭제가 완료되었습니다.");
        var context = "펌웨어버전 : " + this.fwData.fw_ver;
        this.addAdminLog("펌웨어 관리", "펌웨어 삭제", "펌웨어 삭제팝업", context, this.admin_data.user_id);
        this.$emit("reflash");
        this.$bvModal.hide("modal-fw-del");
        
      }  catch (error) {
        window.alert("펌웨어 삭제를 실패했습니다.");
        console.log(error);
      }
    },
    async delFw() {
      try {
        const { data } = await this.$axios.post(
          "/api/delFw", {
            fw_id: this.fwData.id,
          }
        );

        this.fileDel();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async fileDel() {

    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("관리자 명령 로그 등록을 실패했습니다.");
      }
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
  },
};
</script>

<style lang="scss" scoped></style>
