import { render, staticRenderFns } from "./ReaderList.vue?vue&type=template&id=b6a5f7f2&scoped=true&"
import script from "./ReaderList.vue?vue&type=script&lang=js&"
export * from "./ReaderList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6a5f7f2",
  null
  
)

export default component.exports