<template>
  <b-modal
    id="modal-station-del"
    title="충전소 삭제"
    ok-title="삭제"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="false"
    size="lg"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxWidth: '800px', maxHeight: '1000px' }"
    >
      <div>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />충전소 ID
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" v-model="stationData.sid"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />충전소 이름
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" v-model="stationData.name"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />충전소 주소
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" v-model="full_addr"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />삭제사유
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="stationData.del_dtl"></b-form-input>
          </div>
        </b-row>
      </div>
    </div>
    <div class="pt-3">
      <div ref="addr" />
    </div>
  </b-modal>
</template>

<script>
export default {
  components: {
    
  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      edit_type: String,
      title: String,
      ok_title: String,
      station_data: Object,
    },
  },
  data() {
    return {
      admin_data: {},
      stationData: [],
      full_addr: "",
      rules: {
        address: [
          (v) =>
            !!v || {
              title: "주소 입력",
              message: "주소를 입력하세요.",
            },
        ],
        // addressDetail: [
        //   (v) =>
        //     !!v || {
        //       title: "상세 주소 입력",
        //       message: "상세 주소를 입력하세요.",
        //     },
        // ],
      },
    };
  },
  mounted() {
    if (this.$store.getters.user === null) {
      this.admin_data.user_id = "admin";
    } else {
      this.admin_data = this.$store.getters.user;
    }
    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        
      });
    },
    async ok() {debugger;
      try {
        this.delStation();
      }  catch (error) {
        console.log(error);
      }
    },
    async delStation() {
      try {
        const { data } = await this.$axios.post(
          "/api/delStation", {
            sid: this.stationData.sid,
						del_dtl: this.stationData.del_dtl,
          }
        );

        window.alert("충전소 정보가 삭제되었습니다.");
        var context = "충전소ID : " + this.stationData.sid;
        this.addAdminLog("충전소 관리", "충전소 삭제", "충전소 삭제팝업", context, this.admin_data.user_id);
        this.$emit("reflash");

        this.$bvModal.hide("modal-station-del");
      } catch (error) {
        window.alert("충전소 삭제를 실패했습니다.");
      }
    },
    setData(select_rowData) {debugger;
      this.stationData = select_rowData;
      this.full_addr = this.stationData.addr + " " + this.stationData.addr_dtl;
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("OCPP 충전기 정보 등록을 실패했습니다.");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
