<template>
  <b-modal
    id="modal-company-detail"
    :title="obj.title"
    :ok-title="obj.ok_title"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="false"
    size="lg"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxWidth: '800px', maxHeight: '1000px' }"
    >
      <div>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />법인 ID
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="companyData.company_id"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />법인 이름
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="companyData.name"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />담당자 이름
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="companyData.manager_name"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />담당자 연락처
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" type=number v-model="companyData.phone"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row" style="height: 115px;">
          <div class="modal-input-title" style="height: 105px;">
            <span class="text-17">
              <font-awesome-icon />지점 주소
            </span>
          </div>
          <div class="modal-input-content flex-column" style="gap: 5px;">
            <div class="d-flex" style="gap: 10px; width: 100%;">
              <b-form-input readonly style="height: 50px; border-radius: 10px;" v-model="companyData.addr"></b-form-input>
              <b-button class="btn_def" style="width: 106px;" @click="getPostCode">검색</b-button>
            </div>
            <div class="d-flex" style="gap: 10px; width: 100%;">
              <b-form-input style="height: 50px; border-radius: 10px;" v-model="companyData.addr_dtl"></b-form-input>
              <b-button class="btn_def" style="width: 100px;" @click="showMap">지도보기</b-button>
            </div>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />담당자 이메일
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="companyData.email"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />사업자 등록증
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input type=number style="height: 50px; border-radius: 10px;" v-model="companyData.certification_url"></b-form-input>
          </div>
        </b-row>
        <b-row class="">
          <div class="modal-input-title" style="margin-bottom: 10px;">
            <span class="text-17">
              <font-awesome-icon />정산 정보
            </span>
          </div>
          <div>
            
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />은행명
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select size="sm" v-model="companyData.bank_code" :options="bankOptions" @change="changeBank($event)"></b-form-select>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />계좌번호
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" type=number v-model="companyData.account_no"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />계약일자
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" type="date" v-model="companyData.reg_date"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />정산타입
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select style="height: 50px; border-radius: 10px;" v-model="companyData.account_type" :options="calTypeOptions"></b-form-select>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />정산일
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" type=number v-model="companyData.account_day"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />계약상태
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select size="sm" v-model="companyData.status" :options="statusOptions"></b-form-select>
          </div>
        </b-row>
      </div>
    </div>
    <div class="pt-3">
      <div ref="addr" />
    </div>

    <!-- modals -->
    <modal-show-map :obj="obj_addr" />
  </b-modal>
</template>

<script>
import KakaoMap from "@/components/KakaoMap.vue";

import ModalShowMap from "./ShowMap.vue";

export default {
  components: {
    KakaoMap,
    ModalShowMap,
  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
    
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      edit_type: String,
      title: String,
      ok_title: String,
      company_data: Object,
    },
    value: {
      type: Object,
    },
  },
  data() {
    return {
      admin_data: {},
      company_list: [],
      companyData: [],
      bankOptions: [],
      calTypeOptions: [
        { value: "1", text: "일괄결제" },
        { value: "2", text: "월결제" },
        { value: "3", text: "주결제" },
      ],
      statusOptions: [
        { value: "S", text: "계약중" },
        { value: "E", text: "계약종료" },
        { value: "C", text: "계약취소" },
        { value: "W", text: "계약대기" },
      ],
      addressObj: {},
      obj_addr: {
        address: "",
        addressDetail: ""
      },
      find_company: false,
      wrap: null,
      rules: {
        address: [
          (v) =>
            !!v || {
              title: "주소 입력",
              message: "주소를 입력하세요.",
            },
        ],
        // addressDetail: [
        //   (v) =>
        //     !!v || {
        //       title: "상세 주소 입력",
        //       message: "상세 주소를 입력하세요.",
        //     },
        // ],
      },
    };
  },
  mounted() {
    if (this.$store.getters.user === null) {
      this.admin_data.user_id = "admin";
    } else {
      this.admin_data = this.$store.getters.user;
    }

    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.selectBankCode();
        this.wrap = this.$refs.addr;

        if (this.obj.edit_type === "add") {
          let crt_date = this.getToday();

          this.companyData = this.obj.company_data;
          this.companyData.reg_date = crt_date.substring(0, 4) + "-" + crt_date.substring(4, 6) + "-" + crt_date.substring(6, 8);
        } else {
          let regDate = this.obj.company_data.reg_date;

          this.companyData.reg_date = regDate.substring(0, 4) + "-" + regDate.substring(4, 6) + "-" + regDate.substring(6, 8);
          this.obj_addr.address = this.obj.company_data.addr;
          this.obj_addr.addressDetail = this.obj.company_data.addr_dtl;
        }
      });
    },
    async selectBankCode() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectBankCode", {
            //use_yn: "Y", 
            //type: "STC"
          }
        );
        if (data.length > 0) {
          var options = [];

          data.forEach((e,i) => {
            let add_option = { value: e.bank_code, text: e.bank_name }
            options.push(add_option);
          });

          this.bankOptions = options;

          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            this.companyData.bank_code = this.bankOptions[0].value;
          }
        } else if (data.length == 0) {
          this.bankOptions = null;
        }

        
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async ok() {
      try {
        if (this.obj.edit_type === "detail") {
          this.$bvModal.hide("modal-company-detail");
        } else {
          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            this.addCompany();
            //type_url = "/api/addCompany";
          } else if (this.obj.edit_type === "edit") {
            this.updateCompany();
            //type_url = "/api/updateCompany";
          }

          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            window.alert("법인 정보가 등록되었습니다.");
            var context = "법인종류 : " + "일반법인" + ", 법인이름: " + this.companyData.name;
            this.addAdminLog("법인 관리", "법인 등록", "법인 등록팝업", context, this.admin_data.user_id);
          } else if (this.obj.edit_type === "edit") {
            window.alert("법인 정보가 수정되었습니다.");
            var context = "법인종류 : " + "일반법인" + ", 법인이름: " + this.companyData.name;
            this.addAdminLog("법인 관리", "법인 수정", "법인 수정팝업", context, this.admin_data.user_id);
          }

          this.$emit("reflash");
          this.$bvModal.hide("modal-company-detail");
        }
        //this.$root.$emit("hb::pdf::hidden", true);
      }  catch (error) {
        if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
          window.alert("법인 정보 등록을 실패했습니다.");
        } else if (this.obj.edit_type === "edit") {
          window.alert("법인 정보 수정을 실패했습니다.");
        }

        console.log(error);
      }
    },
    async addCompany() {
      let crt_date = this.getToday();

      // 신규등록 법인ID 조합
      this.findCompany();
      
      if (this.find_company === true) {
        window.alert("법인ID가 이미 존재합니다. 변경 후 다시 시도해 주시기 바랍니다.");
      } else if (this.find_company === false) {
        var company_useYn = "";

        if (this.companyData.status == "E" || this.companyData.status == "C" || this.companyData.status == "W") {
          this.companyData.use_yn = "N";
        } else {
          this.companyData.use_yn = "Y";
        };

        this.companyData.type = "S";

        try {
          const { data } = await this.$axios.post(
            "/api/addCompany", {
              company_id: this.companyData.company_id,
              name: this.companyData.name,
              type: this.companyData.type,
              phone: this.companyData.phone,
              manager_name: this.companyData.manager_name,
              addr: this.companyData.addr,
              addr_dtl: this.companyData.addr_dtl,
              gps: this.companyData.gps,
              email: this.companyData.email,
              bank_code: this.companyData.bank_code,
              bank_name: this.companyData.bank_name,
              account_no: this.companyData.account_no,
              reg_date: crt_date,
              update_date: crt_date,
              use_yn: this.companyData.use_yn,
              certification_url: this.companyData.certification_url,
              account_type: this.companyData.account_type,
              account_day: this.companyData.account_day,
              status: this.companyData.status
            }
          );
        } catch (error) {
          // console.log(7);
          console.log(error);
        }
      }
    },
    async updateCompany() {
      let crt_date = this.getToday();

      var company_useYn = "";
      if (this.companyData.status == "E" || this.companyData.status == "C" || this.companyData.status == "W") {
        this.companyData.use_yn = "N";
      } else {
        this.companyData.use_yn = "Y";
      };

      try {
        const { data } = await this.$axios.post(
          "/api/updateCompany", {
            phone: this.companyData.phone,
            manager_name: this.companyData.manager_name,
            addr: this.companyData.addr,
            addr_dtl: this.companyData.addr_dtl,
            gps: this.companyData.gps,
            email: this.companyData.email,
            bank_code: this.companyData.bank_code,
            bank_name: this.companyData.bank_name,
            account_no: this.companyData.account_no,
            update_date: crt_date,
            use_yn: this.companyData.use_yn,
            certification_url: this.companyData.certification_url,
            account_type: this.companyData.account_type,
            account_day: this.companyData.account_day,
            status: this.companyData.status,
            company_id: this.companyData.company_id,
          }
        );
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async selectCompanyList() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectCompanyList", {
            type: "S"
          }
        );

        if (data.length > 0) {
          this.company_list = data;

          var company_count = this.company_list.length;
          var company_lastData = this.company_list[company_count - 1];
          var company_no = parseInt(company_lastData.company_id.substring(3,8)) + 1;debugger;

          var max_regNo = '00000' + String(company_no);
          var mod_regNo = max_regNo.slice(-5);
          var company_Id = "CPS" + mod_regNo;
          this.companyData.company_id = company_Id;
        } else if (data.length === 0) {
          this.companyData.company_id = "CPS00001";
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async findCompany() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectCompanyList", {
            company_id: this.companyData.company_id
          }
        );

        if (data.length > 0) {
          this.find_company = false;
        } else if (data.length === 0) {
          this.find_company = true;
        } 
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    setData(select_rowData) {
      this.companyData = select_rowData;

      if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
        this.companyData.bank_code = this.bankOptions[0].value;
        this.companyData.account_type = this.calTypeOptions[0].value;
        this.companyData.status = this.statusOptions[0].value;

        this.obj_addr.address = this.obj.company_data.addr;
        this.obj_addr.addressDetail = this.obj.company_data.addr_dtl;

        let crt_date = this.getToday();
        this.companyData.reg_date = crt_date.substring(0, 4) + "-" + crt_date.substring(4, 6) + "-" + crt_date.substring(6, 8);

        this.selectCompanyList();
      } else {
        let regDate = this.companyData.reg_date;
        this.companyData.reg_date = regDate.substring(0, 4) + "-" + regDate.substring(4, 6) + "-" + regDate.substring(6, 8);
      }

      this.changeBank(this.companyData.bank_code);
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
    showMap() {
      if (this.companyData.addr === "") {
        window.alert("법인 주소를 검색하신 후 다시 시도해 주시기 바랍니다.");
      } else {
        //this.isShowMap = true;
        this.obj_addr.address = this.companyData.addr;
        this.obj_addr.addressDetail = this.companyData.addr_dtl;
        this.$bvModal.show("modal-show-map");
      }
    },
    foldDaumzonecode() {
      // iframe을 넣은 element를 안보이게 한다.
      this.wrap.style.display = "none";
    },
    getPostCode() {
      // 현재 scroll 위치를 저장해놓는다.
      var currentScroll = Math.max(
        document.body.scrollTop,
        document.documentElement.scrollTop
      );
      new daum.Postcode({
        //autoMappingJibun: false, //연관주소가 지번일 때 지번 주소 자동매핑을 막음
       // autoMappingRoad: false, //연관주소가 도로명일 때  주소 자동매핑을 막음

        oncomplete: (data) => {
          console.log(data);
          // 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

          // 각 주소의 노출 규칙에 따라 주소를 조합한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          var addr = ""; // 주소 변수
          var extraAddr = ""; // 참고항목 변수

          //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
          if (data.userSelectedType === "R") {
            // 사용자가 도로명 주소를 선택했을 경우
            addr = data.roadAddress;
          } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            addr = data.jibunAddress;
          }

          // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
          if (data.userSelectedType === "R") {
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            // 조합된 참고항목을 해당 필드에 넣는다.
          }
          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          //===================================================================
          //jibunAddressEnglish 또는 autoJibunAddressEnglish에 랜덤으로 값이 담겨 필터링 추가함
          let jibun = "";

          if (data.autoJibunAddressEnglish === "") {
            jibun = data.jibunAddressEnglish.split(",")[0];
          } else {
            jibun = data.autoJibunAddressEnglish.split(",")[0];
          }

          //====================================================================
          this.add = jibun;
          this.addressObj = {
            gps_x: "",
            gps_y: "",
            zipNo: data.zonecode,
            address: `${addr} ${extraAddr}`,
            sido: data.sido,
            jibunAddress:
              data.jibunAddress !== ""
                ? data.jibunAddress
                : data.autoJibunAddress,
            roadAddress:
              data.roadAddress != "" ? data.roadAddress : data.autoRoadAddress,
            addressDetailMeta: {
              bcode: data.bcode,
              bname: data.bname,
              bname1: data.bname1,
              buildingCode: data.buildingCode,
              buildingName: data.buildingName,
              bun: jibun.split("-")[0] || "",
              ji: jibun.split("-")[1] || "",
              sido: data.sido,
              sigungu: data.sigungu,
              sigunguCode: data.sigunguCode,
            },
          };

          this.companyData.addr = this.addressObj.address;
          
          const geocoder = new kakao.maps.services.Geocoder();

          let gps_x = "";
          let gps_y = "";

          // 주소로 좌표를 검색합니다
          geocoder.addressSearch(`${addr} ${extraAddr}`, (result, status) => {
            // 정상적으로 검색이 완료됐으면
            if (status === kakao.maps.services.Status.OK) {
              console.log("OK",result)
              const coords = new kakao.maps.LatLng(result[0].y, result[0].x);
              //const message = 'latlng: new kakao.maps.LatLng(' + result[0].y + ', ';
              //message += result[0].x + ')';

              gps_x = result[0].x;
              gps_y = result[0].y;

              this.companyData.gps = gps_x + "," + gps_y;
              this.addressObj.gps_x = gps_x;
              this.addressObj.gps_y = gps_y;
            }
          });

          // 커서를 상세주소 필드로 이동한다.
          // this.$refs.addrDetail.$el.focus();

          // iframe을 넣은 element를 안보이게 한다.
          // (autoClose:false 기능을 이용한다면, 아래 코드를 제거해야 화면에서 사라지지 않는다.)
          //this.wrap.style.display = "none";

          // 우편번호 찾기 화면이 보이기 이전으로 scroll 위치를 되돌린다.
          document.body.scrollTop = currentScroll;
        },

        // 우편번호 찾기 화면 크기가 조정되었을때 실행할 코드를 작성하는 부분. iframe을 넣은 element의 높이값을 조정한다.
        // onresize: (size) => {
        //   this.wrap.style.height = size.height + "px";
        // },
        // width: "100%",
        // height: "100%",
      }).open({ autoClose: true }); //주소 선택시 팝업창 닫기
      // iframe을 넣은 element를 보이게 한다.
      // this.wrap.style.display = "block";
      // test(address);
    },
    changeBank (select_code) {
      this.bankOptions.filter((e) => {
          if (e.value === select_code) {
            this.companyData.bank_name = e.text;
          }
        }
      )
    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("법인 정보 변경 로그 등록을 실패했습니다.");
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
