import { render, staticRenderFns } from "./ReaderList.vue?vue&type=template&id=ab2c31b6&scoped=true&"
import script from "./ReaderList.vue?vue&type=script&lang=js&"
export * from "./ReaderList.vue?vue&type=script&lang=js&"
import style0 from "./ReaderList.vue?vue&type=style&index=0&id=ab2c31b6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab2c31b6",
  null
  
)

export default component.exports