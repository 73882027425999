<template>
  <div class="" style="height: inherit;" ref="element" v-resize @resize="onResize">
    <b-container>
      <b-row style="margin: 0px 0px 10px 0px;">
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">검색조건</span>
          <div class="d-flex align-items-center" style="gap: 10px; flex: 4; padding: 5px;">
            <b-form-select size="sm" style="flex: 1;" v-model="filterData.searchKeyType" @change="changeSearchKey" :options="searchKeyOption"></b-form-select>
            <b-form-input 
              size="sm"
              v-model="filterData.searchKey"
              style="flex: 3; height: 50px; border-radius: 10px;"
              @change="changeSearchKey"
            ></b-form-input>
          </div>
        </b-col>
        <b-col lg=6>
          
        </b-col>
      </b-row>
      <b-row style="margin: 0px 0px 10px 0px;">
        <b-col lg=6 class="d-flex justify-content-between flex-row align-items-center">
          <span>전체 {{ gridFilterData.length }} 건</span>
        </b-col>
        <b-col lg=6 class="d-flex justify-content-end">
          <b-button class="btn_def" @click="detail('add')">등록</b-button>
          <b-button class="btn_def" @click="detail('edit')">수정</b-button>
          <b-button class="btn_def" @click="delCharger()">삭제</b-button>
          <b-button class="btn_def" @click="DownloadExcel">엑셀다운</b-button>
          <b-button class="btn_def" @click="UploadExcel">엑셀업로드</b-button>
          <b-button class="btn_def" href="http://localhost:6060/charger_sampleDownload?filename='charger_sample.xlsx'" download="charger_sample.xlsx" @click="SampleDown">샘플다운</b-button>
        </b-col>
      </b-row>
      <b-row style="width: inherit; margin: 0px;" :style="{'height': page_grid_height + 'px'}">
        <div class="table-wrapper2" style="width: inherit;" >
          <b-table 
            id = "grid"
            bordered hover noCollapse
            :items="gData"
            :fields="gridFields"
            small
          >
            <template #cell(No)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ row.index + 1 + ((currentPage - 1) * perPage) }}
              </div>
            </template>
            <template #cell(sname)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].s_name }}
              </div>
            </template>
            <template #cell(sid)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].sid }}
              </div>
            </template>
            <template #cell(cid)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].cid }}
              </div>
            </template>
            <template #cell(tel_num)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ phoneFormatter(gData[row.index].reader_id) }}
              </div>
            </template>
            <template #cell(laf)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].laf_name }}
              </div>
            </template>
            <template #cell(maker)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].maker_name }}
              </div>
            </template>
            <template #cell(reg_date)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ regDateFormatter(gData[row.index].reg_date) }}
              </div>
            </template>
            <template #cell(status)="row">
              <div 
              :class="{ 'td-color-green': gData[row.index].status_name === '사용 가능', 'td-color-red': gData[row.index].status_name === '준비중' || gData[row.index].status_name === '보류중', 'td-color-blue': gData[row.index].status_name === '충전중' || gData[row.index].status_name === '충전완료중', 'td-color-gray': gData[row.index].status_name === '예약대기' || gData[row.index].status_name === '존재하지 않음'}" 
              style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].status_name }}
              </div>
            </template>
            <template #cell(detail)>
              <b-button size="sm" @click="detail('detail')" class="btn_def">상세정보</b-button>
            </template>
            <template #cell(remote_call)="row">
              <b-button size="sm" @click="remoteCall(row.index)" class="btn_def">원격제어</b-button>
            </template>
          </b-table>
        </div>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="gridFilterData.length"
          :per-page="perPage"
          first-text="⏮"
          prev-text="⏪"
          next-text="⏩"
          last-text="⏭"
          class="mt-4"
          size="sm"
        ></b-pagination>
      </b-row>

      <!-- modals -->
      <modal-excel-upload :file_type="file_type"/>
      <modal-charger-detail style="width: 1200px;" ref="modal_chargerDtl" :obj="obj" @reflash="reflash"/>
      <modal-charger-del style="width: 1200px;" ref="modal_chargerDel" :obj="obj" @reflash="reflash"/>
      <modal-remote-call ref="modal_remoteCall" :obj="obj_remote"/>
    </b-container>
  </div>
</template>

<script>
import Form from "@/components/Form/index";
import regex from "@/lib/regex";

import Modals from "@/components/Modals/index";
import { ref } from 'vue';

import * as XLSX from 'xlsx' // 라이브러리 import

import $ from 'jquery';

export default {
  components: {
    // Modals
    ModalExcelUpload: Modals.ExcelUpload,
    ModalChargerDetail: Modals.ChargerDetail,
    ModalChargerDel: Modals.ChargerDel,
    ModalReaderList: Modals.ReaderList,
    ModalModelList: Modals.ModelList,
    ModalRemoteCall: Modals.RemoteCall
  },
  data() {
    const isDev = process.env.NODE_ENV !== "production";

    return {
      formValid: false,
      salt: 0,
      crt_width: 0,
      crt_height: 0,
      page_grid_height: 0,
      select_index: -1,
      select_rowData: {
        sid : "",
        s_name : "",
        cid : "",
        reader_id : "",
        bid : "",
        b_name : "",
        type : "",
        member : "",
        laf : "F",
        laf_dtl : "",
        pay : "Y",
        cable : "",
        stat_dt : "",
        expired_dt : "",
        note : "",
        year : 0,
        month : 0,
        maker : "",
        maker_name : "",
        owner : "",
        sign : "",
        setup : "",
        subsidy : "N",
        subsidy_amount : "",
        motie : "",
        limit_yn : "",
        limit_dtl : "",
        del_yn : "",
        del_dtl : "",
        reg_date : "",
        reg_user : "",
        update_date : "",
        update_user : "",
        c_channel : "",
        p_terminal : "N",
        i_funnel : "1",
        use_yn : "Y",
        failure_yn : "",
        r_type : "",
        c_capacity : "",
        audio_ver : "",
        c_standard : "",
        firmware_ver : "",
        pn_id : "",
        r_method : "",
        as_id : "",
        as_name : "",
        tr_yn : "",
        ocpp_yn : "",
        kakao_yn : "",
        tmap_yn : "",
        descript : "",
        zcode : "",
        zsgcode : "",
        laf_name : "",
        status : "Available",
        status_name : "",
        bis_id : "",
        mid : "",
        tem_id : "",
        page_num : "",
        id : 0,
        model_id : "",
        ch_id : 0,
      },
      perPage: 1,
      currentPage: 1,
      gridDefaultData: [

      ],
      gridFilterData: [

      ],
      gData: [

      ],
      gridFields: [
        {
          key: "No",
          label: "No"
        },
        {
          key: "sname",
          label: "충전소명"
        },
        {
          key: "sid",
          label: "충전소 ID"
        },
        {
          key: "cid",
          label: "충전기 ID"
        },
        {
          key: "tel_num",
          label: "단말기번호"
        },
        {
          key: "laf",
          label: "급속/완속"
        },
        {
          key: "maker",
          label: "제조사"
        },
        {
          key: "reg_date",
          label: "등록일"
        },
        {
          key: "status",
          label: "상태"
        },
        {
          key: "detail",
          label: "상세보기"
        },
        {
          key: "remote_call",
          label: "원격제어"
        }
      ],
      filterData: {
        searchKeyType: "all",
        searchKey: ""
      },
      searchKeyOption: [
        { text: "전체", value: "all" },
        { text: "충전소ID", value: "sid" },
        { text: "충전소이름", value: "sname" },
        { text: "단말기번호", value: "tel_num" },
        { text: "MID", value: "mid" }
      ],
      file_type: "charger_list",
      rules: {
        email: [
          (v) =>
            !!v || { title: "이메일 입력", message: "이메일을 입력하세요." },
          (v) =>
            regex.serviceId.test(v) || {
              title: "이메일 형식",
              message: "이메일 형식이 올바르지 않습니다.",
            },
        ],
        mobile: [
          (v) =>
            !!v || {
              title: "핸드폰 번호 입력",
              message: "휴대폰 번호를 입력하세요.",
            },
          (v) =>
            v.length >= 10 || {
              title: "핸드폰 번호 입력",
              message: "휴대폰 번호 형식이 올바르지 않습니다.",
            },
        ],
        code: [
          (v) =>
            !!v || {
              title: "핸드폰 번호 인증",
              message: "인증번호를 입력하세요.",
            },
          (v) =>
            v.length === 4 || {
              title: "핸드폰 번호 인증",
              message: "인증번호를 입력하세요.",
            },
          (v) =>
            (v.length === 4 && this.serverValid.code) || {
              title: "핸드폰 번호 인증",
              message: "핸드폰 번호를 인증해주세요.",
            },
        ],
        password: [
          (v) =>
            !!v || {
              title: "비밀번호 입력",
              message: "비밀번호를 입력하세요.",
            },
          (v) =>
            regex.password.test(v) || {
              title: "비밀번호 형식",
              message: "8-16자 영문 대/소문자, 숫자, 특수문자를 사용하세요.",
            },
        ],
        passwordConfirm: [
          (v) =>
            !!v || {
              title: "비밀번호 확인",
              message: "비밀번호 확인을 입력하세요.",
            },
          (v) =>
            this.resetPassword.newPassword === v || {
              title: "비밀번호 확인",
              message: "비밀번호 확인이 일치하지 않습니다",
            },
        ],
      },
      excelUp_show: false,
      chargerDetail_show: false,
      obj: {
        edit_type: "add",
        title: "충전기 등록",
        ok_title: "등록",
        charger_data: {
          sid : "",
          s_name : "",
          cid : "",
          reader_id : "",
          bid : "",
          b_name : "",
          type : "",
          member : "",
          laf : "F",
          laf_dtl : "",
          pay : "Y",
          cable : "",
          stat_dt : "",
          expired_dt : "",
          note : "",
          year : 0,
          month : 0,
          maker : "",
          maker_name : "",
          owner : "",
          sign : "",
          setup : "",
          subsidy : "N",
          subsidy_amount : "",
          motie : "",
          limit_yn : "",
          limit_dtl : "",
          del_yn : "",
          del_dtl : "",
          reg_date : "",
          reg_user : "",
          update_date : "",
          update_user : "",
          c_channel : "",
          p_terminal : "N",
          i_funnel : "1",
          use_yn : "Y",
          failure_yn : "",
          r_type : "",
          c_capacity : "",
          audio_ver : "",
          c_standard : "",
          firmware_ver : "",
          pn_id : "",
          r_method : "",
          as_id : "",
          as_name : "",
          tr_yn : "",
          ocpp_yn : "",
          kakao_yn : "",
          tmap_yn : "",
          descript : "",
          zcode : "",
          zsgcode : "",
          laf_name : "",
          status : "Available",
          status_name : "",
          bis_id : "",
          mid : "",
          tem_id : "",
          page_num : "",
          id : 0,
          model_id : "",
          ch_id : 0,
        },
      },
      obj_remote: {
        param_data: {}
      }
    };
  },
  computed: {
    
  },
  watch: {
    currentPage: function(newVal, oldVal) {
      let items = this.gridFilterData;

      this.selectPage(newVal);
    },
  },
  methods: {
    async selectChargerList() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectChargerList", {
            
          }
        );
        if (data.length > 0) {
          this.gData = data;
          this.gridDefaultData = data;
        } else if (data.length == 0) {
          this.gData = null;
          window.alert("데이터가 없습니다.");
        }

        this.changeSearchKey();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    detail(type) {
      if (type === "add") {
        this.obj.edit_type = type;
        this.obj.title = "충전기 등록";
        this.obj.ok_title = "등록";
        this.obj.charger_data = { 
          sid : "",
          s_name : "",
          cid : "",
          reader_id : "",
          bid : "",
          b_name : "",
          type : "",
          member : "",
          laf : "F",
          laf_dtl : "",
          pay : "Y",
          cable : "",
          stat_dt : "",
          expired_dt : "",
          note : "",
          year : 0,
          month : 0,
          maker : "",
          maker_name : "",
          owner : "",
          sign : "",
          setup : "",
          subsidy : "N",
          subsidy_amount : "",
          motie : "",
          limit_yn : "",
          limit_dtl : "",
          del_yn : "",
          del_dtl : "",
          reg_date : "",
          reg_user : "",
          update_date : "",
          update_user : "",
          c_channel : "",
          p_terminal : "N",
          i_funnel : "1",
          use_yn : "Y",
          failure_yn : "",
          r_type : "",
          c_capacity : "",
          audio_ver : "",
          c_standard : "",
          firmware_ver : "",
          pn_id : "",
          r_method : "",
          as_id : "",
          as_name : "",
          tr_yn : "",
          ocpp_yn : "",
          kakao_yn : "",
          tmap_yn : "",
          descript : "",
          zcode : "",
          zsgcode : "",
          laf_name : "",
          status : "Available",
          status_name : "",
          bis_id : "",
          mid : "",
          tem_id : "",
          page_num : "",
          id : 0,
          model_id : "",
          ch_id : 0,
        };
        this.$refs.modal_chargerDtl.setData(this.obj.charger_data);
        this.$bvModal.show("modal-charger-detail");
      } else {
        if (this.select_index > -1) { 
          if (type === "edit") {
            this.obj.edit_type = type;
            this.obj.title = "충전기 수정";
            this.obj.ok_title = "수정";
            this.obj.charger_data = this.select_rowData;
          } else if (type === "detail") {
            this.obj.edit_type = type;
            this.obj.title = "충전기 정보";
            this.obj.ok_title = "닫기";
            this.obj.charger_data = this.select_rowData;
          }

          this.$refs.modal_chargerDtl.setData(this.select_rowData);
          this.$bvModal.show("modal-charger-detail");
        } else if (this.select_index == -1) {
          window.alert("충전소를 선택하신 후 다시 시도해 주시기 바랍니다.");
        }
      }
    },
    delCharger() {debugger;
      if (this.select_index > -1) {
        this.obj.charger_data = this.select_rowData;
        this.$refs.modal_chargerDel.setData(this.obj.charger_data);
        this.$bvModal.show("modal-charger-del");
      } else if (this.select_index == -1) {
        window.alert("충전기를 선택하신 후 다시 시도해 주시기 바랍니다.");
      }
    },
    remoteCall(row_index) {
      let charger_data = this.gData[row_index];
      this.obj_remote.param_data = charger_data;
      this.ocppConnLastLog(charger_data.sid, charger_data.reader_id);
    },
    async ocppConnLastLog(sid_value, tel_num_value) {
      try {
        const { data } = await this.$axios.post(
          "/api/selectOcppConnLastLog", {
            sid: sid_value, 
            tel_num: tel_num_value
          }
        );
        if (data.length > 0) {
          this.$bvModal.show("modal-remote-call");
        } else if (data.length === 0) {
          window.alert("연결된 충전기가 없습니다. 확인하신 후 다시 시도해 주시기 바랍니다.");
        }

        this.changeSearchKey();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    clipboardCopy(index) {
      var copy_data = JSON.stringify(this.gData[index]);
      window.navigator.clipboard.writeText(copy_data).then( function (res) {alert("복사되었습니다.");} );
    },
    DownloadExcel() {
      var crt_date = this.getToday();
      var excel_name = "charger_list_" + crt_date;
      var workBook = XLSX.utils.book_new(); // 새로운 workbook 생성 
      var grid_list = XLSX.utils.json_to_sheet(this.gridFilterData);
      // var stdData = XLSX.utils.json_to_sheet(this.checkStdList); // json 객체를 sheet로 변환하여 워크시트 생성
      
      XLSX.utils.book_append_sheet(workBook, grid_list, 'charger_list'); // stdData 워크시트로 시트 생성, 해당 시트 이름 명명
      // XLSX.utils.book_append_sheet(workBook, uncheckData, '학생현황'); // 시트 추가
      XLSX.writeFile(workBook, excel_name + '.xlsx'); // 파일 생성;
    },
    UploadExcel() {
      this.file_type = "charger_list";
      this.$bvModal.show("modal-excel-upload");
    },
    async SampleDown() {
      try {
        const response = await this.$axios.post(
          "/api/SampleDownload", {
            filename: "charger_sample",
            responseType: "blob"
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "charger_sample.xlsx");
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
    changeSearchKey() {
      let search_key = this.filterData.searchKey;
      let search_type = this.filterData.searchKeyType;
      let temp_data = [];

      //this.gridDefaultData.

      if (search_key != "") {
        if (search_type === "all") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.sid.indexOf(search_key) != -1 || e.s_name.indexOf(search_key) != -1 || e.reader_id.indexOf(search_key) != -1 || e.mid.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "sid") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.sid.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "s_name") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.s_name.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "tel_num") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.reader_id.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "mid") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.mid.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        }

        this.gridFilterData = temp_data;
      } else {
        this.gridFilterData = this.gridDefaultData;
      }

      this.pageSet();
    },
    selectRow(row_index) {
      var tbody = document.querySelector("#grid");
      var trs = tbody.getElementsByTagName('tr');
      
      var selectIndex = this.select_index + 1;

      if (this.select_index == -1) {
        this.select_index = row_index;
        this.select_rowData = this.gData[row_index];

        trs[row_index + 1].style.backgroundColor = "yellow";
      } else if (this.select_index > -1) {
        if (this.select_index === row_index) {
          this.select_index = -1;
          let default_row_data = {
            sid : "",
            s_name : "",
            cid : "",
            reader_id : "",
            bid : "",
            b_name : "",
            type : "",
            member : "",
            laf : "",
            laf_dtl : "",
            pay : "",
            cable : "",
            stat_dt : "",
            expired_dt : "",
            note : "",
            year : "",
            month : "",
            maker : "",
            maker_name : "",
            owner : "",
            sign : "",
            setup : "",
            subsidy : "",
            subsidy_amount : "",
            motie : "",
            limit_yn : "",
            limit_dtl : "",
            del_yn : "",
            del_dtl : "",
            reg_date : "",
            reg_user : "",
            update_date : "",
            update_user : "",
            c_channel : "",
            p_terminal : "",
            i_funnel : "",
            use_yn : "",
            failure_yn : "",
            r_type : "",
            c_capacity : "",
            audio_ver : "",
            c_standard : "",
            firmware_ver : "",
            pn_id : "",
            r_method : "",
            as_id : "",
            as_name : "",
            tr_yn : "",
            ocpp_yn : "",
            kakao_yn : "",
            tmap_yn : "",
            descript : "",
            zcode : "",
            zsgcode : "",
            laf_name : "",
            status : "",
            status_name : "",
            bis_id : "",
            mid : "",
            tem_id : "",
            page_num : "",
            id : "",
            model_id : "",
            ch_id : "",
          }
          this.select_rowData = default_row_data;

          trs[selectIndex].style.backgroundColor = "white";
        } else {
          this.select_index = row_index;
          this.select_rowData = this.gData[row_index];

          trs[selectIndex].style.backgroundColor = "white";
          trs[row_index + 1].style.backgroundColor = "yellow";
        }
      }
    },
    onResize(e) {
      if (this.crt_width == 0 & this.crt_height == 0) {
        this.crt_width = e.detail.width;
        this.crt_height = e.detail.height;
        
        //this.pageSet();
      } else if (this.crt_width != e.detail.width || this.crt_height != e.detail.height) {
        this.crt_width = e.detail.width;
        this.crt_height = e.detail.height;
        
        this.pageSet();  
      }
    },
    pageSet() {
      var filter_height = 60;
      var button_height = 60;
      var pagination_height = 50;
      var grid_header_height = 60;
      var grid_row_height = 65;
      var grid_data_height = this.crt_height - filter_height - button_height - pagination_height - grid_header_height - 40;
      this.page_grid_height = grid_data_height;

      var page_row_count = Math.floor(grid_data_height / grid_row_height);
      var max_page_num = Math.ceil(this.gridFilterData.length / page_row_count);

      this.perPage = page_row_count;

      this.currentPage = 1;
      this.selectPage(1);
    },
    selectPage(page) {
      let temp_grid_data = this.gridFilterData;

      this.gData = temp_grid_data.slice(
        (page - 1) * this.perPage, page * this.perPage
      )
    },
    reflash() {
      var tbody = document.querySelector("#grid");
      var trs = tbody.getElementsByTagName('tr');
      
      var selectIndex = this.select_index + 1;
      this.select_index = -1;
      this.select_rowData = { sid : "", s_name : "", cid : "", reader_id : "", bid : "", b_name : "", type : "", member : "", laf : "F", laf_dtl : "", pay : "Y", cable : "", stat_dt : "", expired_dt : "", note : "", year : 0, month : 0, maker : "", maker_name : "", owner : "", sign : "", setup : "", subsidy : "N", subsidy_amount : "", motie : "", limit_yn : "", limit_dtl : "", del_yn : "", del_dtl : "", reg_date : "", reg_user : "", update_date : "", update_user : "", c_channel : "", p_terminal : "N", i_funnel : "1", use_yn : "Y", failure_yn : "", r_type : "", c_capacity : "", audio_ver : "", c_standard : "", firmware_ver : "", pn_id : "", r_method : "", as_id : "", as_name : "", tr_yn : "", ocpp_yn : "", kakao_yn : "", tmap_yn : "", descript : "", zcode : "", zsgcode : "", laf_name : "", status : "Available", status_name : "", bis_id : "", mid : "", tem_id : "", page_num : "", id : 0, model_id : "", ch_id : 0 };

      trs[selectIndex].style.backgroundColor = "white";

      this.selectChargerList();
    },
    // 날짜 formatter
    regDateFormatter(data) {
      return data.substr(0,4) + "-" + data.substr(4,2) + "-" + data.substr(6,2);
    },
    // 상태 formatter
    statusFormatter(data, rowIndex) {debugger;
      //let tbody = document.querySelector("#grid");
      //let trs = tbody.getElementsByTagName('tr'); 
      //let row_index = rowIndex + 1;
      //let td = trs[row_index].cells[8];
/*
      if (data == "사용 가능") {
        td[8].style.color = "green";
      } else if (data == "준비중" || data == "보류중") {
        td[8].style.color = "red";
      } else if (data == "충전중" || data == "충전완료중") {
        td[8].style.color = "blue";
      } else if (data == "예약대기" || data == "존재하지 않음" || data == "오류 발생") {
        td[8].style.color = "gray";
      };
*/
      return data;
    },
    // 전화번호 formatter
    phoneFormatter(data) {
      if (data.length > 8 && data.length < 12) {
        if (data.substr(0,3) == "010" && data.substr(0,3) == "011" && data.substr(0,3) == "017" && data.substr(0,3) == "019") {
          return data.substr(0,3) + "-" + data.substr(3,4) + "-" + data.substr(7,4);
        } else {
          if (data.substr(0,2) == "02") {
            if (data.length == 9) {
              return data.substr(0,2) + "-" + data.substr(2,3) + "-" + data.substr(5,4);
            } else if (data.length == 10) {
              return data.substr(0,2) + "-" + data.substr(2,4) + "-" + data.substr(6,4);
            };
          } else {
            if (data.length == 10) {
              return data.substr(0,3) + "-" + data.substr(3,3) + "-" + data.substr(6,4);
            } else if (data.length == 11) {
              return data.substr(0,3) + "-" + data.substr(3,4) + "-" + data.substr(7,4);
            };
          };
        };
      } else {
        return data;
      };
    }
  },
  beforeDestroy() {
    //clearInterval(this.timerActive);
  },
  async mounted() {
    let page_data = this.$refs.element;
    this.crt_width = page_data.clientWidth;
    this.crt_height = page_data.clientHeight;
    
    this.selectChargerList();
  },
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: goldenrod;
}
.td-color-blue {
  color: blue;
}
.td-color-green {
  color: green;
}
.td-color-red {
  color: red;
}
.td-color-gray {
  color: gray;
}
</style>