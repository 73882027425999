<template>
  <div class="" style="height: inherit;" ref="element" v-resize @resize="onResize">
    <b-container>
      <b-row style="margin: 0px 0px 10px 0px;">
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">검색조건</span>
          <div class="d-flex align-items-center" style="gap: 10px; flex: 4; padding: 5px;">
            <b-form-select size="sm" style="flex: 1;" v-model="filterData.searchKeyType" @change="changeSearchKey" :options="searchKeyOption"></b-form-select>
            <b-form-input 
              v-model="filterData.searchKey"
              style="flex: 3; height: 50px; border-radius: 10px;"
              @change="changeSearchKey"
            ></b-form-input>
          </div>
        </b-col>
        <b-col lg=6>
          
        </b-col>
      </b-row>
      <b-row style="margin: 0px 0px 10px 0px;">
        <b-col lg=6 class="d-flex justify-content-between flex-row align-items-center">
          <span>전체 {{ gridFilterData.length }} 건</span>
        </b-col>
        <b-col lg=6 class="d-flex justify-content-end">
          <b-button class="btn_def" @click="detail('add')">등록</b-button>
          <b-button class="btn_def" @click="detail('edit')">수정</b-button>
          <b-button class="btn_def" @click="delStation">삭제</b-button>
          <b-button class="btn_def" @click="DownloadExcel">엑셀다운</b-button>
          <b-button class="btn_def" @click="UploadExcel">엑셀업로드</b-button>
          <!--<a href="http://localhost:6060/station_sampleDownload?filename='station_sample.xlsx'">샘플다운</a>-->
          <b-button class="btn_def" href="http://localhost:6060/station_sampleDownload?filename='station_sample.xlsx'" download="station_sample.xlsx" @click="SampleDown">샘플다운</b-button>
        </b-col>
      </b-row>
      <b-row style="width: inherit; margin: 0px;" :style="{'height': page_grid_height + 'px'}">
        <div class="table-wrapper2" style="width: inherit;">
          <b-table 
            id = "grid"
            hover
            bordered
            noCollapse
            small
            :items="gData"
            :fields="gridFields"
          >
            <template #cell(No)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ row.index + 1 + ((currentPage - 1) * perPage) }}
              </div>
            </template>
            <template #cell(station_name)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].name }}
              </div>
            </template>
            <template #cell(station_id)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].sid }}
              </div>
            </template>
            <template #cell(use_time)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].use_time }}
              </div>
            </template>
            <template #cell(station_kind)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].kind_name }}
              </div>
            </template>
            <template #cell(fee_name)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].fee_title }}
              </div>
            </template>
            <template #cell(addr_name)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].addr + gData[row.index].addr_dtl }}
              </div>
            </template>
            <template #cell(reg_date)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ regDateFormatter(gData[row.index].reg_date) }}
              </div>
            </template>
            <template #cell(station_status)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].del_name }}
              </div>
            </template>
            <template #cell(detail_call)="row">
              <b-button size="sm" @click="detailCall('detail', row.index)" class="btn_def">상세정보</b-button>
            </template>
            <template #cell(map_call)="row">
              <b-button size="sm" @click="mapCall(row.index)" class="btn_def">지도</b-button>
            </template>
          </b-table>
        </div>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="gridFilterData.length"
          :per-page="perPage"
          first-text="⏮"
          prev-text="⏪"
          next-text="⏩"
          last-text="⏭"
          class="mt-4"
          size="sm"
        ></b-pagination>
      </b-row>

      <!-- modals -->
      <modal-excel-upload :file_type="file_type"/>
      <modal-station-detail ref="modal_stationDtl" :obj="obj" @call="showMap" @reflash="reflash"/>
      <modal-station-del ref="modal_stationDel" :obj="obj" @reflash="reflash"/>
      <modal-show-map :obj="obj_addr" />
    </b-container>
  </div>
</template>

<script>
import Form from "@/components/Form/index";
import regex from "@/lib/regex";

import Modals from "@/components/Modals/index";

import { ref } from 'vue';

import * as XLSX from 'xlsx' // 라이브러리 import

import $ from 'jquery';

export default {
  components: {
    // Modals
    ModalExcelUpload: Modals.ExcelUpload,
    ModalStationDetail: Modals.StationDetail,
    ModalStationDel: Modals.StationDel,
    ModalShowMap: Modals.ShowMap
  },
  data() {
    const isDev = process.env.NODE_ENV !== "production";

    return {
      formValid: false,
      salt: 0,
      crt_width: 0,
      crt_height: 0,
      page_grid_height: 0,
      select_index: -1,
      select_rowData: {
        sid : "",
        name : "",
        addr : "",
        addr_dtl : "",
        kind : "",
        kind_dtl : "",
        use_time : "24",
        bname : "",
        bid : "",
        bis_id : "",
        reserv_yn : "Y",
        limit_yn : "Y",
        free_yn : "Y",
        free_dtl : "",
        fee_id : "",
        note : "",
        r_sid : "",
        status_send_yn : "N"
      },
      perPage: 1,
      currentPage: 1,
      gridDefaultData: [

      ],
      gridFilterData: [

      ],
      gData: [

      ],
      gridFields: [
        {
          key: "No",
          label: "No"
        },
        {
          key: "station_name",
          label: "충전소명"
        },
        {
          key: "station_id",
          label: "충전소 ID"
        },
        {
          key: "use_time",
          label: "이용시간"
        },
        {
          key: "station_kind",
          label: "충전소 구분"
        },
        {
          key: "fee_name",
          label: "요금제명"
        },
        {
          key: "addr_name",
          label: "주소"
        },
        {
          key: "reg_date",
          label: "등록일",
        },
        {
          key: "station_status",
          label: "상태"
        },
        {
          key: "detail_call",
          label: "상세보기"
        },
        {
          key: "map_call",
          label: "위치보기"
        }
      ],
      filterData: {
        searchKeyType: "all",
        searchKey: ""
      },
      searchKeyOption: [
        { text: "전체", value: "all" },
        { text: "충전소ID", value: "sid" },
        { text: "충전소이름", value: "sname" }
      ],
      file_type: "station_list",
      rules: {
        email: [
          (v) =>
            !!v || { title: "이메일 입력", message: "이메일을 입력하세요." },
          (v) =>
            regex.serviceId.test(v) || {
              title: "이메일 형식",
              message: "이메일 형식이 올바르지 않습니다.",
            },
        ],
        mobile: [
          (v) =>
            !!v || {
              title: "핸드폰 번호 입력",
              message: "휴대폰 번호를 입력하세요.",
            },
          (v) =>
            v.length >= 10 || {
              title: "핸드폰 번호 입력",
              message: "휴대폰 번호 형식이 올바르지 않습니다.",
            },
        ],
        code: [
          (v) =>
            !!v || {
              title: "핸드폰 번호 인증",
              message: "인증번호를 입력하세요.",
            },
          (v) =>
            v.length === 4 || {
              title: "핸드폰 번호 인증",
              message: "인증번호를 입력하세요.",
            },
          (v) =>
            (v.length === 4 && this.serverValid.code) || {
              title: "핸드폰 번호 인증",
              message: "핸드폰 번호를 인증해주세요.",
            },
        ],
        password: [
          (v) =>
            !!v || {
              title: "비밀번호 입력",
              message: "비밀번호를 입력하세요.",
            },
          (v) =>
            regex.password.test(v) || {
              title: "비밀번호 형식",
              message: "8-16자 영문 대/소문자, 숫자, 특수문자를 사용하세요.",
            },
        ],
        passwordConfirm: [
          (v) =>
            !!v || {
              title: "비밀번호 확인",
              message: "비밀번호 확인을 입력하세요.",
            },
          (v) =>
            this.resetPassword.newPassword === v || {
              title: "비밀번호 확인",
              message: "비밀번호 확인이 일치하지 않습니다",
            },
        ],
      },
      excelUp_show: false,
      stationDetail_show: false,
      obj: {
        edit_type: "add",
        title: "충전소 등록",
        ok_title: "등록",
        station_data: {
          sid : "",
          name : "",
          addr : "",
          addr_dtl : "",
          kind : "",
          kind_dtl : "",
          use_time : "24",
          bname : "",
          bid : "",
          bis_id : "",
          reserv_yn : "Y",
          limit_yn : "Y",
          free_yn : "Y",
          free_dtl : "",
          fee_id : "",
          note : "",
          r_sid : "",
          status_send_yn : "N"
        },
      },
      obj_addr: {
        address: "",
        addressDetail: "",
      }
    };
  },
  computed: {
    
  },
  watch: {
    currentPage: function(newVal, oldVal) {
      let items = this.gridFilterData;

      this.selectPage(newVal);
    },
  },
  methods: {
    async selectStationList(sid_str, name_str, status_str) {
      try {
        const { data } = await this.$axios.post(
          "/api/selectStationList", {
            sid: sid_str,
            name: name_str,
            status: status_str
          }
        );
        if (data.length > 0) {
          this.gData = data;
          this.gridDefaultData = data;
          this.changeSearchKey();
        } else if (data.length == 0) {
          this.gData = null;
          window.alert("데이터가 없습니다.");
        }

        this.pageSet();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    mapCall(row_index) {
      this.obj_addr.address = this.gData[row_index].addr;
      this.obj_addr.addressDetail = this.gData[row_index].addr_dtl;
      this.$bvModal.show("modal-show-map");
    },
    showMap(address, addressDetail) {
      if (address === "") {
        window.alert("충전소 주소를 검색하신 후 다시 시도해 주시기 바랍니다.");
      } else {
        //this.isShowMap = true;
        this.obj_addr.address = address;
        this.obj_addr.addressDetail = addressDetail;
        this.$bvModal.show("modal-show-map");
      }
    },
    detail(type) {debugger;
      if (type === "add") {
        this.obj.edit_type = type;
        this.obj.title = "충전소 등록";
        this.obj.ok_title = "등록";
        this.obj.station_data = { sid : "", name : "", addr : "", addr_dtl : "", kind : "", kind_dtl : "", use_time : "24", bname : "", bid : "", bis_id : "", reserv_yn : "Y", limit_yn : "Y", free_yn : "Y", free_dtl : "", fee_id : "", note : "", r_sid : "", status_send_yn : "N" };
        this.$refs.modal_stationDtl.setData(this.obj.station_data);
        this.$bvModal.show("modal-station-detail");
      } else {
        if (this.select_index > -1) { 
          if (type === "edit") {
            this.obj.edit_type = type;
            this.obj.title = "충전소 수정";
            this.obj.ok_title = "수정";
            this.obj.station_data = this.select_rowData;
          }

          this.$refs.modal_stationDtl.setData(this.select_rowData);
          this.$bvModal.show("modal-station-detail");
        } else if (this.select_index == -1) {
          window.alert("충전소를 선택하신 후 다시 시도해 주시기 바랍니다.");
        }
      }
    },
    detailCall(type, row_index) {
      this.obj.edit_type = type;
      this.obj.title = "충전소 정보";
      this.obj.ok_title = "닫기";
      this.obj.station_data = this.gData[row_index];
      this.$refs.modal_stationDtl.setData(this.obj.station_data);
      this.$bvModal.show("modal-station-detail");
    },
    delStation() {
      if (this.select_index > -1) {
        this.obj.station_data = this.select_rowData;
        this.$refs.modal_stationDel.setData(this.obj.station_data);
        this.$bvModal.show("modal-station-del");
      } else if (this.select_index == -1) {
        window.alert("충전소를 선택하신 후 다시 시도해 주시기 바랍니다.");
      }
    },
    clipboardCopy(index) {
      var copy_data = JSON.stringify(this.gData[index]);
      window.navigator.clipboard.writeText(copy_data).then( function (res) {alert("복사되었습니다.");} );
    },
    DownloadExcel() {
      var crt_date = this.getToday();
      var excel_name = "station_list_" + crt_date;
      var workBook = XLSX.utils.book_new(); // 새로운 workbook 생성 
      var grid_list = XLSX.utils.json_to_sheet(this.gridFilterData);
      // var stdData = XLSX.utils.json_to_sheet(this.checkStdList); // json 객체를 sheet로 변환하여 워크시트 생성
      
      XLSX.utils.book_append_sheet(workBook, grid_list, 'station_list'); // stdData 워크시트로 시트 생성, 해당 시트 이름 명명
      // XLSX.utils.book_append_sheet(workBook, uncheckData, '학생현황'); // 시트 추가
      XLSX.writeFile(workBook, excel_name + '.xlsx'); // 파일 생성;
    },
    UploadExcel() {
      this.file_type = "station_list";
      this.$bvModal.show("modal-excel-upload");
    },
    async SampleDown() {
      try {
        const response = await this.$axios.post(
          "/api/SampleDownload", {
            filename: "station_sample",
            responseType: "blob"
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "station_sample.xlsx");
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
    changeSearchKey() {
      let search_key = this.filterData.searchKey;
      let search_type = this.filterData.searchKeyType;
      let temp_data = [];

      //this.gridDefaultData.

      if (search_key != "") {
        if (search_type === "all") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.sid.indexOf(search_key) != -1 || e.name.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "sid") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.sid.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "sname") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.name.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        }

        this.gridFilterData = temp_data;
      } else {
        this.gridFilterData = this.gridDefaultData;
      }

      this.pageSet();
    },
    selectRow(row_index) {
      var tbody = document.querySelector("#grid");
      var trs = tbody.getElementsByTagName('tr');
      
      var selectIndex = this.select_index + 1;

      if (this.select_index == -1) {
        this.select_index = row_index;
        this.select_rowData = this.gData[row_index];

        trs[row_index + 1].style.backgroundColor = "yellow";
      } else if (this.select_index > -1) {
        if (this.select_index === row_index) {
          this.select_index = -1;
          let default_row_data = {
            sid : "",
            name : "",
            addr : "",
            addr_dtl : "",
            kind : "",
            kind_dtl : "",
            use_time : "24",
            bname : "",
            bid : "",
            bis_id : "",
            reserv_yn : "Y",
            limit_yn : "Y",
            free_yn : "Y",
            fee_id : "",
            note : "",
            r_sid : "",
            status_send_yn : "N"
          }
          this.select_rowData = default_row_data;

          trs[selectIndex].style.backgroundColor = "white";
        } else {
          this.select_index = row_index;
          this.select_rowData = this.gData[row_index];

          trs[selectIndex].style.backgroundColor = "white";
          trs[row_index + 1].style.backgroundColor = "yellow";
        }
      }
    },
    onResize(e) {
      if (this.crt_width == 0 & this.crt_height == 0) {
        this.crt_width = e.detail.width;
        this.crt_height = e.detail.height;
        
        //this.pageSet();
      } else if (this.crt_width != e.detail.width || this.crt_height != e.detail.height) {
        this.crt_width = e.detail.width;
        this.crt_height = e.detail.height;
        
        this.pageSet();  
      }
    },
    pageSet() {
      var filter_height = 60;
      var button_height = 60;
      var pagination_height = 50;
      var grid_header_height = 60;
      var grid_row_height = 65;
      var grid_data_height = this.crt_height - filter_height - button_height - pagination_height - grid_header_height - 40;
      this.page_grid_height = grid_data_height;

      var page_row_count = Math.floor(grid_data_height / grid_row_height);
      var max_page_num = Math.ceil(this.gridFilterData.length / page_row_count);

      this.perPage = page_row_count;

      this.currentPage = 1;
      this.selectPage(1);
    },
    selectPage(page) {
      let temp_grid_data = this.gridFilterData;

      this.gData = temp_grid_data.slice(
        (page - 1) * this.perPage, page * this.perPage
      )
    },
    reflash() {
      var tbody = document.querySelector("#grid");
      var trs = tbody.getElementsByTagName('tr');
      
      var selectIndex = this.select_index + 1;
      this.select_index = -1;
      this.select_rowData = { sid : "", name : "", addr : "", addr_dtl : "", kind : "", kind_dtl : "", use_time : "24", bname : "", bid : "", bis_id : "", reserv_yn : "Y", limit_yn : "Y", free_yn : "Y", free_dtl : "", fee_id : "", note : "", r_sid : "", status_send_yn : "N" };

      trs[selectIndex].style.backgroundColor = "white";

      this.selectStationList(null, null, null);
    },
    // 날짜 formatter
    regDateFormatter(value) {
      return value.substr(0,4) + "-" + value.substr(4,2) + "-" + value.substr(6,2);
    },
    // 상태 formatter
    statusFormatter(value, key, item) {
      if (value == "영업중") {
        gv_contract.setCellColor(rowIndex, 11, "green");
      } else if (value == "영업종료") {
        gv_contract.setCellColor(rowIndex, 11, "red");
      };

      return formattedData;
    }
  },
  beforeDestroy() {
    //clearInterval(this.timerActive);
  },
  async mounted() {
    let page_data = this.$refs.element;
    this.crt_width = page_data.clientWidth;
    this.crt_height = page_data.clientHeight;

    this.selectStationList(null, null, null);
  },
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: goldenrod;
}
</style>