<template>
  <b-modal
    id="modal-fw-update"
    title="펌웨어 업데이트"
    ok-title="업데이트"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="false"
    size="xl"
    ref="element"
    @shown="init"
  >
    <div class="pr-md-2 overflow-auto">
      <div>
        <b-row style="margin: 0px 0px 10px 0px;">
          <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
            <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">검색조건</span>
            <div class="d-flex align-items-center" style="gap: 10px; flex: 4; padding: 5px;">
              <b-form-select size="sm" style="flex: 1;" v-model="filterData.searchKeyType" @change="changeSearchKey" :options="searchKeyOption"></b-form-select>
              <b-form-input 
                size="sm"
                v-model="filterData.searchKey"
                style="flex: 3; height: 50px; border-radius: 10px;"
                @change="changeSearchKey"
              ></b-form-input>
            </div>
          </b-col>
          <b-col lg=6>
            
          </b-col>
        </b-row>
        <b-row style="margin: 0px 0px 10px 0px;">
          <b-col lg=6 class="d-flex justify-content-between flex-row align-items-center">
            <span>전체 {{ gridFilterData.length }} 건</span>
          </b-col>
          <b-col lg=6 class="d-flex justify-content-end">
            
          </b-col>
        </b-row>
        <b-row style="width: inherit; margin: 0px;">
          <div class="table-wrapper2" style="width: -webkit-fill-available;" >
            <b-table 
              id = "model_grid"
              bordered hover noCollapse 
              :items="gData"
              :fields="gridFields"
              small
            >
              <template #cell(No)="row">
                <div class="d-flex" style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                  {{ row.index + 1 }}
                </div>
              </template>
              <template #cell(sname)="row">
                <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                  {{ gData[row.index].s_name }}
                </div>
              </template>
              <template #cell(sid)="row">
                <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                  {{ gData[row.index].sid }}
                </div>
              </template>
              <template #cell(tel_num)="row">
                <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                  {{ gData[row.index].reader_id }}
                </div>
              </template>
              <template #cell(model_id)="row">
                <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                  {{ gData[row.index].model_id }}
                </div>
              </template>
              <template #cell(fw_ver)="row">
                <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                  {{ gData[row.index].firmware_ver }}
                </div>
              </template>
              <template #cell(conn_yn)="row">
                <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                  {{ gData[row.index].uuid }}
                </div>
              </template>
            </b-table>
          </div>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  components: {
    
  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      maker_list: Array,
      param_data: Object,
    },
  },
  data() {
    return {
      admin_data: {},
      main_url: "https://evcloud.duruscoev.com/ocpp-jt",
      file_url: "https://evcloud.duruscoev.com/fw/",
      gridDefaultData: [],
      gridFilterData: [],
      gData: [],
      gridFields: [
        {
          key: "No",
          label: "No"
        },
        {
          key: "sname",
          label: "충전소명"
        },
        {
          key: "sid",
          label: "충전소 ID"
        },
        {
          key: "tel_num",
          label: "단말기 번호"
        },
        {
          key: "model_id",
          label: "모델 ID"
        },
        {
          key: "fw_ver",
          label: "펌웨어 버전"
        },
        {
          key: "conn_yn",
          label: "충전기 연결여부"
        },
      ],
      filterData: {
        searchKeyType: "all",
        searchKey: ""
      },
      searchKeyOption: [
        { text: "전체", value: "all" },
        { text: "충전소 ID", value: "sid" },
        { text: "충전소 이름", value: "sname" },
        { text: "단말기 번호", value: "tel_num" },
        { text: "모델 ID", value: "model_id" }
      ],
      select_index: [],
      select_rowData: [],
      wrap: null,
      rules: {
        address: [
          (v) =>
            !!v || {
              title: "주소 입력",
              message: "주소를 입력하세요.",
            },
        ],
        // addressDetail: [
        //   (v) =>
        //     !!v || {
        //       title: "상세 주소 입력",
        //       message: "상세 주소를 입력하세요.",
        //     },
        // ],
      },
    };
  },
  mounted() {
    console.log("충전기 펌웨어 업데이트 팝업 페이지");
    if (this.$store.getters.user === null) {
      this.admin_data.user_id = "admin";
    } else {
      this.admin_data = this.$store.getters.user;
    }

    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        let company_id = this.obj.param_data.company_id;
        this.connChargerList(company_id);
      });
    },
    async connChargerList(company_id) {
      try {
        const { data } = await this.$axios.post(
          "/api/selectConnChargerList", {
            maker : company_id
          }
        );
        if (data.length > 0) {
          this.gData = data;
          this.gridDefaultData = data;
          this.changeSearchKey();
        } else if (data.length == 0) {
          this.gData = null;
          console.log("데이터가 없습니다.");
        }

        //this.pageSet();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    changeSearchKey() {
      let search_key = this.filterData.searchKey;
      let search_type = this.filterData.searchKeyType;
      let temp_data = [];

      //this.gridDefaultData.

      if (search_key != "") {
        if (search_type === "all") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.sid.indexOf(search_key) != -1 || e.sname.indexOf(search_key) != -1 || e.tel_num.indexOf(search_key) != -1 || e.model_id.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "sid") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.sid.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "sname") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.sname.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "tel_num") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.tel_num.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "model_id") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.model_id.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        }

        this.gData = temp_data;
      } else {
        this.gData = this.gridDefaultData;
      }

      //this.pageSet();
    },
    selectRow(row_index) {
      var tbody = document.querySelector("#model_grid");
      var trs = tbody.getElementsByTagName('tr');

      let index_arr = this.select_index;
      let index_count = index_arr.length;
      let index_chk = true;

      if(index_arr.indexOf(row_index) < 0) {
        index_chk = false;
      }

      if (index_chk === true) {
        index_arr.splice(index_arr.indexOf(row_index), 1);
      } else if (index_chk === false) {
        index_arr.push(row_index);
      }

      index_arr.sort();
      this.select_index = index_arr;

      let select_data = [];
      for (let i = 0; i < this.gData.length; i++) {
        if (index_arr.indexOf(i) < 0) {
          trs[i + 1].style.backgroundColor = "white";
        } else {
          select_data.push(this.gData[i]);
          trs[i + 1].style.backgroundColor = "yellow";
        }
      }

      this.select_rowData = select_data;
    },
    ok() {
      var crt_date = this.getToday();

      let select_charger_count = this.select_rowData.length;

      let full_url = main_url + "/UpdateFirmwareList";

      var req_json = {};
      var items = [];
      /*
      var location_json = [];
      var ftp_data = {};
      ftp_data.FTP_URL = "FTP://ev-pay.co.kr:22";
      ftp_data.FTP_UID = obj[0].ftpUser;
      ftp_data.FTP_UPW = obj[0].ftpPass;
      ftp_data.FTP_DN_FILE_NAME = obj[0].url;							//
      location_json.push(ftp_data);
      */

      for (a=0; a < select_charger_count; a++) {

        let file_full_url = this.file_url + obj.param_data.ftp_user;
        let param_json = {};
        param_json.sessionIndex = this.select_rowData[a].uuid;
        param_json.location = "FTP_HOST: " + file_full_url + ", FTP_DN_FILE_NAME: " + obj.param_data.url;
        param_json.retrieveDate = crt_date;
        param_json.retries = 1;
        param_json.retryInterval = 1;

        items.push(param_json);
      };

      req_json.items = items;

      this.api_call(full_url, req_json, "UpdateFirmwareList");
    },
    api_call(url, param_json, type) {
      let success_count = 0;
      let fail_count = 0;
      let total_count = 0;

      var request = new XMLHttpRequest();
	  	request.open('POST', url);
	  	request.setRequestHeader('Content-Type', 'application/json');
	  	request.send(JSON.stringify(param_json));

	  	request.onload = function() {
	  		if (request.status === 200 || request.status === 201) {
		  		let result_text = {};
		  		result_text = request.response;
	  			let result_str = JSON.stringify(result_text, null, 2);
	  			let result = JSON.parse(result_text);

	  			if (result.status == "ok") {
		  			total_count++;
	  				success_count++;
	  			} else if (result.status == "Rejected") {
		  			total_count++;
            fail_count++;
          }

          console.log('UpdateFirmware', result_str);

          //document.querySelector('pre').textContent = request.response;
        } else {
          fail_count++;
          console.log('error', request.status, request.statusText);
        }

        if (conn_count == total_count) {
          window.alert("펌웨어 업데이트가 완료되었습니다.");
          var context = "모델ID : " + obj.param_data.model_id + ", 펌웨어버전 : " + obj.param_data.fw_ver + ", 성공 : " + success_count + ", 실패 : " + fail_count;
          this.addAdminLog("펌웨어 관리", "펌웨어 업데이트", "펌웨어 업데이트 팝업", context, this.admin_data.user_id);		// 관리자 명령 로그 추가
          this.$bvModal.hide("modal-fw-update");				// 팝업 페이지 닫기
        }
	  	};
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("관리자 명령 로그 등록을 실패했습니다.");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
