import Vue from "vue";
import store from "../store/index";
import VueRouter from "vue-router";
import DefaultLayout from "@/views/Layout/Default.vue";
import AccountLayout from "@/views/Layout/Account.vue";
import MypageLayout from "@/views/Layout/Mypage.vue";

import TestPage from "@/views/TestPage.vue";
import LoginPage from "@/views/Login.vue";
import ConditionChart from "@/views/ConditionChart.vue";
import Account from "@/views/Account/index";
import Matching from "@/views/Matching/index";
import Mypage from "@/views/Mypage/index";
import Guidance from "@/views/Guidance/index";
import CustomerCenter from "@/views/CustomerCenter/index";

import DashboardPage from "@/views/Dashboard/index";
import Charger from "@/views/Charger/index";
import User from "@/views/User/index";
import Roming from "@/views/Roming/index";
import Payment from "@/views/Payment/index";
import Issue from "@/views/Issue/index";
import Customer from "@/views/Customer/index";
import Operation from "@/views/Operation/index";
import Admin from "@/views/Admin/index";
import Log from "@/views/Log/index";
import Statistics from "@/views/Statistics/index";

import ErrorPage from "@/views/ErrorPage.vue";

Vue.use(VueRouter);

const RouterView = {
  render: (h) => h("router-view"),
};

const routes = [
  {
    path: "/",
    component: AccountLayout,
    children: [
      {
        path: "/",
        name: "Login",
        component: LoginPage,
        meta: {
          requireNoAuth: false,
          header: {
            title: "로그인",
          },
        },
      },
    ],
  },
  {
    path: "/dashboard",
    component: DefaultLayout,
    redirect: "/dashboard/dashboard",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: DashboardPage.Dashboard,
        meta: {
          requireNoAuth: false,
        },
      },
    ],
  },
  {
    path: "/charger",
    component: DefaultLayout,
    redirect: "/charger/station_list",
    children: [
      {
        path: "station_list",
        name: "StationList",
        component: Charger.StationList,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "charger_list",
        name: "ChargerList",
        component: Charger.ChargerList,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "charger_status",
        name: "ChargerStatus",
        component: Charger.ChargerStatus,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "charge_use_status",
        name: "ChargeUseStatus",
        component: Charger.ChargeUseStatus,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "reader_list",
        name: "ReaderList",
        component: Charger.ReaderList,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "charger_model_list",
        name: "ChargerModelList",
        component: Charger.ChargerModelList,
        meta: {
          requireNoAuth: false,
        },
      },
    ],
  },
  {
    path: "/user",
    component: DefaultLayout,
    redirect: "/user/user_list",
    children: [
      {
        path: "user_list",
        name: "UserList",
        component: User.UserList,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "company_list",
        name: "CompanyList",
        component: User.CompanyList,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "user_card_list",
        name: "UserCardList",
        component: User.UserCardList,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "user_card_issuance",
        name: "UserCardIssuance",
        component: User.UserCardIssuance,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "user_card_nomatch",
        name: "UserCardNoMatch",
        component: User.UserCardNoMatch,
        meta: {
          requireNoAuth: false,
        },
      },
    ],
  },
  {
    path: "/roming",
    component: DefaultLayout,
    redirect: "/roming/roming_charger_list",
    children: [
      {
        path: "roming_charger_list",
        name: "RomingChargerList",
        component: Roming.RomingChargerList,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "roming_card_list",
        name: "RomingCardList",
        component: Roming.RomingCardList,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "roming_charging_history",
        name: "RomingChargingHistory",
        component: Roming.RomingChargingHistory,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "roming_payment_history",
        name: "RomingPaymentHistory",
        component: Roming.RomingPaymentHistory,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "test",
        name: "Test",
        component: Roming.Test,
        meta: {
          requireNoAuth: false,
        },
      },
    ],
  },
  {
    path: "/payment",
    component: DefaultLayout,
    redirect: "/payment/charging_history",
    children: [
      {
        path: "charging_history",
        name: "ChargingHistory",
        component: Payment.ChargingHistory,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "payment_history",
        name: "PaymentHistory",
        component: Payment.PaymentHistory,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "calculate",
        name: "Calculate",
        component: Payment.Calculate,
        meta: {
          requireNoAuth: false,
        },
      },
    ],
  },
  {
    path: "/issue",
    component: DefaultLayout,
    redirect: "/issue/damage_report",
    children: [
      {
        path: "damage_report",
        name: "DamageReport",
        component: Issue.DamageReport,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "as_schedule",
        name: "ASSchedule",
        component: Issue.ASSchedule,
        meta: {
          requireNoAuth: false,
        },
      },
    ],
  },
  {
    path: "/customer",
    component: DefaultLayout,
    redirect: "/customer/consulting_list",
    children: [
      {
        path: "consulting_list",
        name: "ConsultingList",
        component: Customer.ConsultingList,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "notice",
        name: "Notice",
        component: Customer.Notice,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "qna",
        name: "QnA",
        component: Customer.QnA,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "faq",
        name: "FAQ",
        component: Customer.FAQ,
        meta: {
          requireNoAuth: false,
        },
      },
    ],
  },
  {
    path: "/operation",
    component: DefaultLayout,
    redirect: "/operation/term",
    children: [
      {
        path: "term",
        name: "Term",
        component: Operation.Term,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "message",
        name: "Message",
        component: Operation.Message,
        meta: {
          requireNoAuth: false,
        },
      },
    ],
  },
  {
    path: "/admin",
    component: DefaultLayout,
    redirect: "/admin/menu_set",
    children: [
      {
        path: "menu_set",
        name: "MenuSet",
        component: Admin.MenuSet,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "admin_list",
        name: "AdminList",
        component: Admin.AdminList,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "code_set",
        name: "CodeSet",
        component: Admin.CodeSet,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "charge_set",
        name: "ChargeSet",
        component: Admin.ChargeSet,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "fw_ver_set",
        name: "FwVerSet",
        component: Admin.FwVerSet,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "car_info",
        name: "CarInfo",
        component: Admin.CarInfo,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "message_form",
        name: "MessageForm",
        component: Admin.MessageForm,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "enc_set",
        name: "EncSet",
        component: Admin.EncSet,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "admin_company_list",
        name: "AdminCompanyList",
        component: Admin.AdminCompanyList,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "fw_update_history",
        name: "FwUpdateHistory",
        component: Admin.FwUpdateHistory,
        meta: {
          requireNoAuth: false,
        },
      },
    ],
  },
  {
    path: "/log",
    component: DefaultLayout,
    redirect: "/log/user_conn",
    children: [
      {
        path: "user_conn",
        name: "UserConn",
        component: Log.UserConn,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "admin_conn",
        name: "AdminConn",
        component: Log.AdminConn,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "admin_use_list",
        name: "AdminUseList",
        component: Log.AdminUseList,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "ocpp_auth",
        name: "OcppAuth",
        component: Log.OcppAuth,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "ocpp_boot",
        name: "OcppBoot",
        component: Log.OcppBoot,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "ocpp_meta_value",
        name: "OcppMetaValue",
        component: Log.OcppMetaValue,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "ocpp_conn",
        name: "OcppConn",
        component: Log.OcppConn,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "ocpp_tran",
        name: "OcppTran",
        component: Log.OcppTran,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "ocpp_heartbeat",
        name: "OcppHeartbeat",
        component: Log.OcppHeartbeat,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "ocpp_status",
        name: "OcppStatus",
        component: Log.OcppStatus,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "ocpp_data_tran",
        name: "OcppDataTran",
        component: Log.OcppDataTran,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "ocpp_charger",
        name: "OcppCharger",
        component: Log.OcppCharger,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "ocpp_start_tran",
        name: "OcppStartTran",
        component: Log.OcppStartTran,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "ocpp_stop_tran",
        name: "OcppStopTran",
        component: Log.OcppStopTran,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "ocpp_rstart_tran",
        name: "OcppRstartTran",
        component: Log.OcppRstartTran,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "ocpp_rstop_tran",
        name: "OcppRstopTran",
        component: Log.OcppRstopTran,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "ocpp_payment_noti",
        name: "OcppPaymentNoti",
        component: Log.OcppPaymentNoti,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "fw_status",
        name: "FwStatus",
        component: Log.FwStatus,
        meta: {
          requireNoAuth: false,
        },
      },
    ],
  },
  {
    path: "/statistics",
    component: DefaultLayout,
    redirect: "/statistics/operating_statistics",
    children: [
      {
        path: "operating_statistics",
        name: "OperatingStatistics",
        component: Statistics.OperatingStatistics,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "cp_statistics",
        name: "CpStatistics",
        component: Statistics.CpStatistics,
        meta: {
          requireNoAuth: false,
        },
      },
      {
        path: "station_statistics",
        name: "StationStatistics",
        component: Statistics.StationStatistics,
        meta: {
          requireNoAuth: false,
        },
      },
    ],
  },
  {
    path: "/OCA",
    component: DefaultLayout,
    children: [
      {
        path: "",
        name: "OCA",
        component: TestPage,
      }
    ],
  },
  /*
  {
    path: "/matching",
    component: DefaultLayout,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "/",
        name: "MatchingList",
        component: Matching.List,
      },
      {
        path: "upload",
        name: "MatchingUpload",
        component: Matching.Upload,
      },
      {
        path: "upload2",
        name: "MatchingUpload2",
        component: Matching.Upload2,
      },
      {
        path: ":matching_id",
        name: "MatchingDetail",
        component: Matching.Detail,
      },
      {
        path: ":matching_id/edit",
        name: "MatchingEdit",
        component: Matching.Edit,
      },
      {
        path: ":matching_id/bidding",
        name: "MatchingBidding",
        component: Matching.Bidding,
      },
      {
        path: ":matching_id/edit-bidding",
        query: "idBidder",
        name: "EditBidding",
        component: Matching.EditBidding,
      },
    ],
  },
  {
    path: "/mypage",
    component: MypageLayout,
    // redirect: "/mypage/history?type=ALL",
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "history",
        name: "MatchingHistory",
        component: Mypage.MatchingHistory,
        meta: {
          hasLnb: true,
          showLnb: true,
          imageShort: true,
          showHistory: true,
        },
      },
      {
        path: "check-grade",
        name: "CheckGrade",
        component: Mypage.CheckGrade,
        meta: {
          hasLnb: true,
          showHistory: true,
        },
      },
      {
        path: "notification",
        name: "Notification",
        component: Mypage.Notification,
        meta: {
          hasLnb: true,
          showHistory: true,
        },
      },
      {
        path: "edit-info",
        name: "EditInfo",
        component: Mypage.EditInfo,
        meta: {
          hasLnb: true,
          showHistory: true,
          hideFileDesc: true,
        },
      },
      {
        path: "benefit",
        name: "benefit",
        component: Mypage.Benefit,
        meta: {
          hasLnb: true,
          showHistory: true,
        },
      },
    ],
  },
  {
    path: "/mypage/contact",
    component: MypageLayout,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "/",
        name: "Contact",
        component: Mypage.Contact,
        meta: {
          hasLnb: true,
        },
      },
      {
        path: "inquiry/add",
        name: "Inquiry",
        component: Mypage.Inquiry,
        meta: {
          hasLnb: true,
        },
      },
      {
        path: "inquiry",
        query: {
          id: ":id",
        },
        name: "ContactDetail",
        component: Mypage.ContactDetail,
        meta: {
          hasLnb: true,
        },
      },
    ],
  },
  {
    path: "/customer-center",
    component: DefaultLayout,
    children: [
      {
        path: "/",
        name: "CustomerCenter",
        component: CustomerCenter.List,
      },
      {
        path: ":id",
        name: "CustomerCenterDetail",
        component: CustomerCenter.Detail,
      },
    ],
  },
  {
    path: "/guidance", //기타
    component: DefaultLayout,
    redirect: "/guidance/service-guide",
    children: [
      {
        path: "service-guide",
        name: "ServiceGuide",
        component: Guidance.ServiceGuide,
      },
      {
        path: "matching-guide",
        name: "MatchingGuide",
        component: Guidance.MatchingGuide,
      },
      {
        path: "loan-guide",
        name: "LoanGuide",
        component: Guidance.LoanGuide,
      },
      {
        path: "introduce",
        name: "Introduce",
        component: Guidance.Introduce,
      },
      {
        path: "terms",
        name: "Terms",
        component: Guidance.Terms,
      },
      {
        path: "affiliation",
        name: "Affiliation",
        component: Guidance.Affiliation,
      },
      {
        path: "partners",
        name: "Partners",
        component: Guidance.Partners,
      },
    ],
  },
  */
  {
    path: "/:pathMatch(.*)*",
    component: DefaultLayout,
    children: [
      {
        path: "/",
        name: "ErrorPage",
        component: ErrorPage,
        meta: {
          hideUpsidebar: true,
        },
      },
    ],
  },
];

/*
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') throw err;
  });
};
*/

const mapping = (arr, path) => {
  arr.map((item) => {
    if (item.children) {
      return mapping(
        item.children,
        item.path === "/" ? `${path}` : `${path}/${item.path}`
      );
    } else {
      // console.log((`${path}/${item.path}`);
    }
  });
};
mapping(routes);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // from & to 전체 keys 리스트
    if (to.params.fixedScroll) return;
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const requireAuth = to.matched.some((record) => record.meta.requireAuth);
  const requireNoAuth = to.matched.some((record) => record.meta.requireNoAuth);
  
  const token =
    localStorage.getItem("duruscoev__token") ||
    sessionStorage.getItem("duruscoev__token");

  let user = store.getters.user;

  if (!store.getters.loaded) {
    // await store.dispatch("getTypes");
    user = token ? await store.dispatch("getMe", token) : null;
    store.commit("set", ["loaded", true]);
  }
  if (requireAuth) {
    // 사용자만 접근 가능
    if (user) {
      // 사용자가 있는 경우
      next();
    } else {
      // 사용자가 없는 경우
      next("/account/login");
    }
  } else if (requireNoAuth) {
    if (user) {
      next(from);
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
