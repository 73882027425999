<template>
  <div class="" style="height: inherit;" ref="element">
    <b-container>
      <b-row style="margin: 0px 0px 10px 0px;">
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span style="flex: 1;">종류</span>
          <b-form-select size="sm" style="flex: 5;" v-model="searchType" @change="changeType" :options="searchTypeOption"></b-form-select>
        </b-col>
        <b-col lg=6 class="d-flex justify-content-end flex-row align-items-center">
          <b-button class="btn_def" @click="add">등록</b-button>
        </b-col>
      </b-row>
      <b-row style="margin: 0px 0px 10px 0px;" v-if="grid_type === 'single'">
        <div class="table-wrapper2 d-flex justify-content-end flex-row" style="width: -webkit-fill-available;">
          <b-table 
            id = "grid1" 
            :items="single_gData"
            :fields="single_gridFields"
            bordered hover noCollapse small
          >
            <template #cell(No)="row">
              <div style="cursor: pointer;" @click="selectRow(row.index)">
                {{ row.index + 1 }}
              </div>
            </template>
            <template #cell(code)="row">
              <div style="cursor: pointer;" @click="selectRow(row.index)">
                {{ single_gData[row.index].code }}
              </div>
            </template>
            <template #cell(code_name)="row">
              <div style="cursor: pointer;" @click="selectRow(row.index)">
                {{ single_gData[row.index].code_name }}
              </div>
            </template>
            <template #cell(url)="row">
              <div style="cursor: pointer;" @click="selectRow(row.index)">
                {{ single_gData[row.index].url }}
              </div>
            </template>
            <template #cell(descript)="row">
              <div style="cursor: pointer;" @click="selectRow(row.index)">
                {{ single_gData[row.index].descript }}
              </div>
            </template>
            <template #cell(use_yn)="row">
              <div style="cursor: pointer;" @click="selectRow(row.index)">
                {{ single_gData[row.index].use_yn }}
              </div>
            </template>
          </b-table>
        </div>
      </b-row>
      <b-row style="margin: 0px 0px 10px 0px;" v-else-if="grid_type === 'multi'">
        <b-col lg=4 class="d-flex justify-content-end flex-row">
          <div class="table-wrapper2" style="width: -webkit-fill-available;">
            <b-table 
              id = "grid1"
              bordered hover noCollapse small 
              :items="first_gData"
              :fields="first_gridFields"
            >
              <template #cell(No)="row">
                <div style="cursor: pointer;" @click="secondTypeChange(row.index)">
                  {{ row.index + 1 }}
                </div>
              </template>
              <template #cell(code)="row">
                <div style="cursor: pointer;" @click="secondTypeChange(row.index)">
                  {{ first_gData[row.index].code }}
                </div>
              </template>
              <template #cell(code_name)="row">
                <div style="cursor: pointer;" @click="secondTypeChange(row.index)">
                  {{ first_gData[row.index].code_name }}
                </div>
              </template>
              <template #cell(use_yn)="row">
                <div style="cursor: pointer;" @click="secondTypeChange(row.index)">
                  {{ first_gData[row.index].use_yn }}
                </div>
              </template>
            </b-table>
          </div>
        </b-col>
        <b-col lg=8 class="d-flex justify-content-end flex-row">
          <div class="table-wrapper2" style="width: -webkit-fill-available;">
            <b-table 
              id = "grid2"
              striped hover 
              :items="second_gData"
              :fields="second_gridFields"
              small
            >
              <template #cell(No)="row">
                <div style="cursor: pointer;" @click="selectRow(row.index)">
                  {{ row.index + 1 }}
                </div>
              </template>
              <template #cell(code)="row">
                <div style="cursor: pointer;" @click="selectRow(row.index)">
                  {{ second_gData[row.index].code }}
                </div>
              </template>
              <template #cell(code_name)="row">
                <div style="cursor: pointer;" @click="selectRow(row.index)">
                  {{ second_gData[row.index].code_name }}
                </div>
              </template>
              <template #cell(url)="row">
                <div style="cursor: pointer;" @click="selectRow(row.index)">
                  {{ second_gData[row.index].url }}
                </div>
              </template>
              <template #cell(descript)="row">
                <div style="cursor: pointer;" @click="selectRow(row.index)">
                  {{ second_gData[row.index].descript }}
                </div>
              </template>
              <template #cell(use_yn)="row">
                <div style="cursor: pointer;" @click="selectRow(row.index)">
                  {{ second_gData[row.index].use_yn }}
                </div>
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>

      <!-- modals -->
      <modal-code-add ref="modal_codeAdd" :obj="obj" @reflash="reflash"/>
      <modal-code-edit ref="modal_codeEdit" :obj="obj" @reflash="reflash"/>

    </b-container>
  </div>
</template>

<script>
import Form from "@/components/Form/index";
import regex from "@/lib/regex";

import Modals from "@/components/Modals/index";
import { ref } from 'vue';

import * as XLSX from 'xlsx' // 라이브러리 import

import $ from 'jquery';

export default {
  components: {
    
    // Modals
    ModalCodeAdd: Modals.CodeAdd,
    ModalCodeEdit: Modals.CodeEdit,
  },
  data() {
    const isDev = process.env.NODE_ENV !== "production";

    return {
      grid_type: "single",
      formValid: false,
      salt: 0,
      crt_width: 0,
      crt_height: 0,
      page_grid_height: 0,
      select_index: -1,
      select_rowData: {
        id : 0,
        type : "",
        type_name : "",
        code : "",
        code_name : "",
        level : "",
        order_no : "",
        r_code : "",
        url : "",
        reg_date : "",
        reg_user : "",
        update_date : "",
        update_user : "",
        use_yn : "",
        descript : "",
      },
      first_code: [],
      second_code: [],
      single_gData: [],
      single_gridFields: [
      {
          key: "No",
          label: "No"
        },
        {
          key: "code",
          label: "코드"
        },
        {
          key: "code_name",
          label: "코드명"
        },
        {
          key: "url",
          label: "경로 (이미지)"
        },
        {
          key: "descript",
          label: "추가정보"
        },
        {
          key: "use_yn",
          label: "사용여부"
        },
      ],
      first_gData: [],
      first_gridFields: [
        {
          key: "No",
          label: "No"
        },
        {
          key: "code",
          label: "코드"
        },
        {
          key: "code_name",
          label: "코드명"
        },
        {
          key: "use_yn",
          label: "사용여부"
        },
      ],
      second_gData: [],
      second_gridFields: [
        {
          key: "No",
          label: "No"
        },
        {
          key: "code",
          label: "코드"
        },
        {
          key: "code_name",
          label: "코드명"
        },
        {
          key: "url",
          label: "경로 (이미지)"
        },
        {
          key: "descript",
          label: "추가정보"
        },
        {
          key: "use_yn",
          label: "사용여부"
        },
      ],
      searchType: "CC",
      searchTypeOption: [],
      rules: {
        
      },
      obj: {
        type_data: null,
        first_code: null,
        second_code: null,
        code_data: {
          id : 0,
          type : "",
          type_name : "",
          code : "",
          code_name : "",
          level : "",
          order_no : "",
          r_code : "",
          url : "",
          reg_date : "",
          reg_user : "",
          update_date : "",
          update_user : "",
          use_yn : "",
          descript : "",
        },
      },
    };
  },
  computed: {
    
  },
  watch: {
    
  },
  methods: {
    async selectCode() {
      console.log("코드 관리 페이지");

      try {
        const { data } = await this.$axios.post(
          "/api/selectCode", {
            //use_yn : "Y",
            //type : "C"
          }
        );
        if (data.length > 0) {
          let typeList = data.filter(
            (e, i, callback) =>
              i === callback.findIndex(
                (e1) => e1.type === e.type
              )
          );

          let options = [];

          typeList.forEach((e,i) => {
            let add_option = { value: e.type, text: e.type_name }
            options.push(add_option);
          });

          this.searchTypeOption = options;

          let f_data = [];
          let s_data = [];

          data.forEach((e,i) => {
            if (e.level === 1) {
              f_data.push(e);
            } else if (e.level === 2) {
              s_data.push(e);
            }
          });

          this.first_code = f_data;
          this.second_code = s_data;

          let temp_data1 = [];
          let temp_data2 = [];

          this.first_code.forEach((e,i) => {
            if (e.type === "CC") {
              temp_data1.push(e);
            }
          });

          this.second_code.forEach((e,i) => {
            if (e.type === "CC") {
              temp_data2.push(e);
            }
          });

          if (temp_data2.length == 0) {
            this.grid_type = "single";
            this.single_gData = temp_data1;
          } else if (temp_data2.length > 0) {
            this.grid_type = "multi";
            this.first_gData = temp_data1;

            let second_temp_data = [];

            temp_data2.forEach((e,i) => {
              if (e.r_code === this.first_gData[0].code) {
                second_temp_data.push(e);
              }
            });

            this.second_gData = second_temp_data;
          };
        } else if (data.length == 0) {
          this.searchTypeOption = null;
          window.alert("데이터가 없습니다.");
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    changeType() {
      let select_type = this.searchType;

      let temp_data1 = [];
      let temp_data2 = [];

      this.first_code.forEach((e,i) => {
        if (e.type === select_type) {
          temp_data1.push(e);
        }
      });

      this.second_code.forEach((e,i) => {
        if (e.type === select_type) {
          temp_data2.push(e);
        }
      });

      if (temp_data2.length == 0) {
        this.grid_type = "single";
        this.single_gData = temp_data1;
      } else if (temp_data2.length > 0) {
        this.grid_type = "multi";
        this.first_gData = temp_data1;

        let second_temp_data = [];

        temp_data2.forEach((e,i) => {
          if (e.r_code === this.first_gData[0].code) {
            second_temp_data.push(e);
          }
        });

        this.second_gData = second_temp_data;
      };
    },
    secondTypeChange(row_index) {
      let temp_data = [];

      this.second_code.forEach((e,i) => {
        if (e.r_code === this.first_gData[row_index].code) {
          temp_data.push(e);
        }
      });

      this.second_gData = temp_data;
    },
    selectRow(row_index) {
      this.obj.type_data = this.searchTypeOption;
      this.obj.first_code = this.first_code;
      this.obj.second_code = this.second_code;
      this.obj.code_data = this.second_gData[row_index];

      if (this.second_gData[row_index].level === 1) {
        this.obj.code_data.level_str = "상위코드";
      } else if (this.second_gData[row_index].level === 2) {
        this.obj.code_data.level_str = "하위코드";
      }

      this.first_code.forEach((e,i) => {
        if (e.code === this.second_gData[row_index].r_code) {
          this.obj.code_data.r_code_name = e.code_name;
        }
      })

      this.$refs.modal_codeEdit.setData(this.obj.code_data);
      this.$bvModal.show("modal-code-edit");
    },
    add() {
      this.obj.type_data = this.searchTypeOption;
      this.obj.first_code = this.first_code;
      this.obj.second_code = this.second_code;

      this.$bvModal.show("modal-code-add");
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
    reflash() {
      var tbody = document.querySelector("#grid");
      var trs = tbody.getElementsByTagName('tr');
      
      var selectIndex = this.select_index + 1;
      this.select_index = -1;
      this.select_rowData = { id : 0, type : "", type_name : "", code : "", code_name : "", level : "", order_no : "", r_code : "", url : "", reg_date : "", reg_user : "", update_date : "", update_user : "", use_yn : "", descript : "" };

      trs[selectIndex].style.backgroundColor = "white";

      this.selectCode();
    }
  },
  beforeDestroy() {
    //clearInterval(this.timerActive);
  },
  async mounted() {
    this.selectCode();
  },
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: goldenrod;
}
</style>