<template>
  <b-modal
    id="modal-enc-edit"
    :title="obj.title"
    :ok-title="obj.ok_title"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="false"
    size="lg"
    @shown="init"
  >
    <div class="pr-md-2 overflow-auto">
      <div>
        <div v-if="obj.edit_type === 'add'" class="mt-1">
          <b-row class="modal-input-row">
            <div class="modal-input-title">
              <span class="text-17">
                <font-awesome-icon />종류
              </span>
            </div>
            <div class="d-flex" style="flex: 1; padding: 0 5px;">
              <b-form-select style="height: 50px; border-radius: 10px;" v-model="encData.type" :options="typeOptions"></b-form-select>
              <b-button class="btn_def" style="width: 106px;" @click="add">추가</b-button>
            </div>
          </b-row>
          <b-row class="modal-input-row">
            <div class="modal-input-title">
              <span class="text-17">
                <font-awesome-icon />설정명
              </span>
            </div>
            <div class="modal-input-content">
              <b-form-input style="height: 50px; border-radius: 10px;" v-model="encData.name"></b-form-input>
            </div>
          </b-row>
          <b-row class="modal-input-row">
            <div class="modal-input-title">
              <span class="text-17">
                <font-awesome-icon />설정값
              </span>
            </div>
            <div class="modal-input-content">
              <b-form-input style="height: 50px; border-radius: 10px;" v-model="encData.value"></b-form-input>
            </div>
          </b-row>
        </div>
        <div v-else-if="obj.edit_type === 'edit'" class="mt-1">
          <b-row class="modal-input-row">
            <div class="modal-input-title">
              <span class="text-17">
                <font-awesome-icon />종류
              </span>
            </div>
            <div class="modal-input-content">
              <b-form-input readonly style="height: 50px; border-radius: 10px;" v-model="encData.type"></b-form-input>
            </div>
          </b-row>
          <b-row class="modal-input-row">
            <div class="modal-input-title">
              <span class="text-17">
                <font-awesome-icon />설정명
              </span>
            </div>
            <div class="modal-input-content">
              <b-form-input style="height: 50px; border-radius: 10px;" v-model="encData.name"></b-form-input>
            </div>
          </b-row>
          <b-row class="modal-input-row">
            <div class="modal-input-title">
              <span class="text-17">
                <font-awesome-icon />설정값
              </span>
            </div>
            <div class="modal-input-content">
              <b-form-input style="height: 50px; border-radius: 10px;" v-model="encData.value"></b-form-input>
            </div>
          </b-row>
        </div>
      </div>
    </div>

    <!-- modals -->
    <modal-enc-add ref="modal_encAdd" :obj="obj_data" @updateType="updateType"/>
  </b-modal>
</template>

<script>
import ModalEncAdd from "./EncAdd.vue";

export default {
  components: {
    // Modals
    ModalEncAdd,
  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      edit_type: String,
      title: String,
      ok_title: String,
      type_list: Object,
      param_data: Object
    }
  },
  data() {
    return {
      obj_data: {
        param_data: null
      },
      encData: {
        id : 0,
        type : "",
        name : "",
        value : "",
        reg_user : "",
        reg_date : "",
        update_user : "",
        update_date : "",
      },
      typeOptions: [],
      admin_data: {},
      rules: {
        address: [
          (v) =>
            !!v || {
              title: "주소 입력",
              message: "주소를 입력하세요.",
            },
        ],
        // addressDetail: [
        //   (v) =>
        //     !!v || {
        //       title: "상세 주소 입력",
        //       message: "상세 주소를 입력하세요.",
        //     },
        // ],
      },
    };
  },
  mounted() {
    if (this.$store.getters.user === null) {
      this.admin_data.user_id = "admin";
    } else {
      this.admin_data = this.$store.getters.user;
    }

    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.typeOptions = this.obj.type_list;
        this.encData = this.obj.param_data;

        this.encData.reg_user = this.admin_data.user_id;
        this.encData.update_user = this.admin_data.user_id;
      });
    },
    async ok() {
      try {
        if (obj.edit_type === "add") {
          this.addEnc();

          window.alert("설정값이 등록되었습니다.");

          var context = "종류 : " + this.menuData.type + ", 설정명 : " + this.menuData.name + ", 설정값 : " + this.menuData.value;
          this.addAdminLog("설정값 관리", "설정값 등록", "설정값 등록 팝업", context, this.admin_data.user_id);
        } else if (obj.edit_type === "edit") {
          this.updateEnc();

          window.alert("설정값이 수정되었습니다.");

          var context = "종류 : " + this.menuData.type + ", 설정명 : " + this.menuData.name + ", 설정값 : " + this.menuData.value;
          this.addAdminLog("설정값 관리", "설정값 수정", "설정값 수정 팝업", context, this.admin_data.user_id);
        }

        this.$emit("reflash");
        this.$bvModal.hide("modal-enc-edit");
      }  catch (error) {
        console.log(error);
      }
    },
    setData(select_rowData) {
      this.encData = select_rowData;
    },
    async addEnc() {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addEnc", {
            type: this.encData.type,
						name: this.encData.name,
						value: this.encData.value,
						reg_user: this.admin_data.user_id,
						reg_date: crt_date
          }
        );
      } catch (error) {
        // console.log(7);
        window.alert("설정값 등록을 실패했습니다.");
        console.log(error);
      }
    },
    async updateEnc() {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/updateEnc", {
            id: this.encData.id,
						name: this.encData.name,
						value: this.encData.value,
						update_user: this.admin_data.user_id,
						update_date: crt_date
          }
        );
      } catch (error) {
        // console.log(7);
        window.alert("설정값 수정을 실패했습니다.");
        console.log(error);
      }
    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("관리자 명령 등록을 실패했습니다.");
      }
    },
    add() {
      this.obj_data.param_data = this.typeOptions;

      this.$bvModal.show("modal-enc-add");
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
    updateType(input) {
      let data = this.typeOptions;
      data.push({text: input, value: input});
      this.typeOptions = data;

      this.encData.type = input;
    }
  },
};
</script>

<style lang="scss" scoped></style>
