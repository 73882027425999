<template>
  <b-modal
    id="modal-fee-add"
    title="요금제 추가"
    ok-title="등록"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="false"
    size="lg"
    @shown="init"
  >
    <div class="pr-md-2 overflow-auto">
      <div>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />업체명
            </span>
          </div>
          <div class="d-flex" style="flex: 1; padding: 0 5px;">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" v-model="bid_name"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />요금제명
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="cf_title"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />요금제 목록
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select style="height: 50px; border-radius: 10px;" v-model="cf_value" :options="cfOptions"></b-form-select>
            <div class="d-flex align-items-center">
              <b-form-group class="ml-auto text-gray">
                <b-form-checkbox v-model="fee_copy">
                  복사
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  components: {
    
  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      bid: String,
      bid_fee_list: Array
    }
  },
  data() {
    return {
      bid_list: [],
      bid_name: "",
      cf_value: "",
      cfOptions: [],
      cf_list: [],
      fee_copy: false,
      add_list: [],
      admin_data: {},
    };
  },
  mounted() {
    if (this.$store.getters.user === null) {
      this.admin_data.user_id = "admin";
    } else {
      this.admin_data = this.$store.getters.user;
    }

    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.bid_list = this.obj.bid_fee_list;
        this.bid_name = this.bid_list[0].company_name;

        let cf_data = this.bid_list.filter(
          (e, i, callback) =>
            i === callback.findIndex(
              (e1) => e1.cf_id === e.cf_id
            )
        );

        let temp_cfData = [];
        let options = [];
        for(let j = 0; j < cf_data.length; j++) {
          let temp_data = [];
          this.charge_fee.forEach((e,i) => {
            if (e.cf_id === cf_data[j].cf_id) {
              temp_data.push(e);
              temp_cfData.push(e);
            }
          });

          //temp_cfData.push(temp_data[0]);
          options.push({ text: temp_data[0].cf_title, value: temp_data[0].cf_id});
        };

        this.cf_list = temp_cfData;
        this.cfOptions = options;
        this.cf_value = this.cfOptions[0].value;
      });
    },
    async ok() {
      try {
        this.selectChargeFeeValidate();

        this.$emit("reflash");
        this.$bvModal.hide("modal-fee-add");
      }  catch (error) {
        console.log(error);
      }
    },
    setData(select_rowData) {
      this.bid_list = select_rowData.bid_fee_list;
      this.bid_name = this.bid_list[0].company_name;

      let cf_data = this.bid_list.filter(
        (e, i, callback) =>
          i === callback.findIndex(
            (e1) => e1.cf_id === e.cf_id
          )
      );

      let temp_cfData = [];
      let options = [];
      for(let j = 0; j < cf_data.length; j++) {
        let temp_data = [];
        this.charge_fee.forEach((e,i) => {
          if (e.cf_id === cf_data[j].cf_id) {
            temp_data.push(e);
            temp_cfData.push(e);
          }
        });

        //temp_cfData.push(temp_data[0]);
        options.push({ text: temp_data[0].cf_title, value: temp_data[0].cf_id});
      };

      this.cf_list = temp_cfData;
      this.cfOptions = options;
      this.cf_value = this.cfOptions[0].value;
    },
    async selectChargeFeeValidate() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectChargeFeeValidate", {
            bid : this.obj.bid_name,
            cf_title : this.cf_title
          }
        );
        if (data.length > 0) {
          let fee_name = this.cf_title;
          let id_chk = data;
          let chargeFee_data = this.cfOptions;

          // 요금제 ID 생성
          let fee_no = 0;
          let temp_id = null;
          if (chargeFee_data.length == 0) {
            fee_no = 1;
          } else {
            temp_id = chargeFee_data[chargeFee_data.length - 1].cfId.split("_");
            let fee_lastNo = parseInt(temp_id[1]);
            fee_no = fee_lastNo + 1;
          }

          let max_regNo = '00' + String(fee_no);
          let mod_regNo = max_regNo.slice(-2);
          let cf_id = temp_id[0] + "_" + mod_regNo;

          if (id_chk.length > 0) {
            alert("이미 존재하는 요금제입니다. 요금제명을 변경해 보시기 바랍니다.");
          } else {
            let addFee_list = [];
            let add_cf_id = this.cf_value;
            this.bid_list.forEach((e,i) => {
              if (e.cf_id === add_cf_id) {
                addFee_list.push(e);
              }
            });

            let list_data = {};
            let crt_date = this.getToday();

            for (a=0; a < addFee_data.length; a++) {
              if (this.fee_copy === true) {				// 기존 요금제와 동일한 요금으로 신규 요금제 생성
                list_data = { 
                  sid: addFee_data[a].sid,
                  bid: addFee_data[a].bid,
                  cf_id: cf_id,
                  cf_title: fee_name,
                  name: addFee_data[a].name,
                  sub_name: addFee_data[a].sub_name,
                  season_start: addFee_data[a].season_start,
                  season_end: addFee_data[a].season_end,
                  time_start: addFee_data[a].time_start,
                  time_end: addFee_data[a].time_end,
                  fee_fast: addFee_data[a].fee_fast,
                  fee_low: addFee_data[a].fee_low,
                  reg_user: this.admin_data.user_id,
                  reg_date: crt_date,
                  update_user: "",
                  update_date: "",
                  use_date: crt_date,
                  use_yn: "Y",
                  user_type: addFee_data[a].user_type
                };
              } else if (this.fee_copy === false) {			// 모든 요금 0원으로 신규 요금제 생성
                list_data = { 
                  sid: addFee_data[a].sid,
                  bid: addFee_data[a].bid,
                  cf_id: cf_id,
                  cf_title: fee_name,
                  name: addFee_data[a].name,
                  sub_name: addFee_data[a].sub_name,
                  season_start: addFee_data[a].season_start,
                  season_end: addFee_data[a].season_end,
                  time_start: addFee_data[a].time_start,
                  time_end: addFee_data[a].time_end,
                  fee_fast: "0",
                  fee_low: "0",
                  reg_user: this.admin_data.user_id,
                  reg_date: crt_date,
                  update_user: "",
                  update_date: "",
                  use_date: crt_date,
                  use_yn: "Y",
                  user_type: addFee_data[a].user_type
                };
              };
              addFee_list.push(list_data);
            };

            this.add_list = addFee_list;
            this.add();

            window.alert("요금제 등록이 완료되었습니다.");
            let context = "업체명 : [" + this.bid_name + "]의 요금제 추가";
            content = "업체명: " + this.bid_name + ", 요금제명: " + this.cf_title;
		        this.addAdminLog("전력량 요금 관리", "요금제 추가", "요금 추가 팝업", context, this.admin_data.user_id);			// 관리자 명령 로그 추가
          }
        } else if (data.length == 0) {
          window.alert("데이터가 없습니다.");
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async add() {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addChargeFeeList", this.add_list
        );
      } catch (error) {
        // console.log(7);
        window.alert("요금제 등록을 실패했습니다.");
        console.log(error);
      }
    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("관리자 명령 등록을 실패했습니다.");
      }
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
  },
};
</script>

<style lang="scss" scoped></style>
