<template>
  <div class="" style="height: inherit;" ref="element" v-resize @resize="onResize">
    <b-container>
      <b-row style="margin: 0px 0px 10px 0px;">
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">충전기 상태</span>
          <b-form-select size="sm" style="flex: 4;" v-model="filterData.searchStatus" @change="filterSet" :options="searchStatusOption"></b-form-select>
        </b-col>
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">검색조건</span>
          <div class="d-flex align-items-center" style="gap: 10px; flex: 4; padding: 5px;">
            <b-form-select size="sm" style="flex: 1;" v-model="filterData.searchKeyType" @change="filterSet" :options="searchKeyOption"></b-form-select>
            <b-form-input 
              size="sm"
              v-model="filterData.searchKey"
              style="flex: 3; height: 50px; border-radius: 10px;"
              @change="filterSet"
            ></b-form-input>
          </div>
        </b-col>
        <b-col lg=6>
          
        </b-col>
      </b-row>
      <b-row style="margin: 0px 0px 10px 0px;">
        <b-col lg=6 class="d-flex justify-content-between flex-row align-items-center">
          <span>전체 {{ gridFilterData.length }} 건</span>
        </b-col>
        <b-col lg=6>
          <div class="d-flex justify-content-end">
            <div class="d-flex" style="width: 165px;">
              <switch-button :checked="isAutoReflash" @switch="autoReflash" label="자동 갱신"/>
            </div>
            <b-button class="btn_def" @click="DownloadExcel">엑셀다운</b-button>
          </div>
        </b-col>
      </b-row>
      <b-row style="width: inherit; margin: 0px;" :style="{'height': page_grid_height + 'px'}">
        <div class="table-wrapper2" style="width: inherit;" >
          <b-table 
            id = "grid"
            bordered hover noCollapse 
            :items="gData"
            :fields="gridFields"
            small
          >
            <template #cell(No)="row">
              <div 
                :class="{ 'td-color-red': gData[row.index].text_color === 'red'}"
                style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ row.index + 1 + ((currentPage - 1) * perPage) }}
              </div>
            </template>
            <template #cell(sname)="row">
              <div 
                :class="{ 'td-color-red': gData[row.index].text_color === 'red'}"
                style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].s_name }}
              </div>
            </template>
            <template #cell(sid)="row">
              <div 
                :class="{ 'td-color-red': gData[row.index].text_color === 'red'}"
                style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].sid }}
              </div>
            </template>
            <template #cell(cid)="row">
              <div 
                :class="{ 'td-color-red': gData[row.index].text_color === 'red'}"
                style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].cid }}
              </div>
            </template>
            <template #cell(tel_num)="row">
              <div
                :class="{ 'td-color-red': gData[row.index].text_color === 'red'}"
                style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ phoneFormatter(gData[row.index].reader_id) }}
              </div>
            </template>
            <template #cell(lnf)="row">
              <div 
                :class="{ 'td-color-red': gData[row.index].text_color === 'red'}"
                style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ lafFormatter(gData[row.index].laf) }}
              </div>
            </template>
            <template #cell(c_channel)="row">
              <div 
                :class="{ 'td-color-red': gData[row.index].text_color === 'red'}"
                style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ channelFormatter(gData[row.index].c_channel) }}
              </div>
            </template>
            <template #cell(status)="row">
              <div 
                :class="{ 'td-color-red': gData[row.index].text_color === 'red'}"
                style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].status_name }}
              </div>
            </template>
            <template #cell(cable_status)="row">
              <div
                :class="{ 'td-color-red': gData[row.index].text_color === 'red'}"
                style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ cableFormatter(gData[row.index].cable_status) }}
              </div>
            </template>
            <template #cell(rs_signal)="row">
              <div 
                :class="{ 'td-color-red': gData[row.index].text_color === 'red'}"
                style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].rs_signal }}
              </div>
            </template>
            <template #cell(firmware_ver)="row">
              <div 
                :class="{ 'td-color-red': gData[row.index].text_color === 'red'}"
                style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].firmware_ver }}
              </div>
            </template>
            <template #cell(abnormal_name)="row">
              <div 
                :class="{ 'td-color-red': gData[row.index].text_color === 'red', 'td-color-blue': gData[row.index].text_color === 'blue'}"
                style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].abnormal_name }}
              </div>
            </template>
            <template #cell(a_electricity)="row">
              <div 
                :class="{ 'td-color-red': gData[row.index].text_color === 'red'}"
                style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ doubleFormatter(gData[row.index].a_electricity) }}
              </div>
            </template>
            <template #cell(a_amount)="row">
              <div 
                :class="{ 'td-color-red': gData[row.index].text_color === 'red'}"
                style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ intFormatter(gData[row.index].a_amount) }}
              </div>
            </template>
            <template #cell(cp_update_date)="row">
              <div 
                :class="{ 'td-color-red': gData[row.index].text_color === 'red'}"
                style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ regDateTimeFormatter(gData[row.index].cp_update_date) }}
              </div>
            </template>
            <template #cell(tran_id)="row">
              <div 
                :class="{ 'td-color-red': gData[row.index].text_color === 'red'}"
                style="cursor: pointer;" @click="tranListCall(row.index)">
                {{ tranIdFormatter(row.index) }}
              </div>
            </template>
          </b-table>
        </div>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="gridFilterData.length"
          :per-page="perPage"
          first-text="⏮"
          prev-text="⏪"
          next-text="⏩"
          last-text="⏭"
          class="mt-4"
          size="sm"
        ></b-pagination>
      </b-row>

      <!-- modals -->
      <modal-excel-upload :file_type="file_type"/>
      <modal-tran-list ref="modal_tranList" :obj="obj" />
    </b-container>
  </div>
</template>

<script>
import Form from "@/components/Form/index";
import regex from "@/lib/regex";

import Modals from "@/components/Modals/index";
import { ref } from 'vue';

import * as XLSX from 'xlsx' // 라이브러리 import

import $ from 'jquery';

export default {
  components: {
    SwitchButton: Form.SwitchButton,
    // Modals
    ModalExcelUpload: Modals.ExcelUpload,
    ModalTranList: Modals.TranList,
  },
  data() {
    const isDev = process.env.NODE_ENV !== "production";

    return {
      isAutoReflash: true,
      formValid: false,
      salt: 0,
      crt_width: 0,
      crt_height: 0,
      page_grid_height: 0,
      gun_data: [],
      capacity_data: [],
      method_data: [],
      installType_data: [],
      select_index: -1,
      select_rowData: {
        
      },
      perPage: 1,
      currentPage: 1,
      gridDefaultData: [

      ],
      gridFilterData: [

      ],
      gData: [

      ],
      gridFields: [
        {
          key: "No",
          label: "No"
        },
        {
          key: "sname",
          label: "충전소명"
        },
        {
          key: "sid",
          label: "충전소 ID"
        },
        {
          key: "cid",
          label: "충전기 ID"
        },
        {
          key: "tel_num",
          label: "단말기번호"
        },
        {
          key: "lnf",
          label: "급속/완속"
        },
        {
          key: "c_channel",
          label: "통신채널"
        },
        {
          key: "status",
          label: "충전기 상태"
        },
        {
          key: "cable_status",
          label: "케이블 상태"
        },
        {
          key: "rs_signal",
          label: "RS 신호세기"
        },
        {
          key: "firmware_ver",
          label: "펌웨어버전"
        },
        {
          key: "abnormal_name",
          label: "충전이상상태"
        },
        {
          key: "a_electricity",
          label: "누적 전력량"
        },
        {
          key: "a_amount",
          label: "누적 충전금액"
        },
        {
          key: "cp_update_date",
          label: "상태정보 갱신시간"
        },
        {
          key: "tran_id",
          label: "충전 ID"
        }
      ],
      filterData: {
        searchKeyType: "all",
        searchKey: "",
        searchStatus: "all",
      },
      searchKeyOption: [
        { text: "전체", value: "all" },
        { text: "충전소ID", value: "sid" },
        { text: "충전소이름", value: "sname" },
        { text: "단말기번호", value: "tel_num" },
        { text: "MID", value: "mid" }
      ],
      searchStatusOption: [
        { text: "전체", value: "all" },
        { text: "사용 가능", value: "Available" },
        { text: "준비중", value: "Preparing" },
        { text: "충전중", value: "Charging" },
        { text: "정지된 EVSE", value: "SuspendedEVSE" },
        { text: "정지된 EV", value: "SuspendedEV" },
        { text: "충전완료중", value: "Finishing" },
        { text: "예약대기", value: "Reserved" },
        { text: "존재하지 않음", value: "Unavailable" },
        { text: "오류 발생", value: "Faulted" },
      ],
      file_type: "chargerStatus_list",
      rules: {
        
      },
      excelUp_show: false,
      tranHistory_show: false,
      obj: {
        tran_id: 0,
        title: "충전 내역",
        ok_title: "확인",
      },
    };
  },
  computed: {
    
  },
  watch: {
    currentPage: function(newVal, oldVal) {
      let items = this.gridFilterData;

      this.selectPage(newVal);
    },
  },
  methods: {
    async selectCode() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectCode", {
            use_yn: "Y", 
            type: "CPC"
          }
        );

        if (data.length > 0) {
          let gun_data = [];
          let capacity_data = [];
          let method_data = [];
          let installType_data = [];
          
          this.gun_data = data.filter(
            function (e) {
              if (e.r_code === "CPC01") {
                return true;
              }
            }
          )

          this.capacity_data = data.filter(
            function (e) {
              if (e.r_code === "CPC02") {
                return true;
              }
            }
          )

          this.method_data = data.filter(
            function (e) {
              if (e.r_code === "CPC03") {
                return true;
              }
            }
          )

          this.installType_data = data.filter(
            function (e) {
              if (e.r_code === "CPC04") {
                return true;
              }
            }
          )
        } else {

        }

        this.selectChargerStatusList();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async selectChargerStatusList() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectChargerStatusList", {
            //company_id : ,
            //maker : 
          }
        );
        if (data.length > 0) {
          const now = new Date();
          const utc = now.getTime() + (now.getTimezoneOffset() * 60 * 1000);
          const koreaTimeDiff = 9 * 60 * 60 * 1000;
          const crt_date = new Date(utc + koreaTimeDiff);
          crt_date.setHours(crt_date.getHours() - 20);
          var year = crt_date.getFullYear();
          var month = crt_date.getMonth() + 1;
          var date = crt_date.getDate();
          var hours = crt_date.getHours();
          var minutes = crt_date.getMinutes();
          var seconds = crt_date.getSeconds();
          var issue_date = String(year) + String(month).padStart(2, "0") + String(date).padStart(2, "0") + String(hours).padStart(2, "0") + String(minutes).padStart(2, "0") + String(seconds).padStart(2, "0");

          for (let j=0; j < data.length; j++) {
            let row_data = data[j];

            if (row_data.status == "Charging") {
              data[j].text_color = "blue";
            };

            if (row_data.cp_update_date !== "" && row_data.cp_update_date !== null) {
              if (row_data.cp_update_date <= issue_date) {
                //gv_contract.setRowBackgroundColor(rowIndex, "red");
                data[j].text_color = "red";
                data[j].status_name = "통신오류";

                if (row_data.abnormal_name == "") {
                  data[j].abnormal_name = "통신오류";
                };
              };
            };

            if (data[j].abnormal_name === "" || data[j].abnormal_name === null) {
              data[j].abnormal_name = "이상없음";
            } else {
              if (data[j].abnormal_name === "I/O BD 통신불량" || data[j].abnormal_name === "Emergency 호출") {
                data[j].text_color = "red";
              };
            };
          }debugger;

          this.gData = data;
          this.gridDefaultData = data;
          this.filterSet();
        } else if (data.length == 0) {
          this.gData = null;
          window.alert("데이터가 없습니다.");
        }

        this.pageSet();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    tranListCall(row_index) {
      this.obj.tran_id = this.gridFilterData[row_index].tran_id;
      this.$refs.modal_tranList.setData(this.obj.tran_id);
      this.$bvModal.show("modal-tran-list");
    },
    clipboardCopy(index) {
      var copy_data = JSON.stringify(this.gData[index]);
      window.navigator.clipboard.writeText(copy_data).then( function (res) {alert("복사되었습니다.");} );
    },
    DownloadExcel() {
      var crt_date = this.getToday();
      var excel_name = "chargerStatus_list_" + crt_date;
      var workBook = XLSX.utils.book_new(); // 새로운 workbook 생성 
      var grid_list = XLSX.utils.json_to_sheet(this.gridFilterData);
      // var stdData = XLSX.utils.json_to_sheet(this.checkStdList); // json 객체를 sheet로 변환하여 워크시트 생성
      
      XLSX.utils.book_append_sheet(workBook, grid_list, 'chargerStatus_list'); // stdData 워크시트로 시트 생성, 해당 시트 이름 명명
      // XLSX.utils.book_append_sheet(workBook, uncheckData, '학생현황'); // 시트 추가
      XLSX.writeFile(workBook, excel_name + '.xlsx'); // 파일 생성;
    },
    UploadExcel() {
      this.file_type = "chargerStatus_list";
      this.$bvModal.show("modal-excel-upload");
    },
    async SampleDown() {
      try {
        const response = await this.$axios.post(
          "/api/SampleDownload", {
            filename: "chargerStatus_sample",
            responseType: "blob"
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "chargerStatus_sample.xlsx");
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
    filterSet() {
      let search_key = this.filterData.searchKey;
      let search_type = this.filterData.searchKeyType;
      let search_status = this.filterData.searchStatus;
      let temp_data = [];

      //this.gridDefaultData.

      if (search_key != "") {
        if (search_type === "all") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.sid.indexOf(search_key) != -1 || e.s_name.indexOf(search_key) != -1 || e.reader_id.indexOf(search_key) != -1 || e.mid.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "sid") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.sid.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "s_name") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.s_name.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "tel_num") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.reader_id.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "mid") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.mid.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        }

        this.gridFilterData = temp_data;
      } else {
        this.gridFilterData = this.gridDefaultData;
      }

      if (search_status != "all") {
        temp_data = this.gridFilterData.filter(
          function (e) {
            if (e.status.indexOf(search_status) != -1) {
              return true;
            }
          }
        )

        this.gridFilterData = temp_data;
      }

      this.pageSet();
    },
    selectRow(row_index) {
      var tbody = document.querySelector("#grid");
      var trs = tbody.getElementsByTagName('tr');
      
      var selectIndex = this.select_index + 1;

      if (this.select_index == -1) {
        this.select_index = row_index;
        this.select_rowData = this.gData[row_index];

        trs[row_index + 1].style.backgroundColor = "yellow";
      } else if (this.select_index > -1) {
        if (this.select_index === row_index) {
          this.select_index = -1;
          
          this.select_rowData = {};

          trs[selectIndex].style.backgroundColor = "white";
        } else {
          this.select_index = row_index;
          this.select_rowData = this.gData[row_index];

          trs[selectIndex].style.backgroundColor = "white";
          trs[row_index + 1].style.backgroundColor = "yellow";
        }
      }
    },
    onResize(e) {
      if (this.crt_width == 0 & this.crt_height == 0) {
        this.crt_width = e.detail.width;
        this.crt_height = e.detail.height;
        
        //this.pageSet();
      } else if (this.crt_width != e.detail.width || this.crt_height != e.detail.height) {
        this.crt_width = e.detail.width;
        this.crt_height = e.detail.height;
        
        this.pageSet();  
      }
    },
    pageSet() {
      var filter_height = 60;
      var button_height = 60;
      var pagination_height = 50;
      var grid_header_height = 60;
      var grid_row_height = 65;
      var grid_data_height = this.crt_height - filter_height - button_height - pagination_height - grid_header_height - 40;
      this.page_grid_height = grid_data_height;

      var page_row_count = Math.floor(grid_data_height / grid_row_height);
      var max_page_num = Math.ceil(this.gridFilterData.length / page_row_count);

      this.perPage = page_row_count;

      this.currentPage = 1;
      this.selectPage(1);
    },
    selectPage(page) {
      let temp_grid_data = this.gridFilterData;

      this.gData = temp_grid_data.slice(
        (page - 1) * this.perPage, page * this.perPage
      )
    },
    autoReflash(e) {
      if (e) {
        console.log("on");
        this.setTimer = setInterval(this.selectChargerStatusList, 30000);
      } else {
        console.log("off");
        clearInterval(this.setTimer);
      }
    },
    // 날짜 formatter
    regDateFormatter(data) {
      return data.substr(0,4) + "-" + data.substr(4,2) + "-" + data.substr(6,2);
    },
    // 날짜 + 시간 formatter
    regDateTimeFormatter(data) {
      return data.substr(0,4) + "-" + data.substr(4,2) + "-" + data.substr(6,2) + " " + data.substr(8,2) + ":" + data.substr(10,2) + ":" + data.substr(12,2);
    },
    // [완속/급속] formatter
    lafFormatter(data) {
      if (data == "L") {
        data = "완속";
      } else if (data == "F") {
        data = "급속";
      };

      return data;
    },
    // 통신채널 formatter
    channelFormatter(data) {
      let cpGun_data = this.gun_data.filter(
        function (e) {
          if (e.code === data) {
            return true;
          }
        }
      )

      return cpGun_data[0].code_name;
    },
    // 케이블 연결 formatter
    cableFormatter(data) {
      if (data == "N") {
        data = "해제";
      } else if (data == "Y") {
        data = "연결";
      };

      return data;
    },
    // 전화번호 formatter
    phoneFormatter(data) {
      if (data.length > 8 && data.length < 12) {
        if (data.substr(0,3) == "010" && data.substr(0,3) == "011" && data.substr(0,3) == "017" && data.substr(0,3) == "019") {
          return data.substr(0,3) + "-" + data.substr(3,4) + "-" + data.substr(7,4);
        } else {
          if (data.substr(0,2) == "02") {
            if (data.length == 9) {
              return data.substr(0,2) + "-" + data.substr(2,3) + "-" + data.substr(5,4);
            } else if (data.length == 10) {
              return data.substr(0,2) + "-" + data.substr(2,4) + "-" + data.substr(6,4);
            };
          } else {
            if (data.length == 10) {
              return data.substr(0,3) + "-" + data.substr(3,3) + "-" + data.substr(6,4);
            } else if (data.length == 11) {
              return data.substr(0,3) + "-" + data.substr(3,4) + "-" + data.substr(7,4);
            };
          };
        };
      } else {
        return data;
      };
    },
    // 이상상태 formatter
    abnormalName(data, rowIndex) {
      let row_data = this.gData[rowIndex];
      const now = new Date();
      const utc = now.getTime() + (now.getTimezoneOffset() * 60 * 1000);
      const koreaTimeDiff = 9 * 60 * 60 * 1000;
      const crt_date = new Date(utc + koreaTimeDiff);
      crt_date.setHours(crt_date.getHours() - 20);
      var year = crt_date.getFullYear();
      var month = crt_date.getMonth() + 1;
      var date = crt_date.getDate();
      var hours = crt_date.getHours();
      var minutes = crt_date.getMinutes();
      var seconds = crt_date.getSeconds();
      var issue_date = String(year) + String(month).padStart(2, "0") + String(date).padStart(2, "0") + String(hours).padStart(2, "0") + String(minutes).padStart(2, "0") + String(seconds).padStart(2, "0");

      if (row_data.status == "Charging") {
        this.gData[rowIndex].text_color = "blue";
      };

      if (row_data.cp_update_date != "") {
        if (row_data.cp_update_date <= issue_date) {
          //gv_contract.setRowBackgroundColor(rowIndex, "red");
          this.gData[rowIndex].text_color = "red";
          this.gData[rowIndex].status_name = "통신오류";

          if (row_data.abnormal_name == "") {
            this.gData[rowIndex].abnormal_name = "통신오류";
          };
        };
      };

      if (data == "") {
        this.gData[rowIndex].abnormal_name = "이상없음";
      } else {
        if (data == "I/O BD 통신불량" || data == "Emergency 호출") {
          this.gData[rowIndex].text_color = "red";
        };
      };

      return data;
    },
    // 충전ID formatter
    tranIdFormatter(rowIndex) {
      let row_data = this.gData[rowIndex];
      let formattedData = "";

      if (row_data.status != "Charging") {
        formattedData = "";
      } else {
        formattedData = row_data.tran_id;
      };

      return formattedData;
    },
    // 정수 formatter
    intFormatter(value) {
      const result_value = parseInt(value).toLocaleString('ko-KR');

      return result_value;
    },
    // 실수 formatter
    doubleFormatter(value) {
      const option = {
        maximumFractionDigits: 2
      };

      const result_value = parseFloat(value).toLocaleString('ko-KR', option);

      return result_value;
    }
  },
  beforeDestroy() {
    //clearInterval(this.timerActive);
  },
  async mounted() {
    let page_data = this.$refs.element;
    this.crt_width = page_data.clientWidth;
    this.crt_height = page_data.clientHeight;
    
    this.selectCode();
    // this.selectChargerStatusList();
  },
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: goldenrod;
}
.td-color-blue {
  color: blue;
}
.td-color-red {
  color: red;
}
</style>