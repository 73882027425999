<template>
  <b-modal
    id="modal-user-car"
    title="회원 차량 정보"
    ok-title="등록"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="px-4 pt-4 pb-0 border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="false"
    size="lg"
    @shown="init"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxWidth: '800px', maxHeight: '1000px' }"
    >
      <div class="mt-1">
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />브랜드
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select size="sm" v-model="userCarData.mc_code" :options="mCarOptions" @change="changeMcar($event)"></b-form-select>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />차량 모델
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select size="sm" v-model="userCarData.car_code" :options="carOptions"></b-form-select>
          </div>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  components: {
  
  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    }
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      edit_type: String,
      payment_data: Object,
      car_data: Object
    },
  },
  data() {
    return {
      admin_data: {},
      userCarData: {},
      mCar_data: [],
      car_data: [],
      mCarOptions: [],
      carOptions: []
    };
  },
  mounted() {
    if (this.$store.getters.user === null) {
      this.admin_data.user_id = "admin";
    } else {
      this.admin_data = this.$store.getters.user;
    }

    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.userCarData = this.obj.car_data;

        this.setCarData();
      });
    },
    async setCarData() {debugger;
      try {
        const { data } = await this.$axios.post(
          "/api/selectCode", {
            use_yn: "Y", 
            type: "CC"
          }
        );

        if (data.length > 0) {
          let mCarData = [];
          let carData = [];
          
          this.mCar_data = data.filter(
            function (e) {
              if (e.level === 1) {
                return true;
              }
            }
          )

          this.car_data = data.filter(
            function (e) {
              if (e.level === 2) {
                return true;
              }
            }
          )

          let options1 = [];
          let options2 = [];

          this.mCar_data.forEach((e,i) => {
            let add_option = { value: e.code, text: e.code_name }
            options1.push(add_option);
          });

          this.car_data.forEach((e,i) => {
            if (e.r_code === this.mCar_data[0].code) {
              let add_option = { value: e.code, text: e.code_name }
              options2.push(add_option);
            }
          });

          this.mCarOptions = options1;
          this.carOptions = options2;

          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            this.userCarData.mc_code = this.mCarOptions[0].value;
            this.userCarData.mc_name = this.mCarOptions[0].text;

            this.userCarData.car_code = this.carOptions[0].value;
            this.userCarData.car_name = this.carOptions[0].text;
          }
        } else if (data.length == 0) {
          this.mCarOptions = null;
          this.carOptions = null;
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    changeMcar (select_mcar) {
      let options = [];
      this.car_data.forEach((e,i) => {
        if (e.r_code === select_mcar) {
          let add_option = { value: e.code, text: e.code_name };
          options.push(add_option);
        }
      });

      this.carOptions = options;

      this.userCarData.car_code = this.carOptions[0].value;
      this.userCarData.car_name = this.carOptions[0].text;
    },
    ok() {
      this.$emit("input", this.userCarData.mc_code, this.userCarData.mc_name, this.userCarData.car_code, this.userCarData.car_name);
      this.$bvModal.hide("modal-user-car");
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
    setData(select_rowData) {
      this.userCarData = select_rowData;
      let options2 = [];
      if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
        this.car_data.forEach((e,i) => {
          if (e.r_code === this.mCarOptions[0].value) {
            let add_option = { value: e.code, text: e.code_name, c_position: e.c_position, laf: e.laf }
            options2.push(add_option);
          }
        });

        this.carOptions = options2;

        this.userCarData.mc_code = this.mCarOptions[0].value;
        this.userCarData.mc_name = this.mCarOptions[0].text;

        this.userCarData.car_code = this.carOptions[0].value;
        this.userCarData.car_name = this.carOptions[0].text;
        this.userCarData.c_position = this.carOptions[0].c_position;
        this.userCarData.laf = this.carOptions[0].laf;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
