<template>
  <b-container fluid class="px-0 home-container">
    <!-- 메인/빠른검색 -->
    <article class="py-5">
      <section>
        <div>
          <div class="mt-1">
            <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">충전소 정보조회</b-button>
            <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">충전기 상태정보 조회</b-button>
            <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">충전기 일반정보 조회</b-button>
            <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">충전소 상태 갱신</b-button>
            <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">회원 카드 조회</b-button>
            <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">회원 카드 등록/갱신</b-button>
            <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">회원카드 통계 조회</b-button>
            <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">충전이력 조회</b-button>
            <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">충전기 충전이력 등록</b-button>
            <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">충전이력 통계 조회</b-button>
            <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">충전기 이용정보 조회</b-button>
            <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">충전기 이용정보 등록</b-button>
            <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">충전기 이용정보 삭제</b-button>
            <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">충전기 이용정보 통계 조회</b-button>
            <b-button class="mx-1 my-1 tab_btn" @click="changeProtocolType">공통코드 조회</b-button>
          </div>
        </div>
      </section>
      <section>
        <div style="padding: 10px;">
          <b-row>
            <b-col lg="6">
              <template>
                <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 10px;">
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" />입력
                  </span>
                  <div>
                    <b-button class="mx-1 my-1 default_btn" size="sm" @click="send">호출</b-button>
                  </div>
                </div>
              </template>
              <template>
                <div v-if="select.protocol === '충전소 정보조회'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bid(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bkey(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bkey"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />period
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.period"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bdate
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bdate"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />rbid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.rbid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />sid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.sid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />cid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.cid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />selfex
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.selfex"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />zcode
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.zcode"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === '충전기 상태정보 조회'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bid(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bkey(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bkey"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />period
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.period"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bdate
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bdate"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />rbid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.rbid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />sid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.sid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />cid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.cid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />selfex
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.selfex"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />zcode
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.zcode"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === '충전기 일반정보 조회'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bid(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bkey(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bkey"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bmngid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bmngid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />sid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.sid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />cid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.cid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === '충전소 상태 갱신'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bid(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bkey(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bkey"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />sid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.cstat.sid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />cid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.cstat.cid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />status
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.cstat.status"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === '회원 카드 조회'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bid(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bkey(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bkey"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />period
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.period"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bdate
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bdate"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />rbid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.rbid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />cardNo
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.cardNo"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />selfex
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.selfex"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === '회원 카드 등록/갱신'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bid(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bkey(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bkey"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />cardNo
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.card.no"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />cardStop
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.card.stop"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === '회원카드 통계 조회'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bid(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bkey(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bkey"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />rbid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.rbid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === '충전이력 조회'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bid(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bkey(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bkey"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />rbid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.rbid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />tbase
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.tbase"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />start
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.start"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />end
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.end"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bdate
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bdate"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />btid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.btid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />selfex
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.selfex"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === '충전기 충전이력 등록'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bid(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bkey(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bkey"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />no
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.trade.no"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />sid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.trade.sid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />cid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.trade.cid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />tbid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.trade.tbid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />tsdt
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.trade.tsdt"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />tedt
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.trade.tedt"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />pow
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.trade.pow"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />mon
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.trade.mon"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bprice
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.trade.bprice"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />tbprice
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.trade.tbprice"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bmon
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.trade.bmon"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />rcvdate
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.trade.rcvdate"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />btid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.btid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === '충전이력 통계 조회'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bid(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bkey(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bkey"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />rbid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.rbid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />tbase
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.tbase"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />start
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.start"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />end
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.end"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === '충전기 이용정보 조회'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bid(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bkey(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bkey"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />tbase
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.tbase"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />start
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.start"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />end
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.end"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bdate
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bdate"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === '충전기 이용정보 등록'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bid(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bkey(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bkey"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />sid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.use.sid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />cid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.use.cid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />tsdt
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.use.tsdt"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />tedt
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.use.tedt"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />pow
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.use.pow"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />mon
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.use.mon"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />rcvdate
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.use.rcvdate"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === '충전기 이용정보 삭제'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bid(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bkey(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bkey"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />sid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.use.sid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />cid
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.use.cid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />tsdt
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.use.tsdt"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />tedt
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.use.tedt"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === '충전기 이용정보 통계 조회'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bid(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bkey(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bkey"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />tbase
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.tbase"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />start
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.start"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />end
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.end"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
                <div v-else-if="select.protocol === '공통코드 조회'">
                  <b-row>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bid(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bid"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                    <template>
                      <b-col lg="2" class="mb-2">
                        <div>
                          <span class="text-17">
                            <font-awesome-icon />bkey(*)
                          </span>
                        </div>
                      </b-col>
                      <b-col lg="10" class="mb-2">
                        <div>
                          <b-form-input size="sm" v-model="reqData.bkey"></b-form-input>
                        </div>
                      </b-col>
                    </template>
                  </b-row>
                </div>
              </template>
            </b-col>
            <b-col lg="6">
              <template>
                <div style="height: 61.5px; margin-bottom: 10px;">
                  <span class="text-17 text-danger">
                    <font-awesome-icon icon="fa-solid fa-asterisk" />결과
                  </span>
                </div>
              </template>
              <b-form-textarea
                id="resultText"
                v-model="result_text"
                placeholder="결과 출력..."
                size="sm"
                rows="4"
                max-rows="16"
              ></b-form-textarea>
            </b-col>
          </b-row>
        </div>
      </section>
    </article>      
  </b-container>
</template>

<script>


// 숫자 올리는 애니메이션
import IOdometer from "vue-odometer";
import "odometer/themes/odometer-theme-default.css";
import QuickMenu from "@/components/Nav/QuickMenu.vue";

import $ from 'jquery';

export default {
  name: "Ocpp Test",
  components: { 
    
  },
  props: {
    data: null, //해당 data에는 모든 api를 조회한 값이 담겨옴 (비딩 등록 직전의 데이터)
  },
  data() {
    return {
      select: {
        protocol: "충전소 정보조회"
      },
      main_url: "http://evcloud.duruscoev.com/roming-api/r2",
      reqData: {
        //bid: "DR",
        //bkey: "FZeTc22w6dB9czwy",
        bid: "PA",
        bkey: "xp9FDxu4f6R183yG",
        period: "",
        bdate: "",
        rbid: "",
        sid: "",
        cid: "",
        selfex: "",
        zcode: "",
        bmngid: "",
        cardno: "",
        tbase: "",
        start: "",
        end: "",
        btid: "",
        cstat: {
          sid: "",
          cid: "",
          status: "",
        },
        card: {
          no: "",
          stop: "",
        },
        trade: {
          no: "",
          sid: "",
          cid: "",
          tbid: "",
          tsdt: "",
          tedt: "",
          pow: "",
          mon: "",
          btid: "",
          bprice: "",
          tbprice: "",
          bmon: "",
          rcvdate: "",
        },
        use: {
          sid: "",
          cid: "",
          tsdt: "",
          tedt: "",
          pow: "",
          mon: "",
          rcvdate: "",
        }
      },
      callName: "",
      json_data: {},
      result_data: {},
      result_text: "",
      jsonRead_data: {}
    };
  },
  computed: {
    
  },
  watch: {
    
  },
  methods: {
    changeProtocolType(e) {
      //console.log(event.target.textContent);
      let button_text = e.target.textContent;
      this.select.protocol = button_text;
    },
    async send() {
      let param_json = {
        bid: "DR", 
        bkey: "FZeTc22w6dB9czwy"
      };

      let url = "";

      if (this.select.protocol === "충전소 정보조회") {
        url = "/charger/info/list";

        if (this.reqData.period != "") {
          param_json.period = this.reqData.period;
        };

        if (this.reqData.bdate != "") {
          param_json.bdate = this.reqData.bdate;
        };

        if (this.reqData.rbid != "") {
          param_json.rbid = this.reqData.rbid;
        };

        if (this.reqData.sid != "") {
          param_json.sid = this.reqData.sid;
        };

        if (this.reqData.cid != "") {
          param_json.cid = this.reqData.cid;
        };

        if (this.reqData.selfex != "") {
          param_json.selfex = this.reqData.selfex;
        };

        if (this.reqData.zcode != "") {
          param_json.zcode = this.reqData.zcode;
        };
      } else if (this.select.protocol === "충전기 상태정보 조회") {
        url = "/charger/status/list";

        if (this.reqData.period != "") {
          param_json.period = this.reqData.period;
        };

        if (this.reqData.bdate != "") {
          param_json.bdate = this.reqData.bdate;
        };

        if (this.reqData.rbid != "") {
          param_json.rbid = this.reqData.rbid;
        };

        if (this.reqData.sid != "") {
          param_json.sid = this.reqData.sid;
        };

        if (this.reqData.cid != "") {
          param_json.cid = this.reqData.cid;
        };

        if (this.reqData.selfex != "") {
          param_json.selfex = this.reqData.selfex;
        };

        if (this.reqData.zcode != "") {
          param_json.zcode = this.reqData.zcode;
        };
      } else if (this.select.protocol === "충전기 일반정보 조회") {
        url = "/charger/info/mylist";

        if (this.reqData.bmngid != "") {
          param_json.bmngid = this.reqData.bmngid;
        };

        if (this.reqData.sid != "") {
          param_json.sid = this.reqData.sid;
        };

        if (this.reqData.cid != "") {
          param_json.cid = this.reqData.cid;
        };
      } else if (this.select.protocol === "충전소 상태 갱신") {
        url = "/charger/status/update";

        cstat = [];
			  cstat_data = {
          sid: this.reqData.cstat.sid, 
          cid: this.reqData.cstat.cid, 
          status: this.reqData.cstat.status
        };

      } else if (this.select.protocol === "회원 카드 조회") {
        url = "/card/list";

        if (this.reqData.period != "") {
          param_json.period = this.reqData.period;
        };

        if (this.reqData.bdate != "") {
          param_json.bdate = this.reqData.bdate;
        };

        if (this.reqData.rbid != "") {
          param_json.rbid = this.reqData.rbid;
        };

        if (this.reqData.cardNo != "") {
          param_json.cardno = this.reqData.cardNo;
        };

        if (this.reqData.selfex != "") {
          param_json.selfex = this.reqData.selfex;
        };
      } else if (this.select.protocol === "회원 카드 등록/갱신") {
        url = "/card/update";

        card = [];
        card_data = {
          no: this.reqData.card.no, 
          stop: this.reqData.card.stop
        };

        card.push(card_data);
        param_json.card = card;
      } else if (this.select.protocol === "회원카드 통계 조회") {
        url = "/card/stat";

        if (this.reqData.rbid != "") {
          param_json.rbid = this.reqData.rbid;
        };
      } else if (this.select.protocol === "충전이력 조회") {
        url = "/trade/list";

        if (this.reqData.rbid != "") {
          param_json.rbid = this.reqData.rbid;
        };

        if (this.reqData.tbase != "") {
          param_json.tbase = this.reqData.tbase;
        };

        if (this.reqData.start != "") {
          param_json.start = this.reqData.start;
        };

        if (this.reqData.end != "") {
          param_json.end = this.reqData.end;
        };

        if (this.reqData.bdate != "") {
          param_json.bdate = this.reqData.bdate;
        };

        if (this.reqData.btid != "") {
          param_json.btid = this.reqData.btid;
        };

        if (this.reqData.selfex != "") {
          param_json.selfex = this.reqData.selfex;
        };
      } else if (this.select.protocol === "충전기 충전이력 등록") {
        url = "/trade/regi";

        var trade = [];
        var trade_data = {
          no: this.reqData.trade.no, 
          sid: this.reqData.trade.sid, 
          cid: this.reqData.trade.cid, 
          tbid: this.reqData.trade.tbid, 
          tsdt: this.reqData.trade.tsdt, 
          tedt: this.reqData.trade.tedt, 
          pow: this.reqData.trade.pow, 
          mon: this.reqData.trade.mon
        };
        var trade_data_count = 0;

        if (this.reqData.btid != "") {
          trade_data.btid = this.reqData.btid;
        };

        if (this.reqData.trade.bprice != "") {
          trade_data.bprice = this.reqData.trade.bprice;
        };

        if (this.reqData.trade.tbprice != "") {
          trade_data.tbprice = this.reqData.trade.tbprice;
        };

        if (this.reqData.trade.bmon != "") {
          trade_data.bmon = this.reqData.trade.bmon;
        };

        if (this.reqData.trade.rcvdate != "") {
          trade_data.rcvdate = this.reqData.trade.rcvdate;
        };

        if (trade_data_count > 0) {
          trade.push(trade_data);
          param_json.trade = trade;
        };
      } else if (this.select.protocol === "충전이력 통계 조회") {
        url = "/trade/stat";

        if (this.reqData.rbid != "") {
          param_json.rbid = this.reqData.rbid;
        };

        if (this.reqData.tbase != "") {
          param_json.tbase = this.reqData.tbase;
        };

        if (this.reqData.start != "") {
          param_json.start = this.reqData.start;
        };

        if (this.reqData.end != "") {
          param_json.end = this.reqData.end;
        };
      } else if (this.select.protocol === "충전기 이용정보 조회") {
        url = "/use/list";

        if (this.reqData.tbase != "") {
          param_json.tbase = this.reqData.tbase;
        };

        if (this.reqData.start != "") {
          param_json.start = this.reqData.start;
        };

        if (this.reqData.end != "") {
          param_json.end = this.reqData.end;
        };

        if (this.reqData.bdate != "") {
          param_json.bdate = this.reqData.bdate;
        };
      } else if (this.select.protocol === "충전기 이용정보 등록") {
        url = "/use/regi";

        var use = [];
        var use_data = {
          sid: this.reqData.use.sid, 
          cid: this.reqData.use.cid, 
          tsdt: this.reqData.use.tsdt, 
          tedt: this.reqData.use.tedt, 
          pow: this.reqData.use.pow, 
          mon: this.reqData.use.mon
        };
        var use_data_count = 0;

        if (this.reqData.use.rcvdate != "") {
          use_data.rcvdate = this.reqData.use.rcvdate;
        };

        if (use_data_count > 0) {
          use.push(use_data);
          param_json.use = use;
        };
      } else if (this.select.protocol === "충전기 이용정보 삭제") {
        url = "/use/del";

        var use = [];
        var use_data = {
          sid: this.reqData.use.sid, 
          cid: this.reqData.use.cid, 
          tsdt: this.reqData.use.tsdt, 
          tedt: this.reqData.use.tedt
        };

        use.push(use_data);
        param_json.use = use;
      } else if (this.select.protocol === "충전기 이용정보 통계 조회") {
        url = "/use/stat";

        if (this.reqData.tbase != "") {
          param_json.tbase = this.reqData.tbase;
        };

        if (this.reqData.start != "") {
          param_json.start = this.reqData.start;
        };

        if (this.reqData.end != "") {
          param_json.end = this.reqData.end;
        };
      } else if (this.select.protocol === "공통코드 조회") {
        url = "/code/list";
      }

      let full_url = this.main_url + url;
      this.api_call(full_url, param_json);  
    },
    api_call(url, param_json) {
      var request = new XMLHttpRequest();
      request.open('POST', url);
      request.setRequestHeader('Content-Type', 'application/json');
      request.send(JSON.stringify(param_json));

      request.onload = function() {
        if (request.status === 200 || request.status === 201) {
          var result_text = {};
          result_text = request.response;
          var result = JSON.stringify(result_text, null, 2);
          this.result_text = request.response;
          //document.querySelector('pre').textContent = request.response;
        } else {
          console.log('error', request.status, request.statusText);
        };
      };
    },
  },
  async mounted() {
    //$("#gnb").hide();
    //$("#footer").hide();
  },
};
</script>

<style lang="scss" scoped>
.hide {
  display: none !important;
}
.show {
  display: inline-block !important;
}
.home-container {
  .main-bg {
    position: relative;
    &::before {
      content: "";
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      animation: 50s linear infinite alternate-reverse rotate;
    }
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }
  .tab_btn {
    width: 250px;
    color: white;
    border: solid 2px rgba(255, 242, 217, 0.3);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  .default_btn {
    border-radius: 0.625rem;
    font-weight: 700;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
}
</style>
