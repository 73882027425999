<template>
  <div>
    <b-row align-h="center" style="height: 100vh;" align-v="center">
      <b-col lg="8" class="bg_img"></b-col>
      <b-col lg="4" style="background-color: cornflowerblue; height: 100%; display: flex; align-items: center;">
        <b-container ref="temp">
          <b-form @submit.prevent.stop="login">
            <b-form-group>
              <b-form-input
                v-model="input.serviceId"
                placeholder="아이디"
              ></b-form-input>
            </b-form-group>
            <b-form-group>
              <b-form-input
                v-model="input.password"
                type="password"
                placeholder="비밀번호"
              ></b-form-input>
            </b-form-group>
            <!--
            <div class="d-flex align-items-center">
              <b-form-group class="ml-auto text-gray">
                <b-form-checkbox v-model="input.autoLogin" :value="true">
                  자동로그인
                </b-form-checkbox>
              </b-form-group>
            </div>
            -->

            <div class="mt-3">
              <b-btn variant="primary" class="w-100" type="submit" size="lg">
                <span class="text-18">로그인</span>
              </b-btn>
            </div>
          </b-form>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import $ from 'jquery';
import router from '../router';

export default {
  data() {
    return {
      input: {
        loginType: "EMAIL",
        serviceId: "",
        password: "",
        autoLogin: false,
      },
      user_data: {},
      user_ip: "",
      naverUrl: null,
    };
  },
  methods: {
    call_iP() {
      let userIp = "";
		  let json_data = fetch('https://ipapi.co/json/')
		  .then((response) => {
		  	//var json_data = {};
		  	//json_data = response.json();
			  //user_ip = json_data.ip;
		    return response.json();
		  })
		  .then((data) => {
		  	userIp = data.ip;			// 회원IP 변수에 조회한 접속IP 주소 저장
		    console.log(data);
        this.user_ip = data.ip;
		  });
	  },
    async login() {
      if (!this.input.serviceId.length) {
        if (!this.input.password.length) {
          window.alert("필수값들을 모두 입력해주세요.", {
            title: "필수 값 미입력",
          });
        }
      } else {
        //router.push({ path: "/dashboard" });
        try {
          const { data } = await this.$axios.post(
            "/api/login", {
              user_id : this.input.serviceId,
              password : this.input.password,
              use_yn : "Y"
            }
          );

          if (data.length < 1 ) {
            window.alert("입력하신 아이디 혹은 비밀번호가 일치하지 않습니다.", {
              title: "계정 오류",
            });
          } else if (data.length == 1) {
            const loginData = {
              //token: `${data.data.token_type} ${data.data.access_token}`,
              //autoLogin: this.input.autoLogin,
              userData: data[0]
            };

            this.user_data = data[0];

            this.$store.dispatch("setUserData", loginData);

            console.log(this.$store.getters.user);

            let crt_date = this.getToday();		// 현재시간

            var is_mobile = this.isMobile();
            var appName =  navigator.appName;			// 브라우저 이름
            var appCodeName = navigator.appCodeName;	// 브라우저 코드명
            var platform = navigator.platform;			// 플랫폼 정보
            var userAgent = navigator.userAgent;		// 사용자 정보
            var appVersion = navigator.appVersion;		// 브라우저 버전

            if (this.user_data.type == "U" || this.user_data.type == "C") {			// 회원 종류가 개인, 법인 회원인 경우
              this.addUserConnLog(userAgent, crt_date);			// 회원 접속 로그 저장
            } else if (this.user_data.type == "A" || this.user_data.type == "S") {	// 회원 종류가 관리자, CS담당자인 경우
              this.addAdminConnLog(userAgent, crt_date);			// 관리자 접속 로그 저장
            };

            this.$router.replace({name:"Dashboard"});
          }
        } catch (error) {
          // console.log(7);
          console.log(error);
        }
      }
    },
    isMobile() {
      var user = navigator.userAgent;
	    var is_mobile = false;

	    if( user.indexOf("iPhone") > -1
	        || user.indexOf("Android") > -1
	        || user.indexOf("iPad") > -1
	        || user.indexOf("iPod") > -1
	    ) {
	        is_mobile = true;
	    }
	    return is_mobile;
    },
    async addUserConnLog(browser_info, crt_date) {
      try {
        const { data } = await this.$axios.post(
          "/api/addUserConnLog", {
            user_id: this.user_data.user_id,
				   	type: this.user_data.type,
						conn_ip: this.user_ip,
						browser_info: browser_info,
						conn_date: crt_date
          }
        );
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async addAdminConnLog(browser_info, crt_date) {
      try {
        const { data } = await this.$axios.post(
          "/api/addAdminConnLog", {
            user_id: this.user_data.user_id,
				   	type: this.user_data.type,
						conn_ip: this.user_ip,
						browser_info: browser_info,
						conn_date: crt_date
          }
        );
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
  },
  mounted() {
    $("#gnb").hide();
    $("#footer").hide();
    this.call_iP();
  },
};
</script>

<style lang="scss" scoped>
.bg_img {
  background-image: url("../assets/images/loginimg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  animation: 50s linear infinite alternate-reverse rotate;
  height: 100%;
}
</style>