<template>
  <b-modal
    id="modal-card-select"
    title="회원 카드 등록"
    ok-title="등록"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="lg"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxWidth: '800px', maxHeight: '1000px' }"
    >
      <div>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />회원 ID
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" v-model="cardData.user_id"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />카드번호
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select size="sm" v-model="cardData.card_no" :options="cardListOptions" @change="changeCardNo($event)"></b-form-select>
          </div>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  components: {

  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      card_data: Object,
    },
    value: {
      type: Object,
    },
  },
  data() {
    return {
      admin_data: {},
      cardData: {
        user_id: "",
        card_no: ""
      },
      cardListData: [],
      cardListOptions: [],
      rules: {
        address: [
          (v) =>
            !!v || {
              title: "주소 입력",
              message: "주소를 입력하세요.",
            },
        ],
        // addressDetail: [
        //   (v) =>
        //     !!v || {
        //       title: "상세 주소 입력",
        //       message: "상세 주소를 입력하세요.",
        //     },
        // ],
      },
    };
  },
  mounted() {
    if (this.$store.getters.user === null) {
      this.admin_data.user_id = "admin";
    } else {
      this.admin_data = this.$store.getters.user;
    }

    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.selectCardList();
      });
    },
    async selectCardList() {debugger;
      try {
        const { data } = await this.$axios.post(
          "/api/selectCardList", {
            status : "nomatch",
          }
        );

        if (data.length > 0) {
          this.cardListData = data;

          let options = [];

          this.cardListData.forEach((e,i) => {
            let add_option = { value: e.card_no, text: e.card_no, type: e.type }
            options.push(add_option);
          });

          this.cardListOptions = options;

          this.cardData.card_no = this.cardListOptions[0].value;
          this.cardData.type = this.cardListOptions[0].type;
        } else {
          console.log(error);  
        }
      } catch (error) {
        console.log(error);
      }
    },
    async ok() {
      try {
        this.addUserCardMatch();
      }  catch (error) {
        console.log(error);
      }
    },
    async addUserCardMatch() {debugger;
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addUserCardMatch", {
            card_no : this.cardData.card_no,
						user_id : this.cardData.user_id,
						status : "match",
						type : this.cardData.type,
						card_stop_yn : "N",
						reg_date : crt_date,
						reg_user : this.admin_data.user_id
          }
        );

        this.updateCardListMatch();
      } catch (error) {
        console.log(error);
      }
    },
    async updateCardListMatch() {debugger;
      try {
        const { data } = await this.$axios.post(
          "/api/updateCardListMatch", {
            card_no : this.cardData.card_no,
            user_id : this.cardData.user_id
          }
        );

        window.alert("회원카드 등록이 완료되었습니다.");
        var context = "회원ID : " + this.cardData.user_id + ", 카드No : " + this.cardData.card_no;
        this.addAdminLog("회원카드 발급관리", "회원 매칭", "회원카드 매칭팝업", context, this.admin_data.user_id);
        this.$emit("reflash");
        this.$bvModal.hide("modal-card-select");
      } catch (error) {
        // console.log(7);
        window.alert("회원카드 등록을 실패했습니다.");
        console.log(error);
      }
    },
    setData(select_rowData) {debugger;
      let crt_date = this.getToday();

			this.cardData.user_id = select_rowData.user_id;
			this.cardData.status = "match";
			this.cardData.card_stop_yn = "N";
			this.cardData.reg_date = crt_date;
			this.cardData.reg_user = this.admin_data.user_id;

      this.cardData.card_no = this.cardListOptions[0].value;
      this.cardData.type = this.cardListOptions[0].type;
    },
    changeCardNo(select_index) {
      this.cardData.card_no = this.cardListOptions[select_index].value;
      this.cardData.type = this.cardListOptions[select_index].type;
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("관리자 명령 로그 등록을 실패했습니다.");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
