<template>
  <b-modal
    id="modal-user-payment"
    title="결제카드 정보"
    ok-title="등록"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="px-4 pt-4 pb-0 border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="false"
    size="lg"
    @shown="init"
  >
    <div class="pr-md-2 overflow-auto">
      <div class="mt-1">
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />카드번호
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input placeholder="1111-2222-3333-4444" type=number style="height: 50px; border-radius: 10px;" v-model="userPaymentData.card_no"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="d-flex" style="gap: 10px;">
            <div class="d-flex" style="flex: 1;">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />CVC
                </span>
              </div>
              <div class="modal-input-content">
                <b-form-input placeholder="000" type=number style="height: 50px; border-radius: 10px;" v-model="userPaymentData.card_cvc"></b-form-input>
              </div>
            </div>
            <div class="d-flex" style="flex: 1;">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />비밀번호
                </span>
              </div>
              <div class="modal-input-content">
                <b-form-input placeholder="00" type=number style="height: 50px; border-radius: 10px;" v-model="userPaymentData.card_pw"></b-form-input>
                <span>**</span>
              </div>
            </div>
            <div class="d-flex" style="flex: 1;">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />유효기간
                </span>
              </div>
              <div class="modal-input-content">
                <b-form-input placeholder="00" type=number style="height: 50px; border-radius: 10px;" v-model="mm_text"></b-form-input>
                <span>/</span>
                <b-form-input placeholder="00" type=number style="height: 50px; border-radius: 10px;" v-model="yy_text"></b-form-input>
              </div>
            </div>
          </div>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  components: {
    
  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    }
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      edit_type: String,
      payment_data: Object,
      car_data: Object
    },
  },
  data() {
    return {
      admin_data: {},
      userPaymentData: {},
      mm_text: "",
      yy_text: ""
    };
  },
  mounted() {
    if (this.$store.getters.user === null) {
      this.admin_data.user_id = "admin";
    } else {
      this.admin_data = this.$store.getters.user;
    }

    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.userPaymentData = this.obj.payment_data;
      });
    },
    ok() {
      let expiry_date = this.mm_text + this.yy_text;
      this.$emit("input", this.userPaymentData.card_no, this.userPaymentData.card_cvc, this.userPaymentData.card_pw, expiry_date);
      this.$bvModal.hide("modal-user-payment");
    },
    setData(select_rowData) {
      this.userPaymentData = select_rowData;
    }
  },
};
</script>

<style lang="scss" scoped></style>
