<template>
  <b-modal
    id="modal-nomatch-card"
    title="카드번호 선택"
    ok-title="선택"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="false"
    size="xl"
    ref="element"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxWidth: '1100px', maxHeight: '1000px' }"
    >
      <div>
        <b-row style="margin: 0px 0px 10px 0px;">
          <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
            <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">검색조건</span>
            <div class="d-flex align-items-center" style="gap: 10px; flex: 4; padding: 5px;">
              <b-form-select size="sm" style="flex: 1;" v-model="filterData.searchKeyType" @change="changeSearchKey" :options="searchKeyOption"></b-form-select>
              <b-form-input 
                size="sm"
                v-model="filterData.searchKey"
                style="flex: 3; height: 50px; border-radius: 10px;"
                @change="changeSearchKey"
              ></b-form-input>
            </div>
          </b-col>
          <b-col lg=6>
            
          </b-col>
        </b-row>
        <b-row style="margin: 0px 0px 10px 0px;">
          <b-col lg=6 class="d-flex justify-content-between flex-row align-items-center">
            <span>전체 {{ gridFilterData.length }} 건</span>
          </b-col>
          <b-col lg=6 class="d-flex justify-content-end">
            
          </b-col>
        </b-row>
        <b-row style="width: inherit; margin: 0px;" :style="{'height': page_grid_height + 'px'}">
          <div class="table-wrapper2" style="width: -webkit-fill-available;" >
            <b-table 
              id = "card_grid"
              striped hover 
              :items="gData"
              :fields="gridFields"
              small
            >
              <template #cell(No)="row">
                <div class="d-flex" style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                  {{ row.index + 1 + ((currentPage - 1) * perPage) }}
                </div>
              </template>
              <template #cell(card_no)="row">
                <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                  {{ gData[row.index].card_no }}
                </div>
              </template>
              <template #cell(type)="row">
                <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                  {{ gData[row.index].type }}
                </div>
              </template>
              <template #cell(status)="row">
                <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                  {{ gData[row.index].status }}
                </div>
              </template>
              <template #cell(user_id)="row">
                <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                  {{ gData[row.index].user_id }}
                </div>
              </template>
              <template #cell(reg_user)="row">
                <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                  {{ gData[row.index].reg_user }}
                </div>
              </template>
              <template #cell(reg_date)="row">
                <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                  {{ gData[row.index].reg_date }}
                </div>
              </template>
            </b-table>
          </div>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <b-pagination
            v-model="currentPage"
            :total-rows="gridFilterData.length"
            :per-page="perPage"
            first-text="⏮"
            prev-text="⏪"
            next-text="⏩"
            last-text="⏭"
            class="mt-4"
            size="sm"
          ></b-pagination>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  components: {
    
  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      
    },
  },
  data() {
    return {
      crt_width: 0,
      crt_height: 0,
      page_grid_height: 0,
      perPage: 1,
      currentPage: 1,
      gridDefaultData: [],
      gridFilterData: [],
      gData: [],
      gridFields: [
        {
          key: "No",
          label: "No"
        },
        {
          key: "card_no",
          label: "카드 번호"
        },
        {
          key: "type",
          label: "카드 종류"
        },
        {
          key: "status",
          label: "상태"
        },
        {
          key: "user_id",
          label: "회원ID"
        },
        {
          key: "reg_user",
          label: "등록자"
        },
        {
          key: "reg_date",
          label: "등록일자"
        },
      ],
      filterData: {
        searchKeyType: "all",
        searchKey: ""
      },
      searchKeyOption: [
        { text: "전체", value: "all" },
        { text: "카드 번호", value: "card_no" }
      ],
      select_index: -1,
      select_rowData: {},
      rules: {
        address: [
          (v) =>
            !!v || {
              title: "주소 입력",
              message: "주소를 입력하세요.",
            },
        ],
        // addressDetail: [
        //   (v) =>
        //     !!v || {
        //       title: "상세 주소 입력",
        //       message: "상세 주소를 입력하세요.",
        //     },
        // ],
      },
    };
  },
  mounted() {
    this.crt_width = 1100;
    this.crt_height = 600;

    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.selectCardList();
      });
    },
    async selectCardList() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectCardList", {
            status : "nomatch"
          }
        );
        if (data.length > 0) {
          this.gData = data;
          this.gridDefaultData = data;
          this.changeSearchKey();
        } else if (data.length == 0) {
          this.gData = null;
          console.log("데이터가 없습니다.");
        }

        this.pageSet();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    changeSearchKey() {
      let search_key = this.filterData.searchKey;
      let search_type = this.filterData.searchKeyType;
      let temp_data = [];

      //this.gridDefaultData.

      if (search_key != "") {
        if (search_type === "all") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.card_no.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "card_no") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.card_no.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        }

        this.gridFilterData = temp_data;
      } else {
        this.gridFilterData = this.gridDefaultData;
      }

      this.pageSet();
    },
    selectRow(row_index) {
      var tbody = document.querySelector("#card_grid");
      var trs = tbody.getElementsByTagName('tr');
      
      var selectIndex = this.select_index + 1;

      if (this.select_index == -1) {
        this.select_index = row_index;
        this.select_rowData = this.gData[row_index];

        trs[row_index + 1].style.backgroundColor = "yellow";
      } else if (this.select_index > -1) {
        if (this.select_index === row_index) {
          this.select_index = -1;
          let default_row_data = {
            reader_id : "",
            type : "",
            sn_id : "",
            t_id : "",
            ver : "",
            l_voltage : "",
            fw_ver : "",
            t_cycle : "",
            c_cycle : "",
            status : "",
            status_name : "",
            reg_user : "",
            reg_date : "",
            type_name : "",
            t_id_name : "",
            iccid : "",
            imsi : "",
            mid : "",
            terminal_id : "",
          }
          this.select_rowData = default_row_data;

          trs[selectIndex].style.backgroundColor = "white";
        } else {
          this.select_index = row_index;
          this.select_rowData = this.gData[row_index];

          trs[selectIndex].style.backgroundColor = "white";
          trs[row_index + 1].style.backgroundColor = "yellow";
        }
      }
    },
    onResize(e) {
      if (this.crt_width == 0 & this.crt_height == 0) {
        this.crt_width = e.detail.width;
        this.crt_height = e.detail.height;
        
        //this.pageSet();
      } else if (this.crt_width != e.detail.width || this.crt_height != e.detail.height) {
        this.crt_width = e.detail.width;
        this.crt_height = e.detail.height;
        
        this.pageSet();  
      }
    },
    pageSet() {
      var filter_height = 60;
      var button_height = 60;
      var pagination_height = 50;
      var grid_header_height = 60;
      var grid_row_height = 40;
      var grid_data_height = this.crt_height - filter_height - button_height - pagination_height - grid_header_height - 40;
      this.page_grid_height = grid_data_height;

      //var page_row_count = Math.floor(grid_data_height / grid_row_height);
      var page_row_count = 5;
      var max_page_num = Math.ceil(this.gridFilterData.length / page_row_count);

      this.perPage = page_row_count;

      this.currentPage = 1;
      this.selectPage(1);
    },
    selectPage(page) {
      let temp_grid_data = this.gridFilterData;

      this.gData = temp_grid_data.slice(
        (page - 1) * this.perPage, page * this.perPage
      )
    },
    async ok() {
      if (this.select_index === -1) {
        window.alert("원하는 카드를 선택 후 다시 시도해 주시기 바랍니다.");
      } else {
        this.$emit("input", this.select_rowData);
        this.$bvModal.hide("modal-nomatch-card");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
