<template>
  <b-modal
    id="modal-ocpp-conn-test"
    title="OCPP 접속 테스트"
    ok-title="닫기"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="primary m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="px-4 pt-4 pb-0 border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="primary text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="false"
    size="lg"
    @shown="init"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxWidth: '800px', maxHeight: '1000px' }"
    >
      <div>
        <button @click="disconnect" v-if="status === 'connected'">연결 끊기</button>
        <button @click="connect" v-if="status === 'disconnected'">연결</button> {{ status }}
        <br /><br />
        <div v-if="status === 'connected'">
          <form @submit.prevent="sendMessage" action="#">
            <input v-model="message"><button type="submit">메세지 전송</button>
          </form>
          <ul id="logs">
            <li v-for="log in logs" class="log" v-bind:key="log">
              {{ log.event }}: {{ log.data }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  components: {
    
  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    
  },
  data() {
    return {
      message: "",
      logs: [],
      status: "disconnected"
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
      });
    },
    connect() {debugger;
      let user_id = "druscoev";
      let password = "vusflxptmxm";
      let auth_pw = window.btoa(encodeURIComponent(password));
      let auth_str = user_id + ":" + auth_pw;
      let auth_str2 = user_id + ":" + password;
      let encode_auth = window.btoa(encodeURIComponent(auth_str2));
      let opt = { 
        headers: {
          Accept: "application/json",
          Upgrade: "websocket",
          Connection: "Upgrade",
          //Sec-WebSocket-Protocol: "ocpp1.6",
          Authorization: "Basic " + auth_str
        } 
      }
      
      this.socket = new WebSocket("ws://localhost:8804", ['ocpp1.6'], {
        headers: { Authorization: 'Basic ' + auth_str }
      });

      this.socket.onopen = () => {
        this.status = "connected";
        this.logs.push({ event: "연결 완료: ", data: 'ws://localhost:8804'})

        this.socket.onmessage = ({data}) => {
          this.logs.push({ event: "메세지 수신", data });
        };
      };
    },
    disconnect() {
      this.socket.close();
      this.status = "disconnected";
      this.logs = [];
    },
    sendMessage(e) {
      this.socket.send(this.message);
      this.logs.push({ event: "메시지 전송", data: this.message });
      this.message = "";
    },
    async ok() {
      this.$bvModal.hide("modal-ocpp-conn-test");
    },
  },
};
</script>

<style lang="scss" scoped></style>
