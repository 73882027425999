<template>
  <div class= "d-flex" style="width:100%; height: 100%; flex-direction: column; align-items: center; border-right: 1px solid #000;">
    <div style="width: 100%; height: 200px; padding: 0 30px; border-bottom: 1px solid #000; display: flex; flex-direction: column; justify-content: space-evenly;">
      <div class="d-flex" style="gap: 25px; padding: 10px 30px 10px 40px; font-size: 30px; font-weight: 700; color: #2571B0;">
        <!--
        <img
            src="@/assets/logo.svg"
            alt="durusco 로고"
            style="width: 80px; height: 70px;"
        />
      -->
        <div style="font-size: 30px; font-weight: 700; color: #2571B0;">
          <span>Durusco EV</span>
        </div>
      </div>
      <div class="d-flex" style="height: 30px; padding-left: 40px;">
        <div class="d-flex align-items-center" style="flex: 4; gap: 10px;">
          <span style="font-size: 16px; font-weight: 700;">{{ this.user_data.name }}</span>
          <span style="font-size: 16px; font-weight: 700;">관리자님 접속중</span>
        </div>
        <div class="d-flex justify-content-end" style="flex: 1;">
          <b-button class="d-flex justify-content-center align-items-center" style="background-color: #3279d3; border-radius: 10px; color: #fff; font-weight: bold; flex: 1; padding: 5px; width: 75px;" @click="logout()">로그아웃</b-button>          
        </div>
      </div>
    </div>
    <div style="width: -webkit-fill-available; overflow: auto;" >
      <section>
        <div v-for="(item, i) in content" :key="i">
          <b-btn
            variant="outline-light-gray bg-transparent w-100 py-3 rounded-0 text-darkest d-flex align-items-center"
            v-b-toggle="`collapse-building-${i}`"
            @click="clickFirstMenu(i)"
          >
            <span class="text-13 text-lg-15">{{ item.category }}</span>
            <i
              class="icon icon-16 icon-caret-collapse ml-auto"
              :class="{ active: current === i }"
            />
          </b-btn>
          <b-collapse
            :id="`collapse-building-${i}`"
            class="bg-gray-fa px-4"
            accordion="collapse-building"
            ref="firstMenu"
          >
            <div class="py-3">
              <ul class="list-unstyled m-0 p-0">
                <li
                  class="d-flex align-items-center justify-content-between"
                  style="height: 40px;"
                  v-for="(item2, j) in item.list"
                  :id="`secondMenu-${i}-${j}`"
                  :key="j"
                  :class="{menu_btn : j !== item.list.length - 1}"
                  @click="clickSecondMenu(item2.value, i, j)"
                >
                  <span
                    class=""
                    style="margin-left: 5px;"
                  >
                    {{ item2.key }}
                  </span>
                </li>
              </ul>
            </div>
          </b-collapse>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import router from '../../router';

export default {
  computed: {
    meta() {
      return this.$route.meta;
    },
  },
  data() {
    return {
      user_data: {
        id : 0,
        user_id : "",
        company_id : "",
        type : "",
        type_name : "",
        name : "",
        authority : "",
        phone : "",
        addr : "",
        addr_dtl : "",
        email : "",
        user_card_no : "",
        card_stop_yn : "",
        reg_date : "",
        update_date : "",
        use_yn : "",
        payment_info_yn : "",
        car_info_yn : "",
        level : 0,
        sex : "",
        birth : "",
        eco_yn : "",
        user_card_status : "",
        mc_code : "",
        mc_name : "",
        car_code : "",
        car_name : "",
        c_position : "",
        laf : "",
        card_code : "",
        card_name : "",
        payment_card_no : "",
        cvc : "",
        password : "",
        billing_key : "",
        customer_key : "",
        company_name : "",
        company_type : "",
      },
      current: null,
      currentSub: null,
      select_menu_id: {
        i: 0,
        j: 0
      },
      test : null,
      active:null,
      content: [
        {
          category: "Dashboard",
          list: [
            { key: "대시보드 바로가기", value: "/dashboard/dashboard", selected: "Y" },
          ],
        },
        {
          category: "충전소/충전기 관리",
          list: [
            { key: "충전소 관리", value: "/charger/station_list", selected: "N" },
            { key: "충전기 관리", value: "/charger/charger_list", selected: "N" },
            { key: "충전기 상태정보", value: "/charger/charger_status", selected: "N" },
            { key: "사용현황", value: "/charger/charge_use_status", selected: "N" },
            { key: "단말기 관리", value: "/charger/reader_list", selected: "N" },
            { key: "충전기 모델 관리", value: "/charger/charger_model_list", selected: "N" },
          ],
        },
        {
          category: "회원관리",
          list: [
            { key: "회원 목록", value: "/user/user_list", selected: "N" },
            { key: "법인 목록", value: "/user/company_list", selected: "N" },
            { key: "회원카드 관리", value: "/user/user_card_list", selected: "N" },
            { key: "회원카드 발급관리", value: "/user/user_card_issuance", selected: "N" },
            { key: "공카드 발급관리", value: "/user/user_card_noMatch", selected: "N" },
          ],
        },
        {
          category: "로밍 관리",
          list: [
            { key: "충전기 목록", value: "/roming/roming_charger_list", selected: "N" },
            { key: "회원카드 목록", value: "/roming/roming_card_list", selected: "N" },
            { key: "충전내역 관리", value: "/roming/roming_charging_history", selected: "N" },
            { key: "충전기 사용내역", value: "/roming/roming_payment_history", selected: "N" },
            { key: "로밍 테스트", value: "/roming/test", selected: "N" },
          ],
        },
        {
          category: "충전 관리",
          list: [
            { key: "충전 내역", value: "/payment/charging_history", selected: "N" },
            { key: "결제 내역", value: "/payment/payment_history", selected: "N" },
            { key: "정산 관리", value: "/payment/calculate", selected: "N" }
          ],
        },
        {
          category: "장애 관리",
          list: [
            { key: "충전기 파손신고", value: "/issue/damage_report", selected: "N" },
            { key: "충전기 정비관리", value: "/issue/maintenance", selected: "N" },
            { key: "점검 스케쥴 내역", value: "/issue/as_schedule", selected: "N" }
          ],
        },
        {
          category: "고객센터",
          list: [
            { key: "상담 내역", value: "/customer/consulting_list", selected: "N" },
            { key: "공지사항", value: "/customer/notice", selected: "N" },
            { key: "QnA 관리", value: "/customer/qna", selected: "N" },
            { key: "FAQ 관리", value: "/customer/faq", selected: "N" }
          ],
        },
        {
          category: "운영 관리",
          list: [
            {
              key: "약관 관리", value: "/operation/term", selected: "N"
            },
            {
              key: "메시지 관리", value: "/operation/message", selected: "N"
            },
          ],
        },
        {
          category: "관리자 관리",
          list: [
            {
              key: "메뉴 관리", value: "/admin/menu_set", selected: "N"
            },
            {
              key: "관리자 관리", value: "/admin/admin_list", selected: "N"
            },
            {
              key: "코드 관리", value: "/admin/code_set", selected: "N"
            },
            {
              key: "전력량 요금 관리", value: "/admin/charge_set", selected: "N"
            },
            {
              key: "펌웨어 관리", value: "/admin/fw_ver_set", selected: "N"
            },
            {
              key: "차량 관리", value: "/admin/car_info", selected: "N"
            },
            {
              key: "메시지 폼 관리", value: "/admin/message_form", selected: "N"
            },
            {
              key: "설정값 관리", value: "/admin/enc_set", selected: "N"
            },
            {
              key: "업체 관리", value: "/admin/admin_company_list", selected: "N"
            },
            {
              key: "펌웨어 업데이트 관리", value: "/admin/fw_update_history", selected: "N"
            },
          ],
        },
        {
          category: "로그 관리",
          list: [
            {
              key: "사용자 접속 로그", value: "/log/user_conn", selected: "N"
            },
            {
              key: "관리자 접속 로그", value: "/log/admin_conn", selected: "N"
            },
            {
              key: "관리자 명령 로그", value: "/log/admin_use_list", selected: "N"
            },
            {
              key: "OCPP 인증 로그", value: "/log/ocpp_auth", selected: "N"
            },
            {
              key: "OCPP 부팅 로그", value: "/log/ocpp_boot", selected: "N"
            },
            {
              key: "OCPP 메타벨류 로그", value: "/log/ocpp_meta_value", selected: "N"
            },
            {
              key: "OCPP 접속 로그", value: "/log/ocpp_conn", selected: "N"
            },
            {
              key: "OCPP 충전 로그", value: "/log/ocpp_tran", selected: "N"
            },
            {
              key: "OCPP 충전기 연결신호 로그", value: "/log/ocpp_heartbeat", selected: "N"
            },
            {
              key: "OCPP 충전기 상태 로그", value: "/log/ocpp_status", selected: "N"
            },
            {
              key: "OCPP 데이터 전송 로그", value: "/log/ocpp_data_tran", selected: "N"
            },
            {
              key: "OCPP 충전기 목록", value: "/log/ocpp_charger", selected: "N"
            },
            {
              key: "OCPP 충전 시작 로그", value: "/log/ocpp_start_tran", selected: "N"
            },
            {
              key: "OCPP 충전 정지 로그", value: "/log/ocpp_stop_tran", selected: "N"
            },
            {
              key: "OCPP 원격 충전 시작 로그", value: "/log/ocpp_rstart_tran", selected: "N"
            },
            {
              key: "OCPP 원격 충전 정지 로그", value: "/log/ocpp_rstop_tran", selected: "N"
            },
            {
              key: "OCPP 결제 NOTI 로그", value: "/log/ocpp_payment_noti", selected: "N"
            },
            {
              key: "펌웨어 업데이트 로그", value: "/log/fw_status", selected: "N"
            },
          ],
        },
        {
          category: "통계",
          list: [
            {
              key: "운영실적 월별 통계", value: "/statistics/operating_statistics", selected: "N"
            },
            {
              key: "충전기 이용 통계", value: "/statistics/cp_statistics", selected: "N"
            },
            {
              key: "충전소 이용 통계", value: "/statistics/station_statistics", selected: "N"
            },
          ],
        },
      ],
    }
  },
  watch: {
    $route(newValue) {
      this.toggle = false;
    },
  },
  methods: {
    clickFirstMenu(idx) {
      if (this.current !== null && this.current === idx) {
        //this.current = null;
        router.go(router.currentRoute);
      } else {
          this.current = idx;
          
          this.menu_highlight(idx, 0);
          let move_url = this.content[idx].list[0].value;
          router.push({ path: move_url })
            .catch(()=>{
              //router.go(router.currentRoute);

            });
      }
    },
    clickSecondMenu(select_url, i, j) {
      this.menu_highlight(i, j);
      //let page_path = "";
      router.push({ path: select_url })
        .catch(()=>{
          router.go(router.currentRoute);
        });
    },
    menu_highlight(i, j) {
      if (i !== null && j !== null) {
        let bef_menu_id = "#secondMenu-" + this.select_menu_id.i + "-" + this.select_menu_id.j;
        let bef_select_menu = document.querySelector(bef_menu_id);

        this.select_menu_id.i = i;
        this.select_menu_id.j = j;

        let menu_id = "#secondMenu-" + i + "-" + j;
        let select_menu = document.querySelector(menu_id);

        //bef_select_menu.style.cssText = "";
        //select_menu.style.cssText = "background-color: #3279d3; color: #fff; font-weight: bold; border-radius: 10px; box-shadow: 4px 4px 10px rgba(87, 70, 156, 0.3);";
        bef_select_menu.style.backgroundColor = "#fff";
        bef_select_menu.style.color = "#000";
        bef_select_menu.style.fontWeight = "400";
        bef_select_menu.style.borderRadius = "0px";
        bef_select_menu.style.boxShadow = "0px 0px 0px";

        select_menu.style.backgroundColor = "#3279d3";
        select_menu.style.color = "#fff";
        select_menu.style.fontWeight = "bold";
        select_menu.style.borderRadius = "5px";
        select_menu.style.boxShadow = "4px 4px 10px rgba(87, 70, 156, 0.3)";
      }
    },
  },
  async mounted() {
    let temp_userData = this.$store.getters.user;

    if (temp_userData !== null) {
      this.user_data = this.$store.getters.user;
    }

    let crtUrl = router.currentRoute;

    let temp_data = this.content;

    if (crtUrl.fullPath === "/") {
      this.current = 0;
      this.currentSub = 0;
      this.$refs.firstMenu[0].show = true;
    } else {
      for (let i=0; i < temp_data.length; i++) {
        let temp_subData = temp_data[i].list;

        for (let j=0; j < temp_subData.length; j++) {
          if (temp_subData[j].value === crtUrl.fullPath) {
            this.current = i;
            this.currentSub = j;
            this.$refs.firstMenu[i].show = true;
          };
        }
      }
    }

    this.menu_highlight(this.current, this.currentSub);

    // 메뉴 버튼에 하이라이트 필요함 (j가 서브메뉴 인덱스)
    console.log(this.$refs.firstMenu);
  },
};
</script>

<style lang="scss" scoped>
.headerMenu {
  .nav-item {
    position: relative;
    &::after {
      content: "";
      width: 0%;
      height: 4px;
      position: absolute;
      background: #1a49b9;
      bottom: 5px;
      transition: all 0.2s ease-in-out;
    }
    &:hover,
    &.active {
      &::after {
        width: 100%;
      }
    }
    .nav-link {
      display: block;
      height: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
  .default_btn {
    border-radius: 0.625rem;
    font-weight: 700;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  .menu_btn {
    border-color: #a5a5a5 !important;
    border-bottom: 1px solid #dee2e6 !important;
    margin-bottom: .5rem !important;
  }
  .select_btn {
    background-color: #3279d3;
    color: #fff;
    font-weight: bold;
    border-radius: 10px;
    box-shadow: 4px 4px 10px rgba(87, 70, 156, 0.3);
  }
}
</style>
