<template>
  <b-modal
    id="modal-station-detail"
    :title="obj.title"
    :ok-title="obj.ok_title"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="false"
    size="lg"
    @shown="init"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxWidth: '800px', maxHeight: '1000px' }"
    >
      <div>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />충전소 ID
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" type=number v-model="stationData.sid"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />충전소 이름
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="stationData.name"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row" style="height: 115px;">
          <div class="modal-input-title" style="height: 105px;">
            <span class="text-17">
              <font-awesome-icon />충전소 위치
            </span>
          </div>
          <div class="modal-input-content flex-column" style="gap: 5px;">
            <div class="d-flex" style="gap: 10px; width: 100%;">
              <b-form-input readonly style="height: 50px; border-radius: 10px;" v-model="stationData.addr"></b-form-input>
              <b-button class="btn_def" style="width: 106px;" @click="getPostCode">검색</b-button>
            </div>
            <div class="d-flex" style="gap: 10px; width: 100%;">
              <b-form-input style="height: 50px; border-radius: 10px;" v-model="stationData.addr_dtl"></b-form-input>
              <b-button class="btn_def" style="width: 100px;" @click="$emit('call', stationData.addr, stationData.addr_dtl)">지도보기</b-button>
            </div>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />충전소 구분
            </span>
          </div>
          <div class="modal-input-content" style="gap: 10px;">
            <b-form-select size="sm" v-model="stationData.kind" :options="kindOptions" @change="changeKind($event)"></b-form-select>
            <b-form-select size="sm" v-model="stationData.kind_dtl" :options="kindDtlOptions"></b-form-select>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />이용가능 시간
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select size="sm" v-model="stationData.use_time" :options="useTimeOptions"></b-form-select>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />운영기관
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select size="sm" v-model="stationData.bid" :options="bnameOptions" @change="changeBid($event)"></b-form-select>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />사업자등록번호
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="bis_value" @change="changeBis($event)"></b-form-input>
          </div>
        </b-row>
        <b-row style="gap: 10px; display: flex; margin: 0px 0px 10px 0px; height: 65px;">
          <div  class="modal-input-row" style="flex: 1;">
            <div class="modal-input-title">
              <span class="text-17">
                <font-awesome-icon />예약
              </span>
            </div>
            <div class="d-flex align-items-center" style="flex: 2; padding: 0px 5px;">
              <b-form-select size="sm" v-model="stationData.reserv_yn" :options="reservOptions"></b-form-select>
            </div>
          </div>
          <div  class="modal-input-row" style="flex: 1;">
            <div class="modal-input-title">
              <span class="text-17">
                <font-awesome-icon />이용제한
              </span>
            </div>
            <div class="d-flex align-items-center" style="flex: 2; padding: 0px 5px;">
              <b-form-select size="sm" v-model="stationData.limit_yn" :options="limitOptions"></b-form-select>
            </div>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />주차비
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="stationData.free_dtl"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />충전요금
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select size="sm" v-model="stationData.fee_id" :options="feeOptions"></b-form-select>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />GPS 좌표
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" v-model="gps_text"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title" style="gap: 10px;">
            <span class="text-17">
              <font-awesome-icon />로밍용 충전소ID
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="flex: 1; height: 50px; border-radius: 10px;" v-model="stationData.r_sid"></b-form-input>
            <div class="d-flex" style="width: 165px;">
              <switch-button :checked="isReStatusYn" @switch="reStatusYn" label="상태갱신"/>
            </div>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />특이사항
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="stationData.note"></b-form-input>
          </div>
        </b-row>
      </div>
    </div>
    <div class="pt-3">
      <div ref="addr" />
    </div>
  </b-modal>
</template>

<script>
import KakaoMap from "@/components/KakaoMap.vue";
import SwitchButton from "@/components/SwitchButton.vue";

export default {
  components: {
    KakaoMap,
    SwitchButton,
  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      edit_type: String,
      title: String,
      ok_title: String,
      station_data: Object,
    },
    value: {
      type: Object,
    },
  },
  data() {
    return {
      admin_data: {},
      isReStatusYn: false,
      gps_text: "",
      bis_value: "",
      stationData: [],
      companyData: [],
      kindListData: [],
      kindDtlListData: [],
      kindOptions: [],
      kindDtlOptions: [],
      useTimeOptions: [
        { value: "24", text: "24시간 이용가능" },
        { value: "12", text: "12시간 이용가능" }
      ],
      bnameOptions: [],
      reservOptions: [
        { value: "Y", text: "가능" },
        { value: "N", text: "불가능" }
      ],
      limitOptions: [
        { value: "Y", text: "전체이용" },
        { value: "N", text: "거주자외 출입제한" }
      ],
      feeListData: [],
      feeOptions: [],
      addressObj: {},
      wrap: null,
      rules: {
        address: [
          (v) =>
            !!v || {
              title: "주소 입력",
              message: "주소를 입력하세요.",
            },
        ],
        // addressDetail: [
        //   (v) =>
        //     !!v || {
        //       title: "상세 주소 입력",
        //       message: "상세 주소를 입력하세요.",
        //     },
        // ],
      },
    };
  },
  mounted() {
    if (this.$store.getters.user === null) {
      this.admin_data.user_id = "admin";
    } else {
      this.admin_data = this.$store.getters.user;
    }
    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.selsctkindList();
        this.wrap = this.$refs.addr;

        if (this.obj.edit_type === "add") {
          this.stationData = this.obj.station_data;
        } else {
          let gps_str = this.stationData.gps;
          let gps_arr = this.stationData.gps.split(",");
          this.gps_text = "X : " + gps_arr[0] + ", Y : " + gps_arr[1];

          if (this.stationData.status_send_yn === "Y") {
            this.isReStatusYn = true;
          } else if (this.stationData.status_send_yn === "N") {
            this.isReStatusYn = false;
          }

          this.bis_value = this.stationData.bis_id.substring(0,3) + "-" + this.stationData.bis_id.substring(3,5) + "-" + this.stationData.bis_id.substring(5,10);
        }
      });
    },
    async selsctkindList() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectCode", {
            use_yn: "Y", 
            type: "STC"
          }
        );
        if (data.length > 0) {
          this.kindListData = data.filter(
            function (e) {
              if (e.level == 1) {
                return true;
              }
            }
          )

          this.kindDtlListData = data.filter(
            function (e) {
              if (e.level == 2) {
                return true;
              }
            }
          )

          let options = [];

          this.kindListData.forEach((e,i) => {
            let add_option = { value: e.code, text: e.code_name }
            options.push(add_option);
          });

          this.kindOptions = options;

          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            this.stationData.kind = this.kindOptions[0].value;
          }
        } else if (data.length == 0) {
          this.kindOptions = null;
          this.kindDtlOptions = null;
        }

        this.selectCompanyList();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async selectCompanyList() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectCompanyList", {
            type : "S",
            use_yn : "Y"
          }
        );

        this.companyData = data;

        if (data.length > 0) {
          let options = [];

          data.forEach((e,i) => {
            let add_option = { value: e.company_id, text: e.name }
            options.push(add_option);
          });

          this.bnameOptions = options;

          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            this.stationData.bid = this.bnameOptions[0].value;
          }
        } else if (data.length == 0) {
          this.bnameOptions = null;
        }

        this.selectFeeList();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async selectFeeList() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectFeeList", {
            use_yn : "Y"
          }
        );
        if (data.length > 0) {
          this.feeListData = data.filter(
            (e, i, callback) =>
              i === callback.findIndex(
                (e1) => e1.cf_id === e.cf_id
              )
          );
        
          let options = [];

          let select_bid = "";

          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            select_bid = this.bnameOptions[0].value;
          } else {
            select_bid = this.stationData.bid;
          }

          this.feeListData.forEach((e,i) => {
            if (e.bid === select_bid) {
              let add_option = { value: e.cf_id, text: e.cf_title }
              options.push(add_option);
            }
          });

          this.feeOptions = options;

          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            this.stationData.fee_id = this.feeOptions[0].value;
          }
        } else if (data.length == 0) {
          this.feeOptions = null;
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async ok() {
      try {
        var type_url = "";

        if (this.obj.edit_type === "detail") {
          this.$bvModal.hide("modal-station-detail");
        } else {
          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            this.selectStationId();
          } else if (this.obj.edit_type === "edit") {
            this.updateStation();
          } else if (this.obj.edit_type === "del") {
            this.delStation();
          }
        }
        //this.$root.$emit("hb::pdf::hidden", true);
      }  catch (error) {
        console.log(error);
      }
    },
    async addStation() {
      try {
        var crt_date = this.getToday();
        var crt_year = crt_date.substring(0,8);

        let select_companyData = [];
        
        this.companyData.forEach((e,i) => {
          if (e.company_id === this.stationData.bid) {
            select_companyData.push(e);
          }
        });

        let free_text = "N";
        if (this.stationData.free_dtl === "") {
          free_text = "N";
        } else {
          free_text = "Y";
        }

        let limit_dtl = "";
        if (this.stationData.limit_yn === "N") {
          limit_dtl = "거주자외 출입제한"
        } else if (this.stationData.limit_yn === "Y") {
          limit_dtl = "전체이용";
        } 

        const { data } = await this.$axios.post(
          "/api/addStation", {
            sid: this.stationData.sid,
						bid: this.companyData[0].company_id,
						zcode: this.stationData.zcode,
						zscode: this.stationData.zscode,
						zsgcode: this.stationData.zsgcode,
						name: this.stationData.name,
						addr: this.stationData.addr,
						addr_dtl: this.stationData.addr_dtl,
						daddr: "",
						daddr_dtl: "",
						kind: this.stationData.kind,
						kind_dtl: this.stationData.kind_dtl,
						gps: this.stationData.gps,
						use_time: this.stationData.use_time,
						free: free_text,
						free_dtl: this.stationData.free_dtl,
						bname: this.companyData[0].name,
						bcall: this.companyData[0].phone,
						reserv_yn: this.stationData.reserv_yn,
						note: this.stationData.note,
						year: crt_year,
						limit_yn: this.stationData.limit_yn,
						limit_dtl: limit_dtl,
						del_yn: "N",
						del_dtl: "",
						reg_date: crt_date,
						update_date: "",
						reg_id: this.admin_data.user_id,
						update_id: "",
						fee_id: this.stationData.fee_id,
						bis_id: this.stationData.bis_id,
            r_sid: this.stationData.r_sid,
					  status_send_yn: this.stationData.status_send_yn
          }
        );

        window.alert("충전소 정보가 등록되었습니다.");
        var context = "충전소ID : " + this.stationData.sid;
        this.addAdminLog("충전소 관리", "충전소 등록", "충전소 등록팝업", context, this.admin_data.user_id);
        this.$emit("reflash");

        this.$bvModal.hide("modal-station-detail");
      } catch (error) {
        window.alert("충전소 등록을 실패했습니다.");
      }
    },
    async updateStation() {
      try {
        var crt_date = this.getToday();

        let free_text = "N";
        if (this.stationData.free_dtl === "") {
          free_text = "N";
        } else {
          free_text = "Y";
        }

        let limit_dtl = "";
        if (this.stationData.limit_yn === "N") {
          limit_dtl = "거주자외 출입제한"
        } else if (this.stationData.limit_yn === "Y") {
          limit_dtl = "전체이용";
        }

        const { data } = await this.$axios.post(
          "/api/updateStation", {
            sid: this.stationData.sid,
						bid: this.stationData.bid,
						zcode: this.stationData.zcode,
						zscode: this.stationData.zscode,
						zsgcode: this.stationData.zsgcode,
						name: this.stationData.name,
						addr: this.stationData.addr,
						addr_dtl: this.stationData.addr_dtl,
						daddr: "",
						daddr_dtl: "",
						kind: this.stationData.kind,
						kind_dtl: this.stationData.kind_dtl,
						gps: this.stationData.gps,
						use_time: this.stationData.use_time,
						free: free_text,
						free_dtl: this.stationData.free_dtl,
						bname: this.stationData.bname,
						bcall: this.stationData.bcall,
						reserv_yn: this.stationData.reserv_yn,
						note: this.stationData.note,
						year: this.stationData.year,
						limit_yn: this.stationData.limit_yn,
						limit_dtl: limit_dtl,
						del_yn: "N",
						del_dtl: "",
						update_date: crt_date,
						update_id: this.admin_data.user_id,
						fee_id: this.stationData.fee_id,
						bis_id: this.stationData.bis_id,
						status_send_yn: this.stationData.status_send_yn
          }
        );

        window.alert("충전소 정보가 수정되었습니다.");
        var context = "충전소ID : " + this.stationData.sid;
        this.addAdminLog("충전소 관리", "충전소 수정", "충전소 등록팝업", context, this.admin_data.user_id);
        this.$emit("reflash");

        this.$bvModal.hide("modal-station-detail");
      } catch (error) {
        window.alert("충전소 수정을 실패했습니다.");
      }
    },
    async selectStationZcode() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectStationValidate", {
            zcode: this.stationData.zcode
          }
        );

        let station_count = data.length + 1;
        let max_regRNo = "0000" + station_count.toString();
        let mod_regRNo = max_regRNo.slice(-4);
        let station_Rid = this.stationData.zcode + mod_regRNo;
        this.stationData.r_sid = station_Rid;

        let temp_data = [];
        data.forEach((e,i) => {
          if (e.zsgcode === this.stationData.zsgcode) {
            temp_data.push(e);  
          }
        });

        let station_sigunguCount = temp_data.length + 1;
        let max_regNo = "000" + station_sigunguCount.toString();
        let mod_regNo = max_regNo.slice(-3);
        let station_id = this.stationData.zsgcode + mod_regNo;
        this.stationData.sid = station_id;
      } catch (error) {
        window.alert("충전소 정보 조회를 실패했습니다.");
      }
    },
    async selectStationId() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectStationValidate", {
            sid: this.stationData.sid
          }
        );

        if (data.length === 0) {
          this.addStation();
        } else if (data.length > 0) {
          window.alert("입력하신 충전소ID는 이미 존재하는 것으로 확인되었습니다. 다른 ID로 다시 입력해 주시기 바랍니다.");
        }
      } catch (error) {
        console.log(error);
      }
    },
    changeKind (select_kind) {
      this.kindDtlListData.forEach((e,i) => {
        let options = [];
        if (e.r_code === select_kind) {
          let add_option = { value: e.code, text: e.code_name };
          options.push(add_option);
        }

        this.kindDtlOptions = options;

        if (options.length === 0) {
          this.stationData.kind_dtl = "";
        }
      });
    },
    changeBid (select_bid) {
      let options = [];

      if (select_bid === "") {
        select_bid = this.bnameOptions[0].value;
      }

      this.feeListData.forEach((e,i) => {
        if (e.bid === select_bid) {
          let add_option = { value: e.cf_id, text: e.cf_title }
          options.push(add_option);
        }
      });

      this.feeOptions = options;
    },
    changeBis (bis_id) {
      this.bis_value = bis_id.substring(0,3) + "-" + bis_id.substring(3,5) + "-" + bis_id.substring(5,10);
      this.stationData.bis_id = bis_id;
    },
    reStatusYn(e) {
      if (e) {
        console.log("on");
        this.stationData.status_send_yn = "Y";
      } else {
        console.log("off");
        this.stationData.status_send_yn = "N";
      }
    },
    setData(select_rowData) {
      this.stationData = select_rowData;

      if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
        this.stationData.kind = this.kindOptions[0].value;
        this.stationData.bid = this.bnameOptions[0].value;
        this.stationData.fee_id = this.feeOptions[0].value;
      } else {
        let gps_str = this.stationData.gps;
        let gps_arr = this.stationData.gps.split(",");
        this.gps_text = "X : " + gps_arr[0] + ", Y : " + gps_arr[1];

        if (this.stationData.status_send_yn === "Y") {
          this.isReStatusYn = true;
        } else if (this.stationData.status_send_yn === "N") {
          this.isReStatusYn = false;
        }
      }

      this.changeBid(this.stationData.bid);
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("OCPP 충전기 정보 등록을 실패했습니다.");
      }
    },
    foldDaumzonecode() {
      // iframe을 넣은 element를 안보이게 한다.
      this.wrap.style.display = "none";
    },
    getPostCode() {
      // 현재 scroll 위치를 저장해놓는다.
      var currentScroll = Math.max(
        document.body.scrollTop,
        document.documentElement.scrollTop
      );
      new daum.Postcode({
        //autoMappingJibun: false, //연관주소가 지번일 때 지번 주소 자동매핑을 막음
       // autoMappingRoad: false, //연관주소가 도로명일 때  주소 자동매핑을 막음

        oncomplete: (data) => {
          console.log(data);
          // 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

          // 각 주소의 노출 규칙에 따라 주소를 조합한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          var addr = ""; // 주소 변수
          var extraAddr = ""; // 참고항목 변수

          //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
          if (data.userSelectedType === "R") {
            // 사용자가 도로명 주소를 선택했을 경우
            addr = data.roadAddress;
          } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            addr = data.jibunAddress;
          }

          // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
          if (data.userSelectedType === "R") {
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            // 조합된 참고항목을 해당 필드에 넣는다.
          }
          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          //===================================================================
          //jibunAddressEnglish 또는 autoJibunAddressEnglish에 랜덤으로 값이 담겨 필터링 추가함
          let jibun = "";

          if (data.autoJibunAddressEnglish === "") {
            jibun = data.jibunAddressEnglish.split(",")[0];
          } else {
            jibun = data.autoJibunAddressEnglish.split(",")[0];
          }

          //====================================================================
          this.add = jibun;
          this.addressObj = {
            gps_x: "",
            gps_y: "",
            zipNo: data.zonecode,
            address: `${addr} ${extraAddr}`,
            sido: data.sido,
            jibunAddress:
              data.jibunAddress !== ""
                ? data.jibunAddress
                : data.autoJibunAddress,
            roadAddress:
              data.roadAddress != "" ? data.roadAddress : data.autoRoadAddress,
            addressDetailMeta: {
              bcode: data.bcode,
              bname: data.bname,
              bname1: data.bname1,
              buildingCode: data.buildingCode,
              buildingName: data.buildingName,
              bun: jibun.split("-")[0] || "",
              ji: jibun.split("-")[1] || "",
              sido: data.sido,
              sigungu: data.sigungu,
              sigunguCode: data.sigunguCode,
            },
          };

          this.stationData.addr = this.addressObj.address;
          
          const geocoder = new kakao.maps.services.Geocoder();

          let gps_x = "";
          let gps_y = "";

          // 주소로 좌표를 검색합니다
          geocoder.addressSearch(`${addr} ${extraAddr}`, (result, status) => {
            // 정상적으로 검색이 완료됐으면
            if (status === kakao.maps.services.Status.OK) {
              console.log("OK",result)
              const coords = new kakao.maps.LatLng(result[0].y, result[0].x);
              //const message = 'latlng: new kakao.maps.LatLng(' + result[0].y + ', ';
              //message += result[0].x + ')';

              gps_x = result[0].x;
              gps_y = result[0].y;

              this.stationData.gps = gps_x + "," + gps_y;
              this.addressObj.gps_x = gps_x;
              this.addressObj.gps_y = gps_y;
              this.gps_text = "X : " + gps_x + ", Y : " + gps_y;
            }
          });
          this.stationData.zcode = this.addressObj.addressDetailMeta.bcode.substring(0,2);
          this.stationData.zscode = this.addressObj.addressDetailMeta.bcode
          this.stationData.zsgcode = this.addressObj.addressDetailMeta.bcode.substring(0,5);

          this.selectStationZcode();
          // 커서를 상세주소 필드로 이동한다.
          // this.$refs.addrDetail.$el.focus();

          // iframe을 넣은 element를 안보이게 한다.
          // (autoClose:false 기능을 이용한다면, 아래 코드를 제거해야 화면에서 사라지지 않는다.)
          //this.wrap.style.display = "none";

          // 우편번호 찾기 화면이 보이기 이전으로 scroll 위치를 되돌린다.
          document.body.scrollTop = currentScroll;
        },

        // 우편번호 찾기 화면 크기가 조정되었을때 실행할 코드를 작성하는 부분. iframe을 넣은 element의 높이값을 조정한다.
        // onresize: (size) => {
        //   this.wrap.style.height = size.height + "px";
        // },
        // width: "100%",
        // height: "100%",
      }).open({ autoClose: true }); //주소 선택시 팝업창 닫기
      // iframe을 넣은 element를 보이게 한다.
      // this.wrap.style.display = "block";
      // test(address);
    },
  },
};
</script>

<style lang="scss" scoped></style>
