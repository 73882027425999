<template>
  <b-modal
    id="modal-reader-detail"
    :title="obj.title"
    :ok-title="obj.ok_title"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="false"
    size="lg"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxWidth: '800px', maxHeight: '1000px' }"
    >
      <div>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />단말 종류
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select size="sm" v-model="readerData.type" :options="typeOptions"></b-form-select>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />통신사
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select size="sm" v-model="readerData.t_id" :options="tidOptions"></b-form-select>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />단말기 번호
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" type=number v-model="readerData.reader_id"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />ICCID
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="readerData.iccid"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />버전
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="readerData.ver"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />저전압
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" type=number v-model="readerData.l_voltage"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />메인 F/W버전
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="readerData.fw_ver"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />대기중 전송주기
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" type=number v-model="readerData.t_cycle"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />충전중 전송주기
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" type=number v-model="readerData.c_cycle"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />단말 상태
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select size="sm" v-model="readerData.status" :options="statusOptions"></b-form-select>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />MID
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input v-bind:disable="reader_match === 'Y'" style="height: 50px; border-radius: 10px;" v-model="readerData.mid"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />Terminal ID
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input v-bind:disable="reader_match === 'Y'" style="height: 50px; border-radius: 10px;" v-model="readerData.terminal_id"></b-form-input>
          </div>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  components: {
    
  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      edit_type: String,
      title: String,
      ok_title: String,
      reader_data: Object,
    },
    value: {
      type: Object,
    },
  },
  data() {
    return {
      admin_data: {},
      readerData: [],
      reader_match: "N",
      readerList_data: [],
      typeOptions: [
        { value: "N", text: "신규단말" },
        { value: "O", text: "기존단말" }
      ],
      tidOptions: [
        { value: "S", text: "SKT" },
        { value: "K", text: "KT" },
        { value: "L", text: "LGT" }
      ],
      statusOptions: [
        { value: "Y", text: "정상" },
        { value: "N", text: "중지" }
      ],
      rules: {
        address: [
          (v) =>
            !!v || {
              title: "주소 입력",
              message: "주소를 입력하세요.",
            },
        ],
        // addressDetail: [
        //   (v) =>
        //     !!v || {
        //       title: "상세 주소 입력",
        //       message: "상세 주소를 입력하세요.",
        //     },
        // ],
      },
    };
  },
  mounted() {
    if (this.$store.getters.user === null) {
      this.admin_data.user_id = "admin";
    } else {
      this.admin_data = this.$store.getters.user;
    }

    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        if (this.obj.edit_type === "add") {
          this.readerData = this.obj.reader_data;
        } else if (this.obj.edit_type === "edit") {
          this.readerMatchChk();
        }
      });
    },
    async readerMatchChk() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectChargerValidate", {
            reader_id: this.readerData.reader_id
          }
        );
        if (data.length > 0) {
          this.reader_match = "Y";
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    setData(select_rowData) {debugger;
      this.readerData = select_rowData;

      if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
        this.readerData.type = this.typeOptions[0].value;
        this.readerData.t_id = this.tidOptions[0].value;
        this.readerData.status = this.statusOptions[0].value;
      }
    },
    async ok() {
      if (this.obj.edit_type === "detail") {
        this.$bvModal.hide("modal-reader-detail");
      } else {
        if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
          this.selectReaderList();

          let reader_validate = [];
          this.readerList_data.forEach((e,i) => {
            if (e.reader_id === this.readerData.reader_id) {
              let add_data = { reader_id: e.reader_id }
              reader_validate.push(add_data);  
            }
          });

          if (reader_validate > 0) {
            window.alert("요청하신 단말기번호는 이미 등록된 번호로 확인되었습니다.");
          } else {
            this.addReader();
          }
        } else if (this.obj.edit_type === "edit") {
          this.updateReader();
        }
      }
    },
    async selectReaderList() {
      try {
        const { data } = await this.$axios.post("/api/selectReaderList", {});
        if (data.length > 0) {
          this.readerList_data = data;
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async addReader() {
      var crt_date = this.getToday();

      var tId_value = this.readerData.t_id;
			// imsi 번호 조합
			var imsi = "450";
			if (tId_value == "S") {
				imsi += "05";
			} else if (tId_value == "K") {
				imsi += "04";
			} else if (tId_value == "L") {
				imsi += "06";
			};
			imsi += this.readerData.reader_id;

      try {
        const { data } = await this.$axios.post("/api/addReader", {
          reader_id: this.readerData.reader_id,
					type: this.readerData.type,
					sn_id: this.readerData.reader_id,
					t_id: this.readerData.t_id,
					ver: this.readerData.ver,
					l_voltage: this.readerData.l_voltage,
					fw_ver: this.readerData.fw_ver,
					t_cycle: this.readerData.t_cycle,
					c_cycle: this.readerData.c_cycle,
					status: this.readerData.status,
					reg_user: this.admin_data.user_id,
					reg_date: crt_date,
					iccid: this.readerData.iccid,
					imsi: imsi,
					mid: this.readerData.mid,
					terminal_id: this.readerData.terminal_id
        });

        window.alert("단말기 정보가 등록되었습니다.");
        var context = "단말기ID : " + this.readerData.sid;
        this.addAdminLog("단말기 관리", "단말기 등록", "단말기 등록팝업", context, this.admin_data.user_id);
        this.$emit("reflash");
        this.$bvModal.hide("modal-reader-detail");
      } catch (error) {
        // console.log(7);
        console.log(error);
        window.alert("단말기 정보 등록을 실패했습니다.");
      }
    },
    async updateReader() {
      var crt_date = this.getToday();
      try {
        const { data } = await this.$axios.post("/api/updateReader", {
          reader_id: this.readerData.reader_id,
					type: this.readerData.type,
					sn_id: this.readerData.reader_id,
					t_id: this.readerData.t_id,
					ver: this.readerData.ver,
					l_voltage: this.readerData.l_voltage,
					fw_ver: this.readerData.fw_ver,
					t_cycle: this.readerData.t_cycle,
					c_cycle: this.readerData.c_cycle,
					status: this.readerData.status,
					reg_user: this.readerData.reg_user,
					reg_date: this.readerData.reg_date,
					iccid: this.readerData.iccid,
					imsi: this.readerData.imsi,
					mid: this.readerData.mid,
					terminal_id: this.readerData.terminal_id
        });
        
        window.alert("단말기 정보가 수정되었습니다.");
        var context = "단말기ID : " + this.readerData.sid;
        this.addAdminLog("단말기 관리", "단말기 수정", "단말기 수정팝업", context, this.admin_data.user_id);
        this.$emit("reflash");
        this.$bvModal.hide("modal-reader-detail");
      } catch (error) {
        // console.log(7);
        console.log(error);
        window.alert("단말기 정보 수정을 실패했습니다.");
      }
    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("관리자 명령 로그 등록을 실패했습니다.");
      }
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
  },
};
</script>

<style lang="scss" scoped></style>
