<template>
  <div class="" style="height: inherit;" ref="element">
    <b-container>
      <b-row style="margin: 0px 0px 10px 0px;">
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          
        </b-col>
        <b-col lg=6 class="d-flex justify-content-end flex-row align-items-center">
          <b-button class="btn_def" @click="add">등록</b-button>
        </b-col>
      </b-row>
      <b-row style="margin: 0px 0px 10px 0px;">
        <b-col lg=4 class="d-flex justify-content-end flex-row">
          <div class="table-wrapper2" style="width: -webkit-fill-available;">
            <b-table 
              id = "grid1"
              bordered hover noCollapse small 
              :items="first_gData"
              :fields="first_gridFields"
            >
              <template #cell(No)="row">
                <div style="cursor: pointer;" @click="secondTypeChange(row.index)">
                  {{ row.index + 1 }}
                </div>
              </template>
              <template #cell(code)="row">
                <div style="cursor: pointer;" @click="secondTypeChange(row.index)">
                  {{ first_gData[row.index].code }}
                </div>
              </template>
              <template #cell(code_name)="row">
                <div style="cursor: pointer;" @click="secondTypeChange(row.index)">
                  {{ first_gData[row.index].code_name }}
                </div>
              </template>
              <template #cell(use_yn)="row">
                <div style="cursor: pointer;" @click="secondTypeChange(row.index)">
                  {{ first_gData[row.index].use_yn }}
                </div>
              </template>
            </b-table>
          </div>
        </b-col>
        <b-col lg=8 class="d-flex justify-content-end flex-row">
          <div class="table-wrapper2" style="width: -webkit-fill-available;">
            <b-table 
              id = "grid2"
              striped hover 
              :items="second_gData"
              :fields="second_gridFields"
              small
            >
              <template #cell(No)="row">
                <div style="cursor: pointer;" @click="selectRow(row.index)">
                  {{ row.index + 1 }}
                </div>
              </template>
              <template #cell(code)="row">
                <div style="cursor: pointer;" @click="selectRow(row.index)">
                  {{ second_gData[row.index].code }}
                </div>
              </template>
              <template #cell(code_name)="row">
                <div style="cursor: pointer;" @click="selectRow(row.index)">
                  {{ second_gData[row.index].code_name }}
                </div>
              </template>
              <template #cell(url)="row">
                <div style="cursor: pointer;" @click="selectRow(row.index)">
                  {{ second_gData[row.index].url }}
                </div>
              </template>
              <template #cell(descript)="row">
                <div style="cursor: pointer;" @click="selectRow(row.index)">
                  {{ second_gData[row.index].descript }}
                </div>
              </template>
              <template #cell(use_yn)="row">
                <div style="cursor: pointer;" @click="selectRow(row.index)">
                  {{ second_gData[row.index].use_yn }}
                </div>
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>

      <!-- modals -->
      <modal-car-add ref="modal_carAdd" :obj="obj" @reflash="reflash"/>
      <modal-car-edit ref="modal_carEdit" :obj="obj" @reflash="reflash"/>
    </b-container>
  </div>
</template>

<script>
import Form from "@/components/Form/index";
import regex from "@/lib/regex";

import Modals from "@/components/Modals/index";
import { ref } from 'vue';

import * as XLSX from 'xlsx' // 라이브러리 import

import $ from 'jquery';

export default {
  components: {
    
    // Modals
    ModalCarAdd: Modals.CarAdd,
    ModalCarEdit: Modals.CarEdit,
  },
  data() {
    const isDev = process.env.NODE_ENV !== "production";

    return {
      formValid: false,
      salt: 0,
      crt_width: 0,
      crt_height: 0,
      page_grid_height: 0,
      select_index: -1,
      select_rowData: {
        id : 0,
        type : "CC",
        type_name : "차량",
        code : "",
        code_name : "",
        level : "",
        order_no : "",
        r_code : "",
        url : "",
        reg_date : "",
        reg_user : "",
        update_date : "",
        update_user : "",
        use_yn : "",
        descript : "",
      },
      first_code: [],
      second_code: [],
      single_gData: [],
      single_gridFields: [
      {
          key: "No",
          label: "No"
        },
        {
          key: "code",
          label: "코드"
        },
        {
          key: "code_name",
          label: "코드명"
        },
        {
          key: "url",
          label: "경로 (이미지)"
        },
        {
          key: "descript",
          label: "추가정보"
        },
        {
          key: "use_yn",
          label: "사용여부"
        },
      ],
      first_gData: [],
      first_gridFields: [
        {
          key: "No",
          label: "No"
        },
        {
          key: "code",
          label: "코드"
        },
        {
          key: "code_name",
          label: "코드명"
        },
        {
          key: "use_yn",
          label: "사용여부"
        },
      ],
      second_gData: [],
      second_gridFields: [
        {
          key: "No",
          label: "No"
        },
        {
          key: "code",
          label: "코드"
        },
        {
          key: "code_name",
          label: "코드명"
        },
        {
          key: "url",
          label: "경로 (이미지)"
        },
        {
          key: "descript",
          label: "추가정보"
        },
        {
          key: "use_yn",
          label: "사용여부"
        },
      ],
      searchType: "CC",
      searchTypeOption: [],
      rules: {
        
      },
      obj: {
        edit_type: "add",
        title: "차량 코드 등록",
        ok_title: "등록",
        first_code: null,
        second_code: null,
        param_data: {
          id : 0,
          type : "CC",
          type_name : "차량",
          code : "",
          code_name : "",
          level : "",
          order_no : "",
          r_code : "",
          r_code_name : "",
          url : "",
          reg_date : "",
          reg_user : "",
          update_date : "",
          update_user : "",
          use_yn : "",
          descript : "",
        },
      },
    };
  },
  computed: {
    
  },
  watch: {
    
  },
  methods: {
    async selectCode() {
      console.log("차량 관리 페이지");

      try {
        const { data } = await this.$axios.post(
          "/api/selectCode", {
            //use_yn : "Y",
            type : "CC"
          }
        );
        if (data.length > 0) {
          let f_data = [];
          let s_data = [];

          data.forEach((e,i) => {
            if (e.level === 1) {
              f_data.push(e);
            } else if (e.level === 2) {
              s_data.push(e);
            }
          });

          this.first_code = f_data;
          this.second_code = s_data;

          let temp_data1 = [];
          let temp_data2 = [];

          this.first_code.forEach((e,i) => {
            if (e.type === "CC") {
              temp_data1.push(e);
            }
          });

          this.second_code.forEach((e,i) => {
            if (e.type === "CC") {
              temp_data2.push(e);
            }
          });

          this.first_gData = temp_data1;

          let second_temp_data = [];

          temp_data2.forEach((e,i) => {
            if (e.r_code === this.first_gData[0].code) {
              second_temp_data.push(e);
            }
          });

          this.second_gData = second_temp_data;
        } else if (data.length == 0) {
          this.searchTypeOption = null;
          window.alert("데이터가 없습니다.");
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    secondTypeChange(row_index) {
      let temp_data = [];

      this.second_code.forEach((e,i) => {
        if (e.r_code === this.first_gData[row_index].code) {
          temp_data.push(e);
        }
      });

      this.second_gData = temp_data;
    },
    selectRow(row_index) {
      this.obj.first_code = this.first_code;
      this.obj.second_code = this.second_code;
      this.obj.param_data = this.second_gData[row_index];

      this.$refs.modal_carEdit.setData(this.obj.param_data);
      this.$bvModal.show("modal-car-edit");
    },
    add() {
      this.obj.first_code = this.first_code;
      this.obj.second_code = this.second_code;

      this.$bvModal.show("modal-car-add");
    },
    reflash() {
      var tbody = document.querySelector("#grid");
      var trs = tbody.getElementsByTagName('tr');
      
      var selectIndex = this.select_index + 1;
      this.select_index = -1;
      this.select_rowData = { id : 0, type : "CC", type_name : "차량", code : "", code_name : "", level : "", order_no : "", r_code : "", url : "", reg_date : "", reg_user : "", update_date : "", update_user : "", use_yn : "", descript : "" };

      trs[selectIndex].style.backgroundColor = "white";

      this.selectCode();
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
  },
  beforeDestroy() {
    //clearInterval(this.timerActive);
  },
  async mounted() {
    this.selectCode();
  },
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: goldenrod;
}
</style>