<template>
  <div class="" style="height: inherit;" ref="element">
    <b-container>
      <b-row style="margin: 0px 0px 10px 0px;">
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span style="flex: 1;">종류</span>
          <b-form-select size="sm" style="flex: 5;" v-model="searchType" @change="changeType" :options="searchTypeOption"></b-form-select>
        </b-col>
        <b-col lg=6 class="d-flex justify-content-end flex-row align-items-center">
          <b-button class="btn_def" @click="add">등록</b-button>
        </b-col>
      </b-row>
      <b-row style="margin: 0px 0px 10px 0px;">
        <div class="table-wrapper2 d-flex justify-content-end flex-row" style="width: -webkit-fill-available;">
          <b-table 
            id = "grid1"
            bordered hover noCollapse 
            :items="single_gData"
            :fields="single_gridFields"
            small
          >
            <template #cell(No)="row">
              <div style="cursor: pointer;" @click="selectRow(row.index)">
                {{ row.index + 1 }}
              </div>
            </template>
            <template #cell(type)="row">
              <div style="cursor: pointer;" @click="selectRow(row.index)">
                {{ single_gData[row.index].type }}
              </div>
            </template>
            <template #cell(name)="row">
              <div style="cursor: pointer;" @click="selectRow(row.index)">
                {{ single_gData[row.index].name }}
              </div>
            </template>
            <template #cell(value)="row">
              <div style="cursor: pointer;" @click="selectRow(row.index)">
                {{ single_gData[row.index].value }}
              </div>
            </template>
            <template #cell(reg_user)="row">
              <div style="cursor: pointer;" @click="selectRow(row.index)">
                {{ single_gData[row.index].reg_user }}
              </div>
            </template>
            <template #cell(reg_date)="row">
              <div style="cursor: pointer;" @click="selectRow(row.index)">
                {{ single_gData[row.index].reg_date }}
              </div>
            </template>
            <template #cell(update_user)="row">
              <div style="cursor: pointer;" @click="selectRow(row.index)">
                {{ single_gData[row.index].update_user }}
              </div>
            </template>
            <template #cell(update_date)="row">
              <div style="cursor: pointer;" @click="selectRow(row.index)">
                {{ single_gData[row.index].update_date }}
              </div>
            </template>
          </b-table>
        </div>
      </b-row>

      <!-- modals -->
      <modal-enc-edit ref="modal_encEdit" :obj="obj" @reflash="reflash"/>

    </b-container>
  </div>
</template>

<script>
import Form from "@/components/Form/index";
import regex from "@/lib/regex";

import Modals from "@/components/Modals/index";
import { ref } from 'vue';

import * as XLSX from 'xlsx' // 라이브러리 import

import $ from 'jquery';

export default {
  components: {
    
    // Modals
    ModalEncEdit: Modals.EncEdit,
  },
  data() {
    const isDev = process.env.NODE_ENV !== "production";

    return {
      formValid: false,
      salt: 0,
      crt_width: 0,
      crt_height: 0,
      page_grid_height: 0,
      select_index: -1,
      select_rowData: {
        id : 0,
        type : "",
        name : "",
        value : "",
        reg_user : "",
        reg_date : "",
        update_user : "",
        update_date : "",
      },
      single_gData: [],
      single_gridFields: [
      {
          key: "No",
          label: "No"
        },
        {
          key: "type",
          label: "종류"
        },
        {
          key: "name",
          label: "설정명"
        },
        {
          key: "value",
          label: "설정값"
        },
        {
          key: "reg_user",
          label: "등록자"
        },
        {
          key: "reg_date",
          label: "등록일시"
        },
        {
          key: "update_user",
          label: "수정자"
        },
        {
          key: "update_date",
          label: "수정일시"
        },
      ],
      searchType: "",
      searchTypeOption: [],
      rules: {
        
      },
      obj: {
        edit_type: "add",
        title: "설정값 등록",
        ok_title: "등록",
        type_list: [],
        param_data: {
          id : 0,
          type : "",
          name : "",
          value : "",
          reg_user : "",
          reg_date : "",
          update_user : "",
          update_date : "",
        },
      },
    };
  },
  computed: {
    
  },
  watch: {
    
  },
  methods: {
    async selectEnc() {
      console.log("환경설정 관리 페이지");

      try {
        const { data } = await this.$axios.post(
          "/api/selectEnc", {
            //use_yn : "Y",
            //type : "C"
          }
        );
        if (data.length > 0) {
          let typeList = data.filter(
            (e, i, callback) =>
              i === callback.findIndex(
                (e1) => e1.type === e.type
              )
          );

          let options = [];

          typeList.forEach((e,i) => {
            let add_option = { value: e.type, text: e.type }
            options.push(add_option);
          });

          this.searchTypeOption = options;
          this.searchType = this.searchTypeOption[0].value;

          let temp_data = [];

          data.forEach((e,i) => {
            if (e.type === this.searchType) {
              temp_data.push(e);
            }
          });

          this.single_gData = temp_data;
        } else if (data.length == 0) {
          this.searchTypeOption = null;
          this.single_gData = null;
          window.alert("데이터가 없습니다.");
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    changeType() {
      let select_type = this.searchType;

      let temp_data = [];

      this.data.forEach((e,i) => {
        if (e.type === select_type) {
          temp_data.push(e);
        }
      });

      this.single_gData = temp_data;
    },
    selectRow(row_index) {
      this.obj.edit_type = "edit";
      this.obj.title = "설정값 수정";
      this.obj.ok_title = "수정";
      this.obj.type_list = this.searchTypeOption;
      this.obj.param_data = this.single_gData[row_index];

      this.$refs.modal_encEdit.setData(this.obj.param_data);
      this.$bvModal.show("modal-enc-edit");
    },
    add() {debugger;
      this.obj.edit_type = "add";
      this.obj.title = "설정값 등록";
      this.obj.ok_title = "등록";
      this.obj.type_list = this.searchTypeOption;
      this.obj.param_data.type = this.searchTypeOption[0].value;

      this.$bvModal.show("modal-enc-edit");
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
    reflash() {
      this.selectEnc();
    }
  },
  beforeDestroy() {
    //clearInterval(this.timerActive);
  },
  async mounted() {
    this.selectEnc();
  },
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: goldenrod;
}
</style>