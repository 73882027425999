<template>
  <b-modal
    id="modal-menu-edit"
    title="메뉴 수정"
    ok-title="수정"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="lg"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxWidth: '800px', maxHeight: '1000px' }"
    >
      <div class="mt-1">
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />메뉴 ID
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="menuData.code"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />메뉴 이름
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="menuData.name"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />메뉴 경로
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="menuData.url"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />메뉴 설명
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="menuData.descript"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />권한
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-group>
              <b-form-radio-group
                id="radio-group-1"
                v-model="menuData.authority"
                :options="authOptions"
              ></b-form-radio-group>
            </b-form-group>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />사용여부
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select size="sm" v-model="menuData.use_yn" :options="useOptions"></b-form-select>
          </div>
        </b-row>
      </div>
    </div>

    
  </b-modal>
</template>

<script>

export default {
  components: {

  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      menu_data: Object,
    },
  },
  data() {
    return {
      tabIndex: 1,
      menuData: {
        company_id: "",
				name: "",
				url: "",
				descript: "",
				level: 1,
				order_no: 1,
				r_menu_id: "",
				authority: "S,A,U,C",
				reg_date: "",
				update_date: "",
				reg_id: "",
				use_yn: "Y",
				code: "",
      },
      companyOptions: [],
      rmenuOptions: [],
      authOptions: [
        { value: "S,A,U,C", text: "전체" },
        { value: "S", text: "최고 관리자" },
        { value: "S,A", text: "일반 관리자" },
        { value: "S,A,C", text: "CS 운영자" },
        { value: "S,A,U", text: "사용자" }
      ],
      useOptions: [
        { value: "Y", text: "사용" },
        { value: "N", text: "미사용" }
      ],
      admin_data: {},
      rules: {
        address: [
          (v) =>
            !!v || {
              title: "주소 입력",
              message: "주소를 입력하세요.",
            },
        ],
        // addressDetail: [
        //   (v) =>
        //     !!v || {
        //       title: "상세 주소 입력",
        //       message: "상세 주소를 입력하세요.",
        //     },
        // ],
      },
    };
  },
  mounted() {
    if (this.$store.getters.user === null) {
      this.admin_data.user_id = "admin";
    } else {
      this.admin_data = this.$store.getters.user;
    }

    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.menuData = this.obj.menu_data;
      });
    },
    async ok() {
      try {
        this.updateMenu();

        window.alert("메뉴 정보가 수정되었습니다.");
        var context = "메뉴ID : " + this.menuData.code + ", 메뉴 이름 : " + this.menuData.name + ", 메뉴경로 : " + this.menuData.url + " , 권한 : " + this.menuData.authority + " , 사용유무 : " + this.menuData.use_yn;
        this.addAdminLog("메뉴 관리", "메뉴 수정", "메뉴 수정 팝업", context, this.admin_data.user_id);
        this.$emit("reflash");
        this.$bvModal.hide("modal-menu-edit");
      }  catch (error) {
        window.alert("메뉴 정보가 수정을 실패했습니다.");
        console.log(error);
      }
    },
    setData(select_rowData) {debugger;
      this.menuData = select_rowData;
    },
    async updateMenu() {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/updateMenu", {
            code: this.admin_data.code,
						name: this.menuData.name,
						url: this.menuData.url,
						descript: this.menuData.descript,
						authority: this.menuData.authority,
						update_date: crt_date,
						use_yn: this.menuData.use_yn,
          }
        );
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("OCPP 충전기 정보 등록을 실패했습니다.");
      }
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
  },
};
</script>

<style lang="scss" scoped></style>
