<template>
  <b-modal
    id="modal-reset-call"
    title="충전기 리셋"
    ok-title="재시작"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="false"
    size="md"
    @shown="init"
  >
    <div class="pr-md-2 overflow-auto">
      <div>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />종류
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select size="sm" v-model="reset_type" :options="resetTypeOptions"></b-form-select>
          </div>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  components: {

  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      param_data: Object,
    },
  },
  data() {
    return {
      admin_data: {},
      main_url: "http://evcloud.duruscoev.com/ocpp-jt",
      chargerData: {},
      reset_type: "Soft",
      sys_type: "T",
      conn_data: [],
      entry_data: [],
      call_type: "",
      full_url: "",
      param_json: {},
      resetTypeOptions: [
        { text: "Soft", value: "Soft" },
        { text: "Hard", value: "Hard" }
      ],
    };
  },
  mounted() {
    if (this.$store.getters.user === null) {
      this.admin_data.user_id = "admin";
    } else {
      this.admin_data = this.$store.getters.user;
    }

    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {debugger;
        this.chargerData = this.obj.param_data;
      });
    },
    ok() {
      this.ocppConnReflash(this.chargerData.sid, this.chargerData.reader_id);
    },
    async ocppConnReflash(sid_value, tel_num_value) {
      try {
        const { data } = await this.$axios.post(
          "/api/selectOcppConnLastLog", {
            sid: sid_value, 
            tel_num: tel_num_value
          }
        );
        if (data.length > 0) {
          this.conn_data = data;
          this.selectLastEventEntry();
        } else if (data.length === 0) {
          window.alert("현재 충전기가 연결되어 있지 않습니다. 다시 확인하신 후 시도해 보시기 바랍니다.");
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async selectLastEventEntry() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectLastEventEntry", {
            sys_type: this.sys_type
          }
        );
        if (data.length > 0) {
          this.entry_data = data;

          this.addEventEntry();
        } else if (data.length === 0) {
          console.log("데이터가 없습니다.");
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async addEventEntry() {
      let crt_date = this.getToday();

      let sys_id = parseInt(this.entry_data[0].sys_id) + 1;

      let req_param = {};
      req_param.type = this.reset_type;

      let req_param_str = JSON.stringify(req_param);

      let last_entry_status = this.entry_data[0].status;
      let entry_status = "";

      if (last_entry_status == "STANDBY" || last_entry_status == "QUEUE") {
        entry_status = "QUEUE";
      } else if (last_entry_status == "QUEUE_WAIT") {
        entry_status = "QUEUE_WAIT";
      } else {
        entry_status = "STANDBY";
      };

      try {
        const { data } = await this.$axios.post(
          "/api/addEventEntry", {
            type: "reset",
						req_param: req_param_str,
						status: entry_status,
						timestamp: crt_date,
						session_index: this.conn_data[0].uuid,
						sys_type: this.sys_type,
						sys_id: sys_id
          }
        );
        if (data.length > 0) {
          window.alert("충전기 초기화를 요청했습니다.");
          let context = "충전소ID : " + this.chargerData.sid + ", 충전기ID : " + this.chargerData.cid + ", 단말기번호 : " + this.chargerData.reader_id;
          this.addAdminLog("충전기 관리", "충전기 원격요청", "충전기 초기화 요청", context, this.admin_data.user_id);
          this.$bvModal.hide("modal-reset-call");
        } else if (data.length === 0) {
          window.alert("충전기 초기화 요청을 실패했습니다.");
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("관리자 명령 로그 등록을 실패했습니다.");
      }
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
  },
};
</script>

<style lang="scss" scoped></style>
