<template>
  <div class="" style="height: inherit;" ref="element">
    <b-container class="d-flex flex-column" style="height: 100%;">
      <b-row style="margin: 0px 0px 10px 0px; height: 50px;">
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">정산기간</span>
          <b-form-select size="sm" style="flex: 4;" v-model="filterData.searchCalcurate" @change="setFilter" :options="searchCalcurateOption"></b-form-select>
        </b-col>
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">결제상태</span>
          <b-form-select size="sm" style="flex: 4;" v-model="filterData.searchPay" @change="setFilter" :options="searchPayOption"></b-form-select>
        </b-col>
      </b-row>
      <b-row class="d-flex flex-column" style="width: inherit; margin: 0px; flex: 1; height: 90vh;">
        <div class="d-flex flex-column" style="height: 100%;">
          <div class="d-flex flex-column" style="margin-bottom: 20px; height: 50%; padding-bottom: 20px;">
            <div style="height: 25px; margin: 0px 0px 10px 0px;">
              <b-img></b-img>
              <span style="flex: 1;">종합 정산결과</span>
            </div>
            <div class="table-wrapper2" style="flex: 1; overflow-y: scroll; padding-bottom: 20px;">
              <table class="px-3 text-14 text-lg-16">
                <tr class="border-1 border-white">
                  <th rowspan="2">정산기간</th>
                  <th rowspan="2">충전회수</th>
                  <th rowspan="2">충전량</th>
                  <th colspan="2">정산 (결제)</th>
                  <th colspan="2">정산 (미결제)</th>
                  <th rowspan="2">총 합계</th>
                  <th rowspan="2">상세정보</th>
                </tr>
                <tr class="border-1 border-white">
                  <th>건수</th>
                  <th>금액</th>
                  <th>건수</th>
                  <th>금액</th>
                </tr>
                <tr
                  v-for="(item, i) in items1" :key="i">
                  <td> {{item.cal_date}} </td>
                  <td> {{item.company_count}} </td>
                  <td> {{item.pow}} </td>
                  <td> {{item.pay_count}} </td>
                  <td> {{item.pay_amount}} </td>
                  <td> {{item.not_pay_count}} </td>
                  <td> {{item.not_pay_amount}} </td>
                  <td> {{item.total_amount}} </td>
                  <td>
                    <div>
                      <b-button class="btn_def" @click="add">상세정보</b-button>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="d-flex flex-column" style="margin-bottom: 20px; height: 50%; padding-bottom: 20px;">
            <div style="height: 25px; margin: 0px 0px 10px 0px;">
              <b-img></b-img>
              <span style="flex: 1;">이달의 정산내역</span>
            </div>
            <div class="table-wrapper2" style="flex: 1; overflow-y: scroll; padding-bottom: 20px;">
              <table class="px-3 text-14 text-lg-16">
                <tr class="border-1 border-white">
                  <th rowspan="2">정산기간</th>
                  <th rowspan="2">법인명</th>
                  <th rowspan="2">충전소명</th>
                  <th colspan="2">충전회수</th>
                  <th rowspan="2">충전량</th>
                  <th rowspan="2">정산금액</th>
                  <th rowspan="2">상세정보</th>
                </tr>
                <tr class="border-1 border-white">
                  <th>급속</th>
                  <th>완속</th>
                </tr>
                <tr
                  v-for="(item, i) in items2" :key="i">
                  <td> {{item.calDate}} </td>
                  <td> {{item.name}} </td>
                  <td> {{item.sname}} </td>
                  <td> {{item.f_count}} </td>
                  <td> {{item.l_count}} </td>
                  <td> {{item.pow}} </td>
                  <td> {{item.amount}} </td>
                  <td>
                    <div>
                      <b-button class="btn_def" @click="add">상세정보</b-button>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </b-row>

      <!-- modals -->
      <!--
      <modal-fee-add ref="modal_feeAdd" :obj="obj" @reflash="reflash"/>
      -->
    </b-container>
  </div>
</template>

<script>
import Form from "@/components/Form/index";
import regex from "@/lib/regex";

import Modals from "@/components/Modals/index";
import { ref } from 'vue';

import * as XLSX from 'xlsx' // 라이브러리 import

import $ from 'jquery';

export default {
  components: {
    
    // Modals
    //ModalFeeAdd: Modals.FeeAdd,
  },
  data() {
    const isDev = process.env.NODE_ENV !== "production";

    return {
      admin_data: {},
      date_type: [],
      cal_list: [],
      add_date: [],
      calLast_list: [],
      cal_crt_list: [],
      cal_list: [],
      company_cal_crt_list: [],
      total_data: [],
      cal_total_list: [],
      items1: null,
      items2: null,
      searchCalcurateOption: [],
      searchPayOption: [
        { text: "전체", value: "all" },
        { text: "결제완료", value: "Y" },
        { text: "미결제", value: "N" }
      ],
      filterData: {
        searchCalcurate: "all",
        searchPay: "all",
      },
      obj: {
        bid: "",
        bid_fee_list: []
      },
    };
  },
  computed: {
    
  },
  watch: {
    
  },
  async mounted() {
    console.log("정산관리 페이지 로드");
    if (this.$store.getters.user === null) {
      this.admin_data.user_id = "admin";
      this.admin_data.company_id = "CPS00002";
    } else {
      this.admin_data = this.$store.getters.user;
    }

    this.setDateType();
  },
  methods: {
    setDateType() {
      let first_year = 2024;										// 첫 해 설정
      let first_month = 3;										// 첫 월 설정
      let crt_date = this.getToday().substr(0, 8);
      let crt_year_str = crt_date.substr(0,4);
      let crt_year = parseInt(crt_year_str);
      let crt_month_str = crt_date.substr(4,2);
      let crt_month = parseInt(crt_month_str);

      let temp_json = [{text: "전체", value: "all"}];
      let temp_json2 = [];

      for (let i=0; i < crt_year - first_year + 1; i++) {
        let temp_year = (first_year + i).toString();

        for (let j=1; j < 13; j++) {
          let temp_data = {};

          if (crt_year == (first_year + i)) {		// 현재년도
            if (crt_month >= j) {
              temp_data.text = temp_year + "년 " + (j).toString().padStart(2, "0") + "월";
              temp_data.value = temp_year + (j).toString().padStart(2, "0");
              temp_json.push(temp_data);
              temp_json2.push(temp_data);
            };
          } else {								// 이전년도
            if (i == 0) {						// 2024년도
              if (3 <= j) {
                temp_data.text = temp_year + "년 " + (j).toString().padStart(2, "0") + "월";
                temp_data.value = temp_year + (j).toString().padStart(2, "0");
                temp_json.push(temp_data);
                temp_json2.push(temp_data);
              };
            } else {
              temp_data.text = temp_year + "년 " + (j).toString().padStart(2, "0") + "월";
              temp_data.value = temp_year + (j).toString().padStart(2, "0");
              temp_json.push(temp_data);
              temp_json2.push(temp_data);
            };
          };
        };
      };

      this.searchCalcurateOption = temp_json;
      this.date_type = temp_json2;

      this.selectCalList();
    },
    async selectCalList() {debugger;
      let cal_date_str = null;
      if (this.filterData.searchCalcurate != "all") {
        cal_date_str = this.filterData.searchCalcurate;
      }

      try {
        const { data } = await this.$axios.post(
          "/api/selectCalList", {
            company_type: "S", 
            cal_date: cal_date_str
          }
        );
        if (data.length > 0) {
          let crt_date_str = this.getToday();
          let crt_date = crt_date_str.substr(0,8);
          let crt_year_str = crt_date.substr(0,4);
          let crt_year = parseInt(crt_year_str);
          let crt_month_str = crt_date.substr(4,2);
          let crt_month = parseInt(crt_month_str);

          let dateData = this.date_type;
          let addDate = [];
          let cal_data = [];
          for (let j=0; j < dateData.length - 1; j++) {
            let cal_data = [];
            data.forEach((e,i) => {
              if (e.cal_date === dateData[j].value) {
                cal_data.push(e);  
              }
            });

            if (cal_data.length == 0) {
              addDate.push(dateData[j].value);
            }
          };
          this.cal_list = cal_data;
          this.add_date = addDate;

          if (addDate.length == 1) {
            this.selectCalLastData(addDate[0]);
          } else if (addDate.length > 1) {
            this.selectCalLastData();
          } else if (addDate.length == 0) {
            this.selectCalCrtData();
          }
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async selectCalLastData(search_date) {
      try {
        const { data } = await this.$axios.post(
          "/api/selectCalLastList", {
            company_type: "S", 
            s_month: search_date
          }
        );
        if (data.length > 0) {debugger;
          for (let a=0; a < this.add_date.length; a++) {
            let list_data = {};
            let calLast_data = [];
            let addDate = this.add_date[a];
            data.forEach((e,i) => {
              if (e.cal_date === addDate) {
                calLast_data.push(e);  
              }
            });

            if (calLast_data.length == 0) {
              list_data = { 
                company_id: "CPS00002",
                cal_date: addDate,
                amount: 0,
                pay_type: "",
                pay_info: "",
                pay_date: "",
                status: "N",
                chg_count: 0,
                pow: 0
              };

              this.calLast_list.push(list_data);
            } else {
              for (let b=0; b < calLast_data.length; b++) {
                list_data = { 
                  company_id: calLast_data[b].company_id,
                  cal_date: calLast_data[b].cal_date,
                  amount: calLast_data[b].amount_sum,
                  pay_type: "1",
                  pay_info: calLast_data[b].account_no,
                  pay_date: "",
                  status: "N",
                  chg_count: calLast_data[b].chg_count,
                  pow: calLast_data[b].pow_sum
                };

                this.calLast_list.push(list_data);
              };
            };
          };

          this.addCalLastList(this.calLast_list);
        } else if (data.length == 0) {
          window.alert("데이터가 없습니다.");
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    // 전달 정산 데이터 DB 저장
	  async addCalLastList(list) {
      try {
        const { data } = await this.$axios.post(
          "/api/addCalLastList", list
        );
        
        this.selectCalCrtData();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
	  },
    async selectCalCrtData() {
      let crt_date = this.getToday().substr(0,6);

      try {
        const { data } = await this.$axios.post(
          "/api/selectCalCrtList", {
            company_id: this.admin_data.company_id, 
            company_type: "S", 
            s_month: crt_date
          }
        );
        if (data.length > 0) {debugger;
          this.cal_crt_list = data;
          this.selectTotalCalList();			// 업체별 정산 데이터 조회
        } else if (data.length == 0) {
          this.cal_crt_list = [{bid: this.admin_data.company_id, sid: "", cal_date: crt_date, pow_sum: 0.0, amount_sum: 0, r_pow_sum: 0.0, r_amount_sum: 0, chg_count: 0, company_id: this.admin_data.company_id, station_name: "", company_name: "", bank_name: "", account_no: "", account_day: 0, manager_name: "", phone: "", laf: ""}];
          this.selectTotalCalList();			// 업체별 정산 데이터 조회
          //window.alert("데이터가 없습니다.");
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async selectTotalCalList() {
      let crt_date = this.getToday().substr(0,6);

      try {
        const { data } = await this.$axios.post(
          "/api/selectCalList", {
            company_type: "S"
          }
        );
        if (data.length > 0) {debugger;
          this.cal_list = data;
          this.setTotalData();			// 정산 전체 데이터 셋
        } else if (data.length == 0) {
          window.alert("데이터가 없습니다.");
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    setTotalData() {
      let temp_data = this.cal_list;
      let dateType_data = this.date_type;
      let company_list = this.cal_crt_list.filter(
        (e, i, callback) =>
          i === callback.findIndex(
            (e1) => e1.company_id === e.company_id
          )
      );

      let total_json = [];

      // 총 합계 정산 데이터 취합
      for (let a=0; a < dateType_data.length - 1; a++) {
        let total_data = {};
        let total_pow = 0;
        let total_pCount = 0;
        let total_pAmount = 0;
        let total_nCount = 0;
        let total_nAmount = 0;
        let total_amount = 0;
        let total_chgCount = 0;
        let temp_calData = [];
        this.cal_list.forEach((e,i) => {
          if (e.cal_date === dateType_data[a].value) {
            temp_calData.push(e);  
          }
        });

        for (let b=0; b < temp_calData.length; b++) {
          if (temp_calData[b].status == "Y") {
            total_pCount++;
            total_pAmount = total_pAmount + parseInt(temp_calData[b].amount);
          } else if (temp_calData[b].status == "N") {
            total_nCount++;
            total_nAmount = total_nAmount + parseInt(temp_calData[b].amount);
          };
          total_pow = total_pow + parseInt(temp_calData[b].pow);
          total_amount = total_amount + parseInt(temp_calData[b].amount);
          total_chgCount = total_chgCount + parseInt(temp_calData[b].chg_count);
        };

        total_data.cal_date = temp_calData[0].cal_date;
        total_data.company_count = temp_calData.length;
        total_data.pow = total_pow;
        total_data.pay_count = total_pCount;
        total_data.pay_amount = total_pAmount;
        total_data.not_pay_count = total_nCount;
        total_data.not_pay_amount = total_nAmount;
        total_data.total_amount = total_amount;
        total_data.total_chgCount = total_chgCount;

        total_json.push(total_data);
      };

      let company_arr = [];
      let crtTotal_data = {};
      let crtTotal_pow = 0;
      let crtTotal_chgCount = 0;
      let crtTotal_amount = 0;
      let crtTotal_lCount = 0;
      let crtTotal_fCount = 0;
      let crtTotal_nCount = 0;

      // 업체별 이번달 정산 데이터 연산 취합
      for (let j=0; j < company_list.length; j++) {
        crtTotal_nCount++;
        let temp_crtData = [];
        this.cal_crt_list.forEach((e,i) => {
          if (e.company_id === company_list[j].company_id) {
            temp_crtData.push(e);  
          }
        });

        this.company_cal_crt_list = temp_crtData;
        let sid_arr = temp_crtData.filter(
          (e, i, callback) =>
            i === callback.findIndex(
              (e1) => e1.sid === e.sid
            )
        );

        for (let k=0; k < sid_arr.length; k++) {
          let companyData = {};
          let temp_crtData = [];
          this.cal_crt_list.forEach((e,i) => {
            if (e.sid === sid_arr[k].sid) {
              temp_crtData.push(e);  
            }
          });

          let pow_sum = 0;
          let chgCount_sum = 0;
          let amount_sum = 0;
          let l_count = 0;
          let f_count = 0;

          for (let l=0; l < temp_crtData.length; l++) {
            pow_sum = pow_sum + temp_crtData[l].pow_sum;
            chgCount_sum = chgCount_sum + temp_crtData[l].chg_count;
            amount_sum = amount_sum + temp_crtData[l].amount_sum;

            if (temp_crtData[l].laf == "L") {
              l_count = l_count + temp_crtData[l].chg_count;
            } else if (temp_crtData[l].laf == "F") {
              f_count = f_count + temp_crtData[l].chg_count;
            };

            crtTotal_pow = crtTotal_pow + pow_sum;
            crtTotal_amount = crtTotal_amount + amount_sum;
            crtTotal_chgCount = crtTotal_chgCount + chgCount_sum;
            crtTotal_lCount = crtTotal_lCount + l_count;
            crtTotal_fCount = crtTotal_fCount + f_count;
          };

          companyData.id = k + 1;
          companyData.companyId = temp_crtData[0].company_id;
          companyData.name = temp_crtData[0].company_name;
          companyData.calDate = dateType_data[dateType_data.length - 1].value;
          companyData.amount = amount_sum;
          companyData.chgCount = chgCount_sum;
          companyData.pow = pow_sum;
          companyData.l_count = l_count;
          companyData.f_count = f_count;
          companyData.sid = temp_crtData[0].sid;
          companyData.sname = temp_crtData[0].station_name;

          company_arr.push(companyData);
        };
      };

      crtTotal_data.cal_date = dateType_data[dateType_data.length - 1].value;
      crtTotal_data.company_count = company_list.length;
      crtTotal_data.pow = crtTotal_pow;
      crtTotal_data.pay_count = 0;
      crtTotal_data.pay_amount = 0;
      crtTotal_data.not_pay_count = crtTotal_nCount;
      crtTotal_data.not_pay_amount = crtTotal_amount;
      crtTotal_data.total_amount = crtTotal_amount;

      total_json.push(crtTotal_data);

      //dlt_defaultData.setJSON(company_arr);
      this.total_data = total_json;
      this.cal_total_list = company_arr;

      this.setFilter();											// 데이터 필터링 셋
    },
    setFilter() {
      let select_calDate = this.filterData.searchCalcurate;
      let select_pay = this.filterData.searchPay;

      let temp_item1 = [];
      let temp_item2 = [];

      if (select_calDate === 'all') {
        temp_item1 = this.total_data;
        temp_item2 = this.cal_total_list;
      } else {
        temp_item1 = this.total_data.filter(e => {
          return e.cal_date === select_calDate;
        });

        temp_item2 = this.cal_total_list.filter(e => {
          return e.cal_date === select_calDate;
        });
      }

      if (select_pay === 'all') {
        this.items1 = temp_item1;
        this.items2 = temp_item2;
      } else {
        this.items1 = temp_item1.filter(e => {
          return e.status === select_pay;
        });

        this.items2 = temp_item2.filter(e => {
          return e.status === select_pay;
        });
      }
    },
    add() {
      this.obj.bid = this.filterData.searchCompany;
      this.obj.bid_fee_list = this.temp_fee;
      
      this.$refs.modal_feeAdd.setData(this.obj);
      this.$bvModal.show("modal-fee-add");
    },
    edit() {
      this.updateChargeFee();

      window.alert("요금이 수정되었습니다.");

      let content = "업체명: " + this.items[0].company_name + ", 요금제명: " + this.items[0].cf_title;
      this.addAdminLog("요금 관리", "요금 금액 수정", "요금 수정팝업", content, this.admin_data.user_id);			// 관리자 명령 로그 추가
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
    changeCompany() {
      let temp_cfData = [];
      let tempData = [];
      let options = [];

      let search_company = this.filterData.searchCompany;
      this.charge_fee.forEach((e,i) => {
        if (e.bid === search_company) {
          tempData.push(e);
        }
      });

      this.temp_fee = tempData;

      let cf_data = tempData.filter(
        (e, i, callback) =>
          i === callback.findIndex(
            (e1) => e1.cf_id === e.cf_id
          )
      );

      for(let j = 0; j < cf_data.length; j++) {
        let temp_data = [];
        this.charge_fee.forEach((e,i) => {
          if (e.cf_id === cf_data[j].cf_id) {
            temp_data.push(e);
            temp_cfData.push(e);
          }
        });

        //temp_cfData.push(temp_data[0]);
        options.push({ text: temp_data[0].cf_title, value: temp_data[0].cf_id});
      };

      this.cf_list = temp_cfData;
      this.searchFeeOption = options;
      this.filterData.searchFee = this.searchFeeOption[0].value;

      this.setChargeFee();
    },
    async updateChargeFee() {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/updateChargeFee", items
        );
      } catch (error) {
        // console.log(7);
        window.alert("요금 수정을 실패했습니다.");
        console.log(error);
      }
    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("관리자 명령 등록을 실패했습니다.");
      }
    },
    reflash() {
      this.selectChargeFee();
    }
  },
  beforeDestroy() {
    //clearInterval(this.timerActive);
  },
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: goldenrod;
}
</style>