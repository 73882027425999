<template>
  <b-modal
    id="modal-admin-add"
    :title="obj.title"
    :ok-title="obj.ok_title"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="primary m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="px-4 pt-4 pb-0 border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="primary text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="false"
    size="lg"
    @shown="init"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxWidth: '800px', maxHeight: '1000px' }"
    >
      <div>
        <b-tabs v-model="tabIndex" justified class="tab-detail">
          <b-tab title="관리자" active>
            <div class="mt-1">
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />권한종류
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-select size="sm" v-model="userData.authority" :options="authOptions"></b-form-select>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />회원 ID
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input style="height: 50px; border-radius: 10px;" v-model="userData.user_id"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />비밀번호
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input type="password" style="height: 50px; border-radius: 10px;" v-model="userData.password"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />비밀번호 확인
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input type="password" style="height: 50px; border-radius: 10px;" v-model="password_check"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />이름
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input style="height: 50px; border-radius: 10px;" v-model="userData.name"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />휴대폰 번호
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input type="phone" style="height: 50px; border-radius: 10px;" v-model="userData.phone"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />이메일
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input type="email" style="height: 50px; border-radius: 10px;" v-model="userData.email"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />주민등록번호
                  </span>
                </div>
                <div class="modal-input-content" style="gap: 10px;">
                  <b-form-input type=number style="height: 50px; border-radius: 10px; flex: 5;" v-model="birth_text"></b-form-input>
                  <span style="flex: 1;"> ~ </span>
                  <div class="d-flex" style="flex: 5;">
                    <b-form-input type=number style="height: 50px; border-radius: 10px; flex: 1;" v-model="sex_text"></b-form-input>
                    <span style="flex: 5; font-weight: bold; font-size: medium;"> ****** </span>
                  </div>
                </div>
              </b-row>
              <b-row class="modal-input-row" style="height: 115px;">
                <div class="modal-input-title" style="height: 105px;">
                  <span class="text-17">
                    <font-awesome-icon />주소
                  </span>
                </div>
                <div class="modal-input-content flex-column" style="gap: 5px;">
                  <div class="d-flex" style="gap: 10px; width: 100%;">
                    <b-form-input readonly style="height: 50px; border-radius: 10px;" v-model="userData.addr"></b-form-input>
                    <b-button class="btn_def" style="width: 106px;" @click="getPostCode">검색</b-button>
                  </div>
                  <div class="d-flex" style="gap: 10px; width: 100%;">
                    <b-form-input style="height: 50px; border-radius: 10px;" v-model="userData.addr_dtl"></b-form-input>
                    <b-button class="btn_def" style="width: 100px;" @click="showMap">지도보기</b-button>
                  </div>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />상태정보
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-select size="sm" v-model="userData.use_yn" :options="useOptions"></b-form-select>
                </div>
              </b-row>
            </div>
          </b-tab>
          <b-tab title="CS담당자">
            <div class="mt-1">
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />회원 ID
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input style="height: 50px; border-radius: 10px;" v-model="userData.user_id"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />비밀번호
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input type="password" style="height: 50px; border-radius: 10px;" v-model="userData.password"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />비밀번호 확인
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input type="password" style="height: 50px; border-radius: 10px;" v-model="password_check"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />이름
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input style="height: 50px; border-radius: 10px;" v-model="userData.name"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />휴대폰 번호
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input type="phone" style="height: 50px; border-radius: 10px;" v-model="userData.phone"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />이메일
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input type="email" style="height: 50px; border-radius: 10px;" v-model="userData.email"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />주민등록번호
                  </span>
                </div>
                <div class="modal-input-content" style="gap: 10px;">
                  <b-form-input type=number style="height: 50px; border-radius: 10px; flex: 5;" v-model="birth_text"></b-form-input>
                  <span style="flex: 1;"> ~ </span>
                  <div class="d-flex" style="flex: 5;">
                    <b-form-input type=number style="height: 50px; border-radius: 10px; flex: 1;" v-model="sex_text"></b-form-input>
                    <span style="flex: 5; font-weight: bold; font-size: medium;"> ****** </span>
                  </div>
                </div>
              </b-row>
              <b-row class="modal-input-row" style="height: 115px;">
                <div class="modal-input-title" style="height: 105px;">
                  <span class="text-17">
                    <font-awesome-icon />주소
                  </span>
                </div>
                <div class="modal-input-content flex-column" style="gap: 5px;">
                  <div class="d-flex" style="gap: 10px; width: 100%;">
                    <b-form-input readonly style="height: 50px; border-radius: 10px;" v-model="userData.addr"></b-form-input>
                    <b-button class="btn_def" style="width: 106px;" @click="getPostCode">검색</b-button>
                  </div>
                  <div class="d-flex" style="gap: 10px; width: 100%;">
                    <b-form-input style="height: 50px; border-radius: 10px;" v-model="userData.addr_dtl"></b-form-input>
                    <b-button class="btn_def" style="width: 100px;" @click="showMap">지도보기</b-button>
                  </div>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />상태정보
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-select size="sm" v-model="userData.use_yn" :options="useOptions"></b-form-select>
                </div>
              </b-row>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <div class="pt-3">
      <div ref="addr" />
    </div>

    <!-- modals -->
    <modal-show-map :obj="obj_addr" />
  </b-modal>
</template>

<script>
import KakaoMap from "@/components/KakaoMap.vue";
import ModalShowMap from "./ShowMap.vue";

export default {
  components: {
    KakaoMap,
    ModalShowMap,
  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
    tabIndex: function(newVal, oldVal) {debugger;
      if (newVal === 0) {
        this.userData.type = "A";
        this.userData.authority = "S";
      } else if (newVal === 1) {
        this.userData.type = "S";
        this.userData.authority = "C";
      }
    }
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      edit_type: String,
      title: String,
      ok_title: String,
      user_data: Object,
    },
    value: {
      type: Object,
    },
  },
  data() {
    return {
      tabIndex: 0,
      admin_data: {},
      userData: {},
      password_check: "",
      birth_text: "",
      sex_text: "",
      authOptions: [
        { value: "S", text: "최상급 관리자" },
        { value: "A", text: "일반 관리자" }
      ],
      useOptions: [
        { value: "Y", text: "정상" },
        { value: "W", text: "대기" },
        { value: "N", text: "탈퇴" }
      ],
      addressObj: {},
      obj_addr: {
        address: "",
        addressDetail: ""
      },
      wrap: null,
      rules: {
        address: [
          (v) =>
            !!v || {
              title: "주소 입력",
              message: "주소를 입력하세요.",
            },
        ],
        // addressDetail: [
        //   (v) =>
        //     !!v || {
        //       title: "상세 주소 입력",
        //       message: "상세 주소를 입력하세요.",
        //     },
        // ],
      },
    };
  },
  mounted() {
    if (this.$store.getters.user === null) {
      this.admin_data.user_id = "admin";
    } else {
      this.admin_data = this.$store.getters.user;
    }

    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.wrap = this.$refs.addr;

        let crt_date = this.getToday();

        this.userData = this.obj.user_data;

        this.userData.type = "A";
        this.userData.authority = this.authOptions[0].value;
        this.userData.use_yn = this.useOptions[0].value;
        this.birth_text = this.userData.birth.substring(2,8);

        let sex_type = parseInt(this.userData.birth.substring(0,2));
        if (this.userData.sex === "M") {
          if (sex_type > 19) {
            this.sex_text = "3";
          } else {
            this.sex_text = "1";
          }
        } else if (this.userData.sex === "F") {
          if (sex_type > 19) {
            this.sex_text = "4";
          } else {
            this.sex_text = "2";
          }
        }
      });
    },
    ok() {
      if (this.userData.user_id === "" || this.userData.name === "" || this.userData.password === "" || this.password_check === "" || this.userData.phone === "" || this.userData.email === "") {
				window.alert("정보를 모두 입력하신 후 다시 시도하시기 바랍니다.");
			} else if (this.userData.password === this.password_check) {
				window.alert("비밀번호를 재입력 해주시기 바랍니다.");
			} else {
        this.selectUserIdChk();
      }
    },
    async selectUserIdChk() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectUserIdChk", {
            user_id : this.userData.user_id
            //maker : 
          }
        );

        if (data.length > 0) {
          this.addAdmin();
        } else if (data.length == 0) {
          window.alert("아이디를 재입력 해주시기 바랍니다.");
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async addAdmin() {
      let crt_date = this.getToday();

      if (this.sex_text === "1" || this.sex_text === "3") {
        this.userData.sex = "M";
      } else if (this.sex_text === "2" || this.sex_text === "4") {
        this.userData.sex = "F";
      };

      if (this.sex_text === "1" || this.sex_text === "2") {
        this.userData.birth = 19000000 + parseInt(this.birth_text);
      } else if (this.sex_text === "3" || this.sex_text === "4") {
        this.userData.birth = 20000000 + parseInt(this.birth_text);
      };

      try {
        const { data } = await this.$axios.post(
          "/api/addUser", {
            user_id: this.userData.user_id,
				   	password: this.userData.password,
						company_id: "",
						type: this.userData.type,
						name: this.userData.name,
						authority: this.userData.authority,
						phone: this.userData.phone,
						addr: this.userData.addr,
						addr_dtl: this.userData.addr_dtl,
						email: this.userData.email,
						card_no: "",
						card_stop_yn: "",
						reg_date: crt_date,
						update_date: crt_date,
						use_yn: this.userData.use_yn,
						payment_info_yn: "",
						car_info_yn: "",
						level: 1,
						sex: this.userData.sex,
						birth: this.userData.birth,
						eco_yn: "N"
          }
        );

        let type_text = "";
        if (this.userData.type === "A") {
          if (this.userData.authority === "S") {
            type_text = "최고 관리자";
          } else if (this.userData.authority === "A") {
            type_text = "일반 관리자";
          }
        } else if (this.userData.type === "S") {
          type_text = "CS 담당자";
        }

        window.alert("관리자 정보가 등록되었습니다.");
        var context = "관리자 ID : " + this.userData.user_id + ", 관리자 종류 : " + type_text;
        this.addAdminLog("관리자 관리", "관리자 등록", "관리자 등록 팝업", context, this.admin_data.user_id);
        this.$emit("reflash");
        this.$bvModal.hide("modal-admin-add");
      } catch (error) {
        // console.log(7);
        window.alert("관리자 등록을 실패했습니다.");
        console.log(error);
      }
    },
    showMap() {
      if (this.userData.addr === "") {
        window.alert("주소를 검색하신 후 다시 시도해 주시기 바랍니다.");
      } else {
        //this.isShowMap = true;
        this.obj_addr.address = this.userData.addr;
        this.obj_addr.addressDetail = this.userData.addr_dtl;
        this.$bvModal.show("modal-show-map");
      }
    },
    foldDaumzonecode() {
      // iframe을 넣은 element를 안보이게 한다.
      this.wrap.style.display = "none";
    },
    getPostCode() {
      // 현재 scroll 위치를 저장해놓는다.
      var currentScroll = Math.max(
        document.body.scrollTop,
        document.documentElement.scrollTop
      );
      new daum.Postcode({
        //autoMappingJibun: false, //연관주소가 지번일 때 지번 주소 자동매핑을 막음
       // autoMappingRoad: false, //연관주소가 도로명일 때  주소 자동매핑을 막음

        oncomplete: (data) => {
          console.log(data);
          // 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

          // 각 주소의 노출 규칙에 따라 주소를 조합한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          var addr = ""; // 주소 변수
          var extraAddr = ""; // 참고항목 변수

          //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
          if (data.userSelectedType === "R") {
            // 사용자가 도로명 주소를 선택했을 경우
            addr = data.roadAddress;
          } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            addr = data.jibunAddress;
          }

          // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
          if (data.userSelectedType === "R") {
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            // 조합된 참고항목을 해당 필드에 넣는다.
          }
          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          //===================================================================
          //jibunAddressEnglish 또는 autoJibunAddressEnglish에 랜덤으로 값이 담겨 필터링 추가함
          let jibun = "";

          if (data.autoJibunAddressEnglish === "") {
            jibun = data.jibunAddressEnglish.split(",")[0];
          } else {
            jibun = data.autoJibunAddressEnglish.split(",")[0];
          }

          //====================================================================
          this.add = jibun;
          this.addressObj = {
            gps_x: "",
            gps_y: "",
            zipNo: data.zonecode,
            address: `${addr} ${extraAddr}`,
            sido: data.sido,
            jibunAddress:
              data.jibunAddress !== ""
                ? data.jibunAddress
                : data.autoJibunAddress,
            roadAddress:
              data.roadAddress != "" ? data.roadAddress : data.autoRoadAddress,
            addressDetailMeta: {
              bcode: data.bcode,
              bname: data.bname,
              bname1: data.bname1,
              buildingCode: data.buildingCode,
              buildingName: data.buildingName,
              bun: jibun.split("-")[0] || "",
              ji: jibun.split("-")[1] || "",
              sido: data.sido,
              sigungu: data.sigungu,
              sigunguCode: data.sigunguCode,
            },
          };

          this.userData.addr = this.addressObj.address;
          
          const geocoder = new kakao.maps.services.Geocoder();

          let gps_x = "";
          let gps_y = "";

          // 주소로 좌표를 검색합니다
          geocoder.addressSearch(`${addr} ${extraAddr}`, (result, status) => {
            // 정상적으로 검색이 완료됐으면
            if (status === kakao.maps.services.Status.OK) {
              console.log("OK",result)
              const coords = new kakao.maps.LatLng(result[0].y, result[0].x);
              //const message = 'latlng: new kakao.maps.LatLng(' + result[0].y + ', ';
              //message += result[0].x + ')';

              gps_x = result[0].x;
              gps_y = result[0].y;

              this.userData.gps = gps_x + "," + gps_y;
              this.addressObj.gps_x = gps_x;
              this.addressObj.gps_y = gps_y;
            }
          });

          // 커서를 상세주소 필드로 이동한다.
          // this.$refs.addrDetail.$el.focus();

          // iframe을 넣은 element를 안보이게 한다.
          // (autoClose:false 기능을 이용한다면, 아래 코드를 제거해야 화면에서 사라지지 않는다.)
          //this.wrap.style.display = "none";

          // 우편번호 찾기 화면이 보이기 이전으로 scroll 위치를 되돌린다.
          document.body.scrollTop = currentScroll;
        },

        // 우편번호 찾기 화면 크기가 조정되었을때 실행할 코드를 작성하는 부분. iframe을 넣은 element의 높이값을 조정한다.
        // onresize: (size) => {
        //   this.wrap.style.height = size.height + "px";
        // },
        // width: "100%",
        // height: "100%",
      }).open({ autoClose: true }); //주소 선택시 팝업창 닫기
      // iframe을 넣은 element를 보이게 한다.
      // this.wrap.style.display = "block";
      // test(address);
    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("관리자 명령 로그 등록을 실패했습니다.");
      }
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
  },
};
</script>

<style lang="scss" scoped></style>
