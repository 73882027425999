<template>
  <div class="" style="height: inherit;" ref="element" v-resize @resize="onResize">
    <b-container>
      <b-row style="margin: 0px 0px 10px 0px;">
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">종류</span>
          <b-form-select size="sm" style="flex: 4;" v-model="filterData.searchType" @change="filterSet" :options="searchTypeOption"></b-form-select>
        </b-col>
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">상태</span>
          <b-form-select size="sm" style="flex: 4;" v-model="filterData.searchStatus" @change="filterSet" :options="searchStatusOption"></b-form-select>
        </b-col>
      </b-row>
      <b-row style="margin: 0px 0px 10px 0px;">
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">검색조건</span>
          <div class="d-flex align-items-center" style="gap: 10px; flex: 4; padding: 5px;">
            <b-form-select size="sm" style="flex: 1;" v-model="filterData.searchKeyType" @change="filterSet" :options="searchKeyOption"></b-form-select>
            <b-form-input 
              size="sm"
              v-model="filterData.searchKey"
              style="flex: 3; height: 50px; border-radius: 10px;"
              @change="filterSet"
            ></b-form-input>
          </div>
        </b-col>
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          
        </b-col>
      </b-row>
      <b-row style="margin: 0px 0px 10px 0px;">
        <b-col lg=6 class="d-flex justify-content-between flex-row align-items-center">
          <span>전체 {{ gridFilterData.length }} 건</span>
        </b-col>
        <b-col lg=6 class="d-flex justify-content-end">
          <b-button class="btn_def" @click="detail">등록</b-button>
        </b-col>
      </b-row>
      <b-row style="width: inherit; margin: 0px;" :style="{'height': page_grid_height + 'px'}">
        <div class="table-wrapper2" style="width: -webkit-fill-available;">
          <b-table 
            id = "grid"
            bordered hover noCollapse 
            :items="gData"
            :fields="gridFields"
            small
          >
            <template #cell(No)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ row.index + 1 + ((currentPage - 1) * perPage) }}
              </div>
            </template>
            <template #cell(type_name)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].type_name }}
              </div>
            </template>
            <template #cell(title)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].title }}
              </div>
            </template>
            <template #cell(descript)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].descript }}
              </div>
            </template>
            <template #cell(reg_date)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].reg_date }}
              </div>
            </template>
            <template #cell(status_name)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].status_name }}
              </div>
            </template>
          </b-table>
        </div>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="gridFilterData.length"
          :per-page="perPage"
          first-text="⏮"
          prev-text="⏪"
          next-text="⏩"
          last-text="⏭"
          class="mt-4"
          size="sm"
        ></b-pagination>
      </b-row>

      <!-- modals -->
      <modal-board-detail ref="modal_boardDtl" :obj="obj" @reflash="reflash"/>

    </b-container>
  </div>
</template>

<script>
import Form from "@/components/Form/index";
import regex from "@/lib/regex";

import Modals from "@/components/Modals/index";
import { ref } from 'vue';

import * as XLSX from 'xlsx' // 라이브러리 import

import $ from 'jquery';

export default {
  components: {
    
    // Modals
    ModalBoardDetail: Modals.BoardDetail,
  },
  data() {
    const isDev = process.env.NODE_ENV !== "production";

    return {
      formValid: false,
      salt: 0,
      crt_width: 0,
      crt_height: 0,
      page_grid_height: 0,
      select_index: -1,
      select_rowData: {
        id : 0,
        type : "",
        type_name : "",
        code : "",
        code_name : "",
        title : "",
        descript : "",
        reg_user : "",
        reg_date : "",
        update_date : "",
        post_date : "",
        use_yn : "",
        status_name : "",
      },
      perPage: 1,
      currentPage: 1,
      gridDefaultData: [

      ],
      gridFilterData: [

      ],
      gData: [

      ],
      gridFields: [
        {
          key: "No",
          label: "No"
        },
        {
          key: "type_name",
          label: "종류"
        },
        {
          key: "title",
          label: "문의"
        },
        {
          key: "descript",
          label: "답변"
        },
        {
          key: "reg_date",
          label: "등록일시"
        },
        {
          key: "status_name",
          label: "상태"
        },
      ],
      filterData: {
        searchKeyType: "all",
        searchKey: "",
        searchType: "all",
        searchStatus: "all",
      },
      searchKeyOption: [
        { text: "전체", value: "all" },
        { text: "제목", value: "title" },
        { text: "내용", value: "context" },
        { text: "제목 + 내용", value: "tnc" }
      ],
      searchTypeOption: [
        { text: "전체", value: "all" },
        { text: "faq1", value: "FC01" },
        { text: "faq2", value: "FC02" }
      ],
      searchStatusOption: [
        { text: "전체", value: "all" },
        { text: "게시", value: "Y" },
        { text: "대기", value: "W" },
        { text: "숨김", value: "N" }
      ],
      rules: {
        
      },
      obj: {
        type: "FAQ",
        edit_type: "add",
        title: "FAQ 등록",
        ok_title: "등록",
        param_data: {
          id : 0,
          type : "",
          type_name : "",
          code : "",
          code_name : "",
          title : "",
          descript : "",
          reg_user : "",
          reg_date : "",
          update_date : "",
          post_date : "",
          use_yn : "",
          status_name : "",
        },
      },
    };
  },
  computed: {
    
  },
  watch: {
    currentPage: function(newVal, oldVal) {
      let items = this.gridFilterData;

      this.selectPage(newVal);
    },
  },
  methods: {
    async selectBoard() {
      console.log("FAQ 페이지");

      try {
        const { data } = await this.$axios.post(
          "/api/selectBoard", {
            type: "F"
          }
        );
        if (data.length > 0) {
          this.gData = data;
          this.gridDefaultData = data;
          this.filterSet();
        } else if (data.length == 0) {
          this.gData = null;
          window.alert("데이터가 없습니다.");
        }

        this.pageSet();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    detail() {
      this.obj.type = "FAQ";
      this.obj.edit_type = "add";
      this.obj.title = "FAQ 등록";
      this.obj.ok_title = "등록";
      this.obj.param_data = { 
        id : 0,
        type : "",
        type_name : "",
        code : "",
        code_name : "",
        title : "",
        descript : "",
        reg_user : "",
        reg_date : "",
        update_date : "",
        post_date : "",
        use_yn : "",
        status_name : "",
      };
      this.$refs.modal_boardDtl.setData(this.obj.param_data);
      this.$bvModal.show("modal-board-detail");
    },
    selectRow(row_index) {
      this.obj.edit_type = "edit";
      this.obj.title = "FAQ 수정";
      this.obj.ok_title = "수정";
      this.obj.param_data = this.gData[row_index];

      this.$refs.modal_boardDtl.setData(this.gData[row_index]);
      this.$bvModal.show("modal-board-detail");
    },
    clipboardCopy(index) {
      var copy_data = JSON.stringify(this.gData[index]);
      window.navigator.clipboard.writeText(copy_data).then( function (res) {alert("복사되었습니다.");} );
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
    filterSet() {
      let search_key = this.filterData.searchKey;
      let search_type = this.filterData.searchType;
      let search_status = this.filterData.searchStatus;
      let temp_data = [];

      //this.gridDefaultData.

      if (search_key != "") {
        if (search_type === "all") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.title.indexOf(search_key) != -1 || e.descript.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "title") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.title.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "context") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.descript.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "tnc") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.title.indexOf(search_key) != -1 || e.descript.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        }

        this.gridFilterData = temp_data;
      } else {
        this.gridFilterData = this.gridDefaultData;
      }

      if (search_type != "all") {
        temp_data = this.gridFilterData.filter(
          function (e) {
            if (e.code === search_type) {
              return true;
            }
          }
        )

        this.gridFilterData = temp_data;
      }

      if (search_status != "all") {
        temp_data = this.gridFilterData.filter(
          function (e) {
            if (e.use_yn === search_status) {
              return true;
            }
          }
        )

        this.gridFilterData = temp_data;
      }

      this.pageSet();
    },
    onResize(e) {
      if (this.crt_width == 0 & this.crt_height == 0) {
        this.crt_width = e.detail.width;
        this.crt_height = e.detail.height;
        
        //this.pageSet();
      } else if (this.crt_width != e.detail.width || this.crt_height != e.detail.height) {
        this.crt_width = e.detail.width;
        this.crt_height = e.detail.height;
        
        this.pageSet();  
      }
    },
    pageSet() {
      var filter_height = 130;
      var button_height = 60;
      var pagination_height = 50;
      var grid_header_height = 60;
      var grid_row_height = 65;
      var grid_data_height = this.crt_height - filter_height - button_height - pagination_height - grid_header_height - 40;
      this.page_grid_height = grid_data_height;

      var page_row_count = Math.floor(grid_data_height / grid_row_height);
      var max_page_num = Math.ceil(this.gridFilterData.length / page_row_count);

      this.perPage = page_row_count;

      this.currentPage = 1;
      this.selectPage(1);
    },
    selectPage(page) {
      let temp_grid_data = this.gridFilterData;

      this.gData = temp_grid_data.slice(
        (page - 1) * this.perPage, page * this.perPage
      )
    },
    reflash() {
      var tbody = document.querySelector("#grid");
      var trs = tbody.getElementsByTagName('tr');
      
      var selectIndex = this.select_index + 1;
      this.select_index = -1;
      this.select_rowData = { id : 0, type : "", type_name : "", code : "", code_name : "", title : "", descript : "", reg_user : "", reg_date : "", update_date : "", post_date : "", use_yn : "", status_name : "" };

      trs[selectIndex].style.backgroundColor = "white";

      this.selectBoard();
    }
  },
  beforeDestroy() {
    //clearInterval(this.timerActive);
  },
  async mounted() {
    let page_data = this.$refs.element;
    this.crt_width = page_data.clientWidth;
    this.crt_height = page_data.clientHeight;
    
    this.selectBoard();
  },
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: goldenrod;
}
</style>