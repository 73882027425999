<template>
  <b-modal
    id="modal-remote-call"
    title="충전기 원격명령"
    footer-class="d-none"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="false"
    size="lg"
    @shown="init"
  >
    <div class="pr-md-2">
      <div>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />충전소 이름
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input size="sm" style="height: 50px; border-radius: 10px;" v-model="chargerData.s_name"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />충전기 ID
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input size="sm" style="height: 50px; border-radius: 10px;" v-model="chargerData.cid"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />단말기 번호
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input size="sm" style="height: 50px; border-radius: 10px;" v-model="chargerData.reader_id"></b-form-input>
          </div>
        </b-row>
        <b-row class="d-flex" style="gap: 10px;">
          <b-button class="btn_def" @click="callRemoteStart">충전 시작</b-button>
          <b-button class="btn_def" @click="callRemoteEnd">충전 정지</b-button>
          <b-button class="btn_def" @click="callReset">충전기 리셋</b-button>
          <b-button class="btn_def" @click="callFwUpdate">펌웨어 업데이트</b-button>
        </b-row>
      </div>
    </div>

    <modal-reset-call ref="modal_resetCall" :obj="obj_data"/>
    <modal-update-call ref="modal_updateCall" :obj="obj_data"/>
  </b-modal>
</template>

<script>

import ModalResetCall from "./ResetCall.vue";
import ModalUpdateCall from "./UpdateCall.vue";

export default {
  components: {
    ModalResetCall,
    ModalUpdateCall
  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      param_data: Object,
    },
  },
  data() {
    return {
      admin_data: {},
      main_url: "http://evcloud.duruscoev.com/ocpp-jt",
      chargerData: {},
      conn_data: [],
      call_type: "",
      full_url: "",
      param_json: {},
      obj_data: {
        param_data: {}
      },
    };
  },
  mounted() {
    if (this.$store.getters.user === null) {
      this.admin_data.user_id = "admin";
    } else {
      this.admin_data = this.$store.getters.user;
    }

    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {debugger;
        this.chargerData = this.obj.param_data;

        this.ocppConnLastLog(this.chargerData.sid, this.chargerData.reader_id);
      });
    },
    async ocppConnLastLog(sid_value, tel_num_value) {
      try {
        const { data } = await this.$axios.post(
          "/api/selectOcppConnLastLog", {
            sid: sid_value, 
            tel_num: tel_num_value
          }
        );
        if (data.length > 0) {
          this.conn_data = data;
        } else if (data.length === 0) {
          this.conn_data = [];
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    callRemoteStart() {
      this.call_type = "remoteStart";
      this.ocppConnReflash(this.chargerData.sid, this.chargerData.reader_id);
    },
    callRemoteEnd() {
      this.call_type = "remoteStop";
      this.ocppConnReflash(this.chargerData.sid, this.chargerData.reader_id);
    },
    callReset() {
      this.obj_data.param_data = this.chargerData;
      this.$bvModal.show("modal-reset-call");
    },
    callFwUpdate() {
      this.obj_data.param_data = this.chargerData;
      this.$bvModal.show("modal-update-call");
    },
    async ocppConnReflash(sid_value, tel_num_value) {
      try {
        const { data } = await this.$axios.post(
          "/api/selectOcppConnLastLog", {
            sid: sid_value, 
            tel_num: tel_num_value
          }
        );
        if (data.length > 0) {
          let crt_date = this.getToday();
          
          if (call_type == "remoteStart") {
            this.full_url = this.main_url + "/remoteStartTransaction";
            let paramJson = {};
            paramJson.sessionIndex = this.conn_data[0].uuid;
            paramJson.connectorId = this.chargerData.ch_id;

            let chargingSchedulePeriod = [];
            let arr_data = {};
            let items = {};
            items.startPeriod = 1;
            items.limit = 1.0;
            items.numberPhases = 1;
            arr_data.items = items;
            chargingSchedulePeriod.push(arr_data);

            let chargingSchedule = {};
            chargingSchedule.duration = 1;
            chargingSchedule.startSchedule = crt_date;
            chargingSchedule.chargingRateUnit = "A";
            chargingSchedule.chargingSchedulePeriod = chargingSchedulePeriod
            chargingSchedule.minChargingRate = 1.0;

            let chargingProfile = {};
            chargingProfile.chargingProfileId = 1;
            //chargingProfile.transactionId = ipb_transactionId.getValue();
            chargingProfile.stackLevel = 1;
            chargingProfile.chargingProfilePurpose = "ChargePointMaxProfile";
            chargingProfile.chargingProfileKind = "Absolute";
            chargingProfile.recurrencyKind = "Daily";
            chargingProfile.validFrom = crt_date;
            chargingProfile.validTo = crt_date;
            chargingProfile.chargingSchedule = chargingSchedule;

            //param_json.connectorId = ipb_connId.getValue();
            paramJson.idTag = "test";
            paramJson.chargingProfile = chargingProfile;

            this.api_call(this.full_url, paramJson, "remoteStart");
          } else if (call_type == "remoteStop") {
            this.chargingHistoryInfo();
          } else if (call_type == "reset") {
            this.selectLastEventEntry();
          };
        } else if (data.length === 0) {
          window.alert("현재 충전기가 연결되어 있지 않습니다. 다시 확인하신 후 시도해 보시기 바랍니다.");
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async chargingHistoryInfo() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectChargingHistory", {
            sid: this.conn_data[0].sid, 
            cid: this.conn_data[0].cid,
            status: "Charging"
          }
        );
        if (data.length > 0) {
          this.full_url = this.main_url + "/remoteStopTransaction";

          let paramJson = {};
          paramJson.sessionIndex = conn_data[0].uuid;
          paramJson.transactionId = temp_data[0].tranId;

          this.api_call(this.full_url, paramJson, "remoteStop");
        } else if (data.length === 0) {
          window.alert("현재 충전중이 아닙니다.");
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    api_call(full_url, param_json, type) {
      let request = new XMLHttpRequest();
	  	request.open('POST', url);
	  	request.setRequestHeader('Content-Type', 'application/json');
	  	request.send(JSON.stringify(param_json));

	  	request.onload = function() {
	  		if (request.status === 200 || request.status === 201) {
		  		let result_text = {};
		  		result_text = request.response;
	  			let result_str = JSON.stringify(result_text, null, 2);
	  			let result = JSON.parse(result_text);
	  			if (type == "remoteStart") {
		  			if (result.status == "Accepted") {
		  				window.alert("충전 시작을 요청했습니다.");
              let context = "충전소ID : " + this.chargerData.sid + ", 충전기ID : " + this.chargerData.cid + ", 단말기번호 : " + this.chargerData.reader_id;
              this.addAdminLog("충전기 관리", "충전기 원격요청", "충전 시작 요청", context, this.admin_data.user_id);
		  			} else if (result.status == "Rejected") {
						  window.alert("충전을 시작하지 못했습니다. 다시 시도해 주시기 바랍니다.");
			  		};
			  		console.log('remoteStart', result_str);
			  	} else if (type == "remoteStop") {
			  		if (result.status == "Accepted") {
		  				window.alert("충전 종료를 요청했습니다.");
              let context = "충전소ID : " + this.chargerData.sid + ", 충전기ID : " + this.chargerData.cid + ", 단말기번호 : " + this.chargerData.reader_id;
              this.addAdminLog("충전기 관리", "충전기 원격요청", "충전 종료 요청", context, this.admin_data.user_id);
		  			} else if (result.status == "Rejected") {
						  window.alert("충전을 종료하지 못했습니다. 다시 시도해 주시기 바랍니다.");
			  		};
				  	console.log('remoteStop', result_str);
				  };

          this.$bvModal.hide("modal-remote-call");
		  	} else {
			  	console.log('error', request.status, request.statusText);
			  };
	  	};
    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("관리자 명령 로그 등록을 실패했습니다.");
      }
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
  },
};
</script>

<style lang="scss" scoped></style>
