<template>
  <b-modal
    id="modal-model-detail"
    :title="obj.title"
    :ok-title="obj.ok_title"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="lg"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxWidth: '800px', maxHeight: '1000px' }"
    >
      <div>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />제조사
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select size="sm" v-model="modelData.maker" :options="makerOptions"></b-form-select>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />모델ID
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="modelData.model_id"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />설비용량(kW)
            </span>
          </div>
          <div class="modal-input-content" style="gap: 10px;">
            <b-form-select size="sm" v-model="modelData.capacity" :options="capacityOptions"></b-form-select>
            <b-form-select size="sm" v-model="modelData.channel_type" :options="channelOptions"></b-form-select>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />급속/완속
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select size="sm" v-model="modelData.laf_type" :options="lafOptions"></b-form-select>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />충전건 타입
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select size="sm" v-model="modelData.gun_type" :options="gunTypeOptions"></b-form-select>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />설치 타입
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select size="sm" v-model="modelData.install_type" :options="installOptions"></b-form-select>
          </div>
        </b-row>
      </div>
    </div>

    
  </b-modal>
</template>

<script>

export default {
  components: {

  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      edit_type: String,
      title: String,
      ok_title: String,
      chargerModel_data: Object,
    },
    value: {
      type: Object,
    },
  },
  data() {
    return {
      modelData: {},
      makerOptions: [],
      capacityOptions: [],
      gunTypeOptions: [],
      installOptions: [],
      lafOptions: [
        { value: "F", text: "급속" },
        { value: "L", text: "완속" }
      ],
      channelOptions: [
        { value: "S", text: "Single" },
        { value: "D", text: "Dual" },
        { value: "M", text: "Multi" }
      ],
      admin_data: {},
      rules: {
        address: [
          (v) =>
            !!v || {
              title: "주소 입력",
              message: "주소를 입력하세요.",
            },
        ],
        // addressDetail: [
        //   (v) =>
        //     !!v || {
        //       title: "상세 주소 입력",
        //       message: "상세 주소를 입력하세요.",
        //     },
        // ],
      },
    };
  },
  mounted() {
    if (this.$store.getters.user === null) {
      this.admin_data.user_id = "admin";
    } else {
      this.admin_data = this.$store.getters.user;
    }

    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.selectCodeList();

        if (this.obj.edit_type === "add") {
          //this.modelData = this.obj.chargerModel_data;
        }
      });
    },
    async selectCodeList() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectCode", {
            use_yn: "Y", 
            type: "CPC"
          }
        );
        if (data.length > 0) {
          var gunData = [];
          var capaData = [];
          var installData = [];

          var add_option = {};

          data.filter(
            function (e) {
              if (e.r_code == "CPC01") {
                add_option = { text: e.code_name, value: e.code };
                gunData.push(add_option);
              } else if (e.r_code == "CPC02") {
                add_option = { text: e.code_name, value: e.code };
                capaData.push(add_option);
              } else if (e.r_code == "CPC04") {
                add_option = { text: e.code_name, value: e.code };
                installData.push(add_option);
              }
            }
          )

          this.gunTypeOptions = gunData;
          this.capacityOptions = capaData;
          this.installOptions = installData;

          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            this.modelData.gun_type = this.gunTypeOptions[0].value;
            this.modelData.install_type = this.installOptions[0].value;
            this.modelData.capacity = this.capacityOptions[0].value;
            this.modelData.laf_type = this.lafOptions[0].value;
            this.modelData.channel_type = this.channelOptions[0].value;
          }
        } else if (data.length == 0) {
          this.gunTypeOptions = null;
          this.capacityOptions = null;
          this.installOptions = null;
        }

        this.selectCompanyList();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async selectCompanyList() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectCompanyList", {
            type : "M",
            use_yn : "Y"
          }
        );
        if (data.length > 0) {
          let options = [];

          data.forEach((e,i) => {
            let add_option = { value: e.company_id, text: e.name }
            options.push(add_option);
          });

          this.makerOptions = options;

          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            this.modelData.maker = this.makerOptions[0].value;
          }
        } else if (data.length == 0) {
          this.makerOptions = null;
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async ok() {
      try {
        if (this.obj.edit_type === "detail") {
          this.$bvModal.hide("modal-model-detail");
        } else {
          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            this.addChargerModel();
            //type_url = "/api/addChargerModel";
          } else if (this.obj.edit_type === "edit") {
            this.updateChargerModel();
            //type_url = "/api/updateChargerModel";
          } else if (this.obj.edit_type === "del") {
            this.delChargerModel();
          }
        }
        //this.$root.$emit("hb::pdf::hidden", true);
      }  catch (error) {
        console.log(error);
      }
    },
    setData(select_rowData) {debugger;
      this.chargerData = select_rowData;

      if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
        this.modelData.maker = this.makerOptions[0].value;
        this.modelData.gun_type = this.gunTypeOptions[0].value;
        this.modelData.install_type = this.installOptions[0].value;
        this.modelData.capacity = this.capacityOptions[0].value;
        this.modelData.laf_type = this.lafOptions[0].value;
        this.modelData.channel_type = this.channelOptions[0].value;
      } else {
        this.modelData.maker = this.obj.chargerModel_data.maker,
				this.modelData.model_id = this.obj.chargerModel_data.model_id,
				this.modelData.capacity = this.obj.chargerModel_data.capacity,
				this.modelData.install_type = this.obj.chargerModel_data.install_type,
				this.modelData.gun_type = this.obj.chargerModel_data.gun_type,
				this.modelData.channel_type = this.obj.chargerModel_data.channel_type,
				this.modelData.laf_type = this.obj.chargerModel_data.laf_type
      }
    },
    async addChargerModel() {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addChargerModel", {
            maker: this.modelData.maker,
						model_id: this.modelData.model_id,
						capacity: this.modelData.capacity,
						install_type: this.modelData.install_type,
						gun_type: this.modelData.gun_type,
						channel_type: this.modelData.channel_type,
						reg_date: crt_date,
						reg_user: this.admin_data.user_id,
						laf_type: this.modelData.laf_type
          }
        );

        window.alert("충전기 모델 정보가 등록되었습니다.");
        var context = "충전기 모델 ID : " + this.modelData.model_id;
        this.addAdminLog("충전기 모델 관리", "충전기 모델 등록", "충전기 모델 등록팝업", context, this.admin_data.user_id);
        this.$emit("reflash");
        this.$bvModal.hide("modal-model-detail");
      } catch (error) {
        // console.log(7);
        window.alert("충전기 모델 정보 등록을 실패했습니다.");
        console.log(error);
      }
    },
    async updateChargerModel() {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/updateChargerModel", {
            maker: this.modelData.maker,
						model_id: this.modelData.model_id,
						capacity: this.modelData.capacity,
						install_type: this.modelData.install_type,
						gun_type: this.modelData.gun_type,
						channel_type: this.modelData.channel_type,
						update_date: crt_date,
						update_user: this.admin_data.user_id,
						laf_type: this.modelData.laf_type
          }
        );

        window.alert("충전기 모델 정보가 수정되었습니다.");
        var context = "충전기 모델 ID : " + this.modelData.model_id;
        this.addAdminLog("충전기 모델 관리", "충전기 모델 등록", "충전기 모델 수정팝업", context, this.admin_data.user_id);
        this.$emit("reflash");
        this.$bvModal.hide("modal-model-detail");
      } catch (error) {
        // console.log(7);
        window.alert("충전기 모델 정보 수정을 실패했습니다.");
        console.log(error);
      }
    },
    async delChargerModel() {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/delChargerModel", {
            maker: this.modelData.maker,
						model_id: this.modelData.model_id,
          }
        );

        window.alert("충전기 모델 정보가 삭제되었습니다.");
        var context = "충전기 모델 ID : " + this.modelData.model_id;
        this.addAdminLog("충전기 모델 관리", "충전기 모델 삭제", "충전기 모델 삭제팝업", context, this.admin_data.user_id);
        this.$emit("reflash");
        this.$bvModal.hide("modal-model-detail");
      } catch (error) {
        // console.log(7);
        window.alert("충전기 모델 정보 삭제를 실패했습니다.");
        console.log(error);
      }
    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("OCPP 충전기 정보 등록을 실패했습니다.");
      }
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
  },
};
</script>

<style lang="scss" scoped></style>
