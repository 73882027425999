<template>
  <b-modal
    id="modal-update-call"
    title="펌웨어 업데이트"
    ok-title="업데이트"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="false"
    size="xl"
    ref="element"
    @shown="init"
  >
    <div class="pr-md-2 overflow-auto">
      <div>
        <b-row style="margin: 0px 0px 10px 0px;">
          <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
            <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">검색조건</span>
            <div class="d-flex align-items-center" style="gap: 10px; flex: 4; padding: 5px;">
              <b-form-select size="sm" style="flex: 1;" v-model="filterData.searchKeyType" @change="changeSearchKey" :options="searchKeyOption"></b-form-select>
              <b-form-input 
                size="sm"
                v-model="filterData.searchKey"
                style="flex: 3; height: 50px; border-radius: 10px;"
                @change="changeSearchKey"
              ></b-form-input>
            </div>
          </b-col>
          <b-col lg=6>
            
          </b-col>
        </b-row>
        <b-row style="margin: 0px 0px 10px 0px;">
          <b-col lg=6 class="d-flex justify-content-between flex-row align-items-center">
            <span>전체 {{ gridFilterData.length }} 건</span>
          </b-col>
          <b-col lg=6 class="d-flex justify-content-end">
            
          </b-col>
        </b-row>
        <b-row style="width: inherit; margin: 0px;">
          <div class="table-wrapper2" style="width: -webkit-fill-available;" >
            <b-table 
              id = "model_grid"
              bordered hover noCollapse 
              :items="gData"
              :fields="gridFields"
              small
            >
              <template #cell(No)="row">
                <div class="d-flex" style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                  {{ row.index + 1 }}
                </div>
              </template>
              <template #cell(sname)="row">
                <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                  {{ gData[row.index].s_name }}
                </div>
              </template>
              <template #cell(sid)="row">
                <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                  {{ gData[row.index].sid }}
                </div>
              </template>
              <template #cell(tel_num)="row">
                <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                  {{ gData[row.index].reader_id }}
                </div>
              </template>
              <template #cell(model_id)="row">
                <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                  {{ gData[row.index].model_id }}
                </div>
              </template>
              <template #cell(fw_ver)="row">
                <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                  {{ gData[row.index].firmware_ver }}
                </div>
              </template>
              <template #cell(conn_yn)="row">
                <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                  {{ gData[row.index].uuid }}
                </div>
              </template>
            </b-table>
          </div>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  components: {
    
  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      maker_list: Array,
      param_data: Object,
    },
  },
  data() {
    return {
      admin_data: {},
      chargerData: {},
      fw_list: [],
      conn_data: [],
      entry_data: [],
      sys_type: "T",
      gridDefaultData: [],
      gridFilterData: [],
      gData: [],
      gridFields: [
        {
          key: "No",
          label: "No"
        },
        {
          key: "sname",
          label: "충전소명"
        },
        {
          key: "sid",
          label: "충전소 ID"
        },
        {
          key: "tel_num",
          label: "단말기 번호"
        },
        {
          key: "model_id",
          label: "모델 ID"
        },
        {
          key: "fw_ver",
          label: "펌웨어 버전"
        },
        {
          key: "conn_yn",
          label: "충전기 연결여부"
        },
      ],
      filterData: {
        searchKeyType: "all",
        searchKey: ""
      },
      searchKeyOption: [
        { text: "전체", value: "all" },
        { text: "충전소 ID", value: "sid" },
        { text: "충전소 이름", value: "sname" },
        { text: "단말기 번호", value: "tel_num" },
        { text: "모델 ID", value: "model_id" }
      ],
      select_index: -1,
      select_rowData: {},
    };
  },
  mounted() {
    console.log("충전기 펌웨어 업데이트 팝업 페이지");
    if (this.$store.getters.user === null) {
      this.admin_data.user_id = "admin";
    } else {
      this.admin_data = this.$store.getters.user;
    }

    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.chargerData = this.obj.param_data;
        this.selectFwList();
      });
    },
    async selectFwList(company_id) {
      try {
        const { data } = await this.$axios.post(
          "/api/selectFwList", {
            maker : this.chargerData.maker
          }
        );
        if (data.length > 0) {
          this.gData = data;
          this.gridDefaultData = data;
          this.changeSearchKey();
        } else if (data.length == 0) {
          this.gData = null;
          console.log("데이터가 없습니다.");
        }

        //this.pageSet();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    changeSearchKey() {
      let search_key = this.filterData.searchKey;
      let search_type = this.filterData.searchKeyType;
      let temp_data = [];

      //this.gridDefaultData.

      if (search_key != "") {
        if (search_type === "all") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.sid.indexOf(search_key) != -1 || e.sname.indexOf(search_key) != -1 || e.tel_num.indexOf(search_key) != -1 || e.model_id.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "sid") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.sid.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "sname") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.sname.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "tel_num") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.tel_num.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "model_id") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.model_id.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        }

        this.gData = temp_data;
      } else {
        this.gData = this.gridDefaultData;
      }

      //this.pageSet();
    },
    selectRow(row_index) {
      var tbody = document.querySelector("#grid");
      var trs = tbody.getElementsByTagName('tr');
      
      var selectIndex = this.select_index + 1;

      if (this.select_index == -1) {
        this.select_index = row_index;
        this.select_rowData = this.gData[row_index];

        trs[row_index + 1].style.backgroundColor = "yellow";
      } else if (this.select_index > -1) {
        if (this.select_index === row_index) {
          this.select_index = -1;
          let default_row_data = {
            sid : "",
            s_name : "",
            cid : "",
            reader_id : "",
            bid : "",
            b_name : "",
            type : "",
            member : "",
            laf : "",
            laf_dtl : "",
            pay : "",
            cable : "",
            stat_dt : "",
            expired_dt : "",
            note : "",
            year : "",
            month : "",
            maker : "",
            maker_name : "",
            owner : "",
            sign : "",
            setup : "",
            subsidy : "",
            subsidy_amount : "",
            motie : "",
            limit_yn : "",
            limit_dtl : "",
            del_yn : "",
            del_dtl : "",
            reg_date : "",
            reg_user : "",
            update_date : "",
            update_user : "",
            c_channel : "",
            p_terminal : "",
            i_funnel : "",
            use_yn : "",
            failure_yn : "",
            r_type : "",
            c_capacity : "",
            audio_ver : "",
            c_standard : "",
            firmware_ver : "",
            pn_id : "",
            r_method : "",
            as_id : "",
            as_name : "",
            tr_yn : "",
            ocpp_yn : "",
            kakao_yn : "",
            tmap_yn : "",
            descript : "",
            zcode : "",
            zsgcode : "",
            laf_name : "",
            status : "",
            status_name : "",
            bis_id : "",
            mid : "",
            tem_id : "",
            page_num : "",
            id : "",
            model_id : "",
            ch_id : "",
          }
          this.select_rowData = default_row_data;

          trs[selectIndex].style.backgroundColor = "white";
        } else {
          this.select_index = row_index;
          this.select_rowData = this.gData[row_index];

          trs[selectIndex].style.backgroundColor = "white";
          trs[row_index + 1].style.backgroundColor = "yellow";
        }
      }
    },
    ok() {
      this.ocppConnReflash(this.chargerData.sid, this.chargerData.reader_id);
    },
    async ocppConnReflash(sid_value, tel_num_value) {
      try {
        const { data } = await this.$axios.post(
          "/api/selectOcppConnLastLog", {
            sid: sid_value, 
            tel_num: tel_num_value
          }
        );
        if (data.length > 0) {
          this.conn_data = data;
          this.selectLastEventEntry();
        } else if (data.length === 0) {
          window.alert("현재 충전기가 연결되어 있지 않습니다. 다시 확인하신 후 시도해 보시기 바랍니다.");
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async selectLastEventEntry() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectLastEventEntry", {
            sys_type: this.sys_type
          }
        );
        if (data.length > 0) {
          this.entry_data = data;

          this.addEventEntry();
        } else if (data.length === 0) {
          console.log("데이터가 없습니다.");
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async addEventEntry() {
      let crt_date = $p.getCurrentServerDate("yyyy-MM-dd HH:mm:ss");

      let sys_id = parseInt(this.entry_data[0].sys_id) + 1;

      var req_param = {};
      req_param.chargePointVendor = this.chargerData.maker_name
      req_param.chargePointModel = this.chargerData.model_id
      req_param.chargePointSerialNumber = this.conn_data[0].tel_num;
      req_param.firmwareVersion = this.chargerData.firmware_ver
      req_param.iccid = this.conn_data[0].tel_num;
      req_param.updateFirmwareVersion = select_data.fw_ver;

      let req_param_str = JSON.stringify(req_param);

      let last_entry_status = this.entry_data[0].status;
      let entry_status = "";

      if (last_entry_status == "STANDBY" || last_entry_status == "QUEUE") {
        entry_status = "QUEUE";
      } else if (last_entry_status == "QUEUE_WAIT") {
        entry_status = "QUEUE_WAIT";
      } else {
        entry_status = "STANDBY";
      };

      try {
        const { data } = await this.$axios.post(
          "/api/addEventEntry", {
            type: "updateFirmware",
						req_param: req_param_str,
						status: entry_status,
						timestamp: crt_date,
						session_index: this.conn_data[0].uuid,
						sys_type: sys_type,
						sys_id: sys_id
          }
        );
        if (data.length > 0) {
          window.alert("충전기 펌웨어 업데이트를 요청했습니다.");
          let context = "충전소ID : " + this.chargerData.sid + ", 충전기ID : " + this.chargerData.cid + ", 단말기번호 : " + this.chargerData.reader_id;
          this.addAdminLog("충전기 관리", "충전기 원격요청", "충전기 펌웨어 업데이트 요청", context, this.admin_data.user_id);
          this.$bvModal.hide("modal-update-call");
        } else if (data.length === 0) {
          window.alert("충전기 펌웨어 업데이트 요청을 실패했습니다.");
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + "-" + month.toString() + "-" + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + ":" + minutes.toString() + ":" + seconds.toString();

      return crt_date + " " + crt_time;
    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("관리자 명령 로그 등록을 실패했습니다.");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
